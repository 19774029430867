import config from '@/config'
import { get } from './http'
const URL = {
  GET_INDEX_SALE: '/sg/cms/flashSales/third/indexsale.json', // 首页-限时抢购
  GET_PROVINCE_LIST: '/api/basedict/regions/getRegionByPIdAndReType.json', // 获取城市列表
  GET_PROVINCE_LIST_BY_STOREOWNERID: '/api/basedict/regions/getRegionByStoreOwnerIdAndReType.json', // 获取授权范围内城市列表

  GET_REFRESH_TOKEN: '/v3/platform/login/rt.json',

  GET_LOCATION: '/mstore/region/location.json', // 根据经纬度获取地址,
  GET_LATLONG: '/mstore/region/getGeo.json', // 根据位置获取经纬度
  GET_BANNER: '/sg/cms/home/banner.json', // 首页轮播
  // 场景商城接口
  GET_SCENE: '/sg/cms/home/uhome/getTabsByPageInfo.json',
  GET_STORE_SCENE: '/sg/cms/store/home/uhome/getTabsByPageInfo.json', // 店铺首页场景tabs列表
  GET_CUSTOM_PAGE: '/activity/page/getCustomPageDetail.json', // banner动态活动页
  GET_ACTIVITY_PAGE: '/v3/mstore/sg/activity/toActivityPage.html', // banner活动
  GET_NEW_VIP_COUPON: '/activity/coupons/getNewVipCoupon.html', // 新人专享
  IS_SEVEN_MENBER: '/activity/coupons/isSeventhMember.html', // 七日内注册微店主
  GET_PRODUCTS: '/activity/coupons/getProducts.html', // 商品推荐
  GET_COUPON_DETAIL: '/coupon/v1/display/getCouponDetail.json', // banner优惠券详情

  GET_CLIENT_SUN_CODE: '/api-gw/zjBaseServer/wechat/getWeChatCode', //公域太阳码

  productDetailData: '/item/purchase/', // 详情页接口
  relation: '/item/sgProduct/product/relation.json', // 单品页获取关联商品接口
  checkStock: '/item/purchase/checkStock.json',
  getHasCoupons: '/activity/coupons/getHasCoupons.html', // 是否有优惠券
  getCouponsByProductId: '/activity/coupons/coupons/getCouponsByProductId.html', // 优惠券列表
  getFreightAndActivity: '/item/purchase/getFreightAndActivity.json', // 加减数量，获取运费
  evaluationInfo: '/item/evaluation/', // 商品评价 获取评价数好评率
  getComments: '/v3/h5/sg/getCommentsByCondition.json', // 商品评价
  getProductImpressions: '/v3/h5/sg/getProductImpressions.json', // 商品评价 商品标签
  getCommentNum: '/v3/h5/sg/toAssessList.html', // 商品评价  各种类评价的数量
  // getProductImg: "item/purchase/promos.json",// 商品详情 图文详情
  getProductImg: '/mstore/item/getImgAndAttrs.json', // 商品详情 图文详情
  getConnectProduct: '/mstore/item/getRelationProductsByParam.json', // 获取关联商品
  specifications: '/item/purchase/specifications.json', // 规格参数
  addToCar: '/v3/h5/cart/add.json', // 加入购物车
  getCartNum: '/v3/h5/cart/getCartNum.json', // 获取购物车件数
  checkStockForNum: '/item/purchase/checkStockForNum.json', // 加购物车检查库存
  getDes: '/item/purchase/getDes.json', // 服务说明
  getDetailCode: '/mstore/smartstore/member/getDetailCode.json', // 生成小程序二维码
  // getProductBasicInfo: '/item/sgProduct/purchase/', // 单品页基本静态信息_new
  getProductBasicInfo: '/item/sgProduct/purchase/v2/', // 单品页基本静态信息_new
  getRelationProductList: '/relation/product/getRelationProductList.json', // 商品关联销售-获取推荐商品
  postServiceCenter: '/item/precall/product', // 单品页商品详情回传客服中心
  getActivityInfoByScene: '/paycenter/pay/installment/getActivityInfoByScene.json', //获取花呗分期活动信息
  getInstallmentInfoByScene: '/paycenter/pay/installment/getInstallmentInfoByScene.json', //结算及收银台获取活动分期信息
  getVirtualSpuInfo: '/item/sgProduct/getVirtualSpu/', // 获取商品 所属 spu组 信息
  dynamic: '/item/sgProduct/product/dynamic.json', // 单品页动态信息接口_new
  generateExclusivePrice: '/activity/exclusiveprice/generateExclusivePrice.json', // 生成专属价
  queryExclusivePrice: '/activity/exclusiveprice/queryExclusivePrice.json', // 查询专属价使用信息
  promos: '/item/sgProduct/purchase/promos.json', // 单品页图文详情接口_new
  getStock: '/item/sgProduct/attr/getStock.json', // 动态获取商品库存
  cancelProductCollectionV2: '/item/collection/v1/cancel.json', // 单品页取消收藏
  getCustomer: '/v3/mstore/sg/getCustomer.json',
  getMemberInfo: '/v3/platform/web/member/getMemberInfo.json',
  traceLog: '/activity/region/traceLog.json',
  productCollection: '/v3/h5/sg/item/productCollection.json', // 单品页收藏接口
  productCollectionV2: '/item/collection/v1/add.json', // 单品页收藏接口 新版
  CHECK_NOTICE: '/v3/h5/sg/checkOnly.html', // 检验到货通知是否发送
  ARRIVE_NOTICE: '/v3/h5/sg/insertNotice.html', // 添加通知
  hasProgram: '/sg/cms/home/product/hasProgram.json', // 单品页查询当前商品是否存在成套家电方案
  getProductOldForNew: '/item/ofn/getProductOldForNew.json', // 单品页以旧换新模块展示控制
  selfCollectionList: '/api/cpc/selfCollection/v1/list', //获取商品收藏列表

  feedbackReportCheck: '/v3/h5/sg/report/reportCheck.json', // 举报校验
  feedbackReportSave: '/v3/h5/sg/report/reportBad.json', //举报保存
  feedbackReportTypes: '/v3/h5/sg/report/reportTypes.json', //举报分类
  // 我的页面
  getOrderCount: '/v3/h5/order/getOrderCount.json', // 订单数量接口
  myManage: '/v3/mstore/sg/myManage.json', // 用户基本信息
  wallet: '/mstore/smartstore/member/wallet.json', // 我的钱包数目
  isWdHost: '/sg/cms/isWdHost.json', // 判断是否是微店主
  findOrderProductUserInfo: '/v3/mstore/sg/mechanism/findOrderProductUserInfo.json', // 我的团队-我的用户
  pagingChildren: '/v3/mstore/sg/mechanism/pagingChildren.json', // 我的团队-我的上下级
  findTeamLevelApplyDesc: '/v3/mstore/sg/mechanism/findTeamLevelApplyDesc.json', // 顺逛机制
  formIdUrl: '/mstore/smartstore/member/collectFormId.json', // 收集formid
  getMemberId: '/v3/mstore/sg/msgCenter.html', // 获取memberId
  myCollectionListV2: '/item/collection/v1/list.json', // 新商品收藏列表
  cancelCollectionV2: '/item/collection/v1/cancelBatch.json', // 批量取消收藏
  storeCollection: '/v3/mstore/sg/storeCollection.json', // 店铺收藏列表
  getCollectScene: '/item/scene/collection/v1/list.json', // 收藏场景列表

  getDailyTask: '/v3/mstore/sg/task/findTaskList.html', // 获取今日任务信息
  dailyTaskShare: '/v3/mstore/sg/task/doTask.html', // 今日任务分享完成
  getDailyTaskDetail: '/v3/mstore/sg/task/findTaskDetail.html', // 获取今日任务详细信息
  // 用户授权相关
  getToken: '/mstore/smartstore/getToken.json', // getToken
  wdApplySubmit: '/v3/mstore/sg/wdApplySubmit.html', // 升级微店主接口
  captchaForSms: '/mstore/smartstore/captchaForSms.json', // 发送短信验证码（UC）
  baseLogin: '/mstore/smartstore/baseLogin.json', // 基础登录判断是否绑定手机号
  bindMobileOrRegist: '/mstore/smartstore/bindMobileOrRegist.json', // 绑定手机号及快速注册接口
  getPromotionCode: '/v3/platform/web/member/getPromotionCode.json', // 获取用户虚拟上级的推广码
  getWxPhone: '/mstore/smartstore/getWxPhone.json', // 获取授权手机号
  getImgCaptcha: '/mstore/smartstore/getImgCaptcha.json', // 获取图形验证码（商城）绑定手机号
  getImgCaptchafromUC: '/mstore/smartstore/getImgCaptchafromUC.html', // 获取图形验证码(UC)
  captchaLogin: '/mstore/smartstore/captchaLogin.json', // 账号密码登录
  captchaForFindPaw: '/mstore/smartstore/captchaForFindPaw.json', // 忘记密码发送短信验证码
  resetPassword: '/mstore/smartstore/resetPassword.json', // 重置密码
  removeSmsCaptcha: '/v3/h5/order/removeSmsCaptcha.json', // 验证码置为失效
  refreshToken: '/v3/platform/login/refreshToken4zjFrame.json', // 刷新token临时方案

  // 评价
  submitPingJia: '/v3/h5/sg/comment/submitComment.json', // 提交评价
  appendPingJia: '/v3/h5/sg/comment/submitExperience.json', // 追加评价
  getPingJia: '/v3/h5/sg/comment/getComment.json', // 查看评价
  getSuccessPingJia: '/v3/h5/sg/comment/commentSuccessPage.json', // 评价成功页面接口
  getCommentPage: '/v3/h5/sg/comment/commentPage.json', // 发表评价页面获取买家印象

  // 订单管理
  myOrderUrl: '/v3/h5/order/getOrderList.json', // 订单管理（已废弃）
  getRepairsList: '/sh/order/v/getOrderRepairsList.json', // 售后订单列表（新）
  myOrderUrlNew: '/sh/order/v/getOrderList.json',
  deleteOrder: '/v3/h5/sg/order/deleteOrder.json', // 删除订单接口
  cancelOrder: '/v3/h5/sg/order/cancelOrder.html', // 取消订单接口
  confirmReceive: '/v3/h5/sg/order/confirmOrderProduct.html', // 确认收货接口
  getConfirmReceiveList: '/v3/h5/sg/order/confirmList.html', // 获取订单确认收货列表
  orderdetail: '/v3/h5/order/getOrderDetail.json', // 订单详情
  orderProductDetailMore: '/sh/order/v/getOrderProductDetailMore.json', // 获取更多订单详情信息
  orderProductDetail: '/sh/order/v/getOrderProductDetail.json', // 订单详情新接口（暂时用来控制”确认收货“按钮的逻辑，[TODO]后续会整体迁移，完全替代上面的旧接口）
  trackFlowOrderDetail: '/v3/h5/order/getTrackFlowOrderDetail.json', // 订单详情页获取物流追踪信息
  orderProductTrackList: '/v3/h5/order/orderProductTrackList.json', // 物流追踪信息列表 网单号不传查订单所有，网单号传则查网单号下全部
  getGoodsMsgList: '/v3/h5/ylhorder/getGoodsMsgList.json', // 获取门店订单下发货单列表
  getOrderStagePayment: '/sh/orderpay/v/getOrderStagePayment.json', // 根据订单号获取支付单列表分阶段信息
  getOrderProductStagePayment: '/sh/orderpay/v/getOrderProductStagePayment.json', // 根据网单号获取支付单列表分阶段信息
  getOgcListByCorderSn: '/sh/order/gift/coupon/v/getOgcListByCorderSn.json', // 根据网单号获取礼品券信息
  getNextOrderPayInfo: '/sh/order/v/getNextOrderPayInfo.json', //获取下一条未支付单
  // checkrefund: '/v3/h5/sg/order/checkOrderRefund.html', // 检查是否可以退款退货
  getStoreOrder: '/v3/h5/ylhorder/getOrderList.json', // 易理货订单列表
  getCompensationOrders: '/v3/h5/order/getCompensationOrders.json', // 发票超时赔付订单列表
  getOrderStockInfo: '/sh/order/occupystock/v/getOrderStockInfo.json', // 查询网单申请占库发货详情
  occupyStock: '/sh/order/occupystock/v/occupyStock.json', // 操作占库发货
  getOrderPayDetailByOrderPaySn: '/sh/orderpay/v/getOrderPayDetailByOrderPaySn.json', //分阶段付款信息
  customerPreCall: '/sh/order/precall/customerPreCall.json', // 网单详情页回传网单信息给客服中心
  getOrderStoreInfo: '/sh/order/v/getOrderStoreInfo.json', // 根据订单号查询门店信息


  identifyAuth: '/v3/kjt/bank/identifyAuth.json', //实名认证(（姓名+身份证号+有缓存版)
  getAlipayStatusAndIcbcStatus: '/paycenter/merchantAccount/getAlipayStatusAndIcbcStatus', //获取工行支付宝开户状态
  queryAliAndIcbcAndXjStatus: '/paycenter/merchantAccount/getAliAndIcbcAndXjStatus', // 获取开户状态

  // 优惠券页面
  getViewCouponCate: '/activity/coupons/getViewCouponCate.html', // 获取 优惠券 tab 数据
  getCouponsCenter: '/coupon/v1/carry/getCouponCenter.json', // 获取 优惠券列表 couponCateId startIndex pageSize
  receiveCoupon: '/coupon/v1/carry/receiveCoupon.json', // 领取优惠券  ?couponId
  _old_getProductsByCouponId: '/activity/couponNew/getProductsForCoupon.json', // 可用优惠券的商品列表  ?couponId startIndex pageSize
  getProductsByCouponId: '/coupon/v1/display/getCouponProducts.json', // 可用优惠券的商品列表  ?couponId startIndex pageSize
  getCouponsByMemberId: '/coupon/v1/display/getMyCoupons.json', // 我的优惠券列表POST, status:状态, number:1表示未使用 2表示已经使用, startIndex, pageSize
  getCouponsByMemberIdOneDay: '/v3/h5/sg/coupon/getCouponsByMemberIdOneDay.html', // 即将过期的优惠券 GET status:状态1即将过期 startIndex, pageSize
  getMemberById: '/v3/mstore/sg/coupon/getMemberById.html', // 通过 id 获取用户然后送优惠券 ?id=
  couponForTransferNew: '/v3/h5/sg/coupons/couponForTransferNew.html', // 优惠券转赠 POST ?memberId=138993801&id=90428713
  REMIND_COUPONS_LIST: '/v3/h5/sg/coupons/setRemind.html', // 新优惠券提醒
  getCate: '/v3/h5/sg/getCate.html', // 店铺商品分类
  getStoreProductFirstCate: '/productdomain/api/cate/getStoreProductFirstCate', //店铺分类新接口
  getCartByCoupon: '/v3/h5/scart/plus/list.json', // 根据优惠券id获取购车中的商品
  // addCartByCoupon: '/v3/h5/scart/add.json', // 单个优惠券商品加入购物车
  // updateCouponByNumber: '/v3/h5/scart/update.json', // 通过数量更新加购数量
  getVoucherPackageList: '/coupon/v1/package/list.json', // 获取券包
  receiveVoucherPackage: '/coupon/v1/package/receive.json', // 获取券包
  getCouponList: '/coupon/v1/hotSelling/getCouponList', //低价爆款查询券包
  queryRuleInfoByCoupon: 'api-gw/shpm/couponStrategyRule/queryRuleInfoByCoupon', //查询券规则信息
  getCouponMarketAndStrategyRule: '/coupon/v1/display/getCouponMarketAndStrategyRule.json', //查询券规则信息(新接口，增加营销中台规则)
  getCouponBrandAndDepartment: '/coupon/v1/display/getCouponBrandAndDepartment.json', //查询优惠券品牌及产品组
  dealPromptByStrategyFirst: '/coupon/v1/carry/dealPromptByStrategyFirst.json', //优惠券/礼品券适用商品列表页加购提示
 
  getUnverifiedGdtcOrder: '/sh/order/v/getUnverifiedGdtcOrder.json', //获取待核销柜电套餐订单
  verifiedGdtcOrder: '/v3/h5/order/verifiedGdtcOrder.json', //核销柜电套餐订单
  getOrderProductGdtc: '/sh/order/v/getOrderProductGdtc.json', //获取柜电套餐订单商品信息

  // 申请售后
  // getorderRefund: '/v3/h5/sg/order/orderRefund.html', // 获取账单金额
  // submitOrderRepair: '/v3/h5/sg/order/submitOrderRepair.html', // 申请退款
  // trackOrder: '/v3/h5/sg/order/orderTrack.html', // 追踪订单
  trackOrder: '/v3/h5/sg/order/newOrderTrack.html', // 追踪订单
  orderTrack: '/v3/h5/order/orderTrack.json', // 追踪订单节点
  orderProductTrack: '/v3/h5/order/orderProductTrack.json', // 追踪订单节点(新)
  getYlhLogisticsMsgList: '/v3/h5/ylhorder/getYlhLogisticsMsgList.json', // 追踪订单节点(门店订单))
  getLogisticsCode: '/v3/h5/order/getLogisticsCode.json', // 根据发货单号/网单号获取运单号
  orderProductTrackGeo: '/v3/h5/order/orderProductTrackGeo.json', // 获取地图轨迹信息
  getOrderRepairsInfo: '/sh/order/v/getOrderRepairsDetail.json', // 退换货追踪
  checkOrderRefund: '/v3/h5/sg/order/checkOrderRefund.html', // 校验退款订单是否具备条件
  getRefundProcessDetail: '/v3/h5/sg/order/getOrderRepairsInfo.html', // 逆向进程查询
  getExpress: '/v3/h5/sg/order/getExpress.json', // 根据快递名字获取物流信息

  cancelOrderRepair: '/v3/h5/sg/order/cancelOrderRepair.json', // 新逆向 撤销申请
  getExpressList: '/v3/h5/sg/order/getExpressList.json', // 新逆向 获取快递公司列表
  repairExpress: '/v3/h5/sg/order/requestOrderRepairExpress.json', // 填写快递信息
  requestXiaoer: '/v3/h5/sg/order/requestXiaoer.json', // 申请客服介入
  repairHistory: '/v3/h5/sg/order/getOrderRepairHistory.json', // 协商历史
  orderRepair: '/v3/h5/sg/order/orderRepair.json', // 申请退货退款页面信息
  verifyInvoiceRefund: '/v3/h5/order/verifyInvoiceRefundQualification.json', // 检验网单发票是否可退款
  bankCardValidate: '/v3/kjt/bank/bankCardValidate.json', // 申请退款用户银行信息校验
  getNewRepairsInfo: '/v3/h5/sg/order/getOrderRepairInfo.json', // 新退换货追踪
  requestOrderRepair: '/v3/h5/sg/order/requestOrderRepair.json', // 申请退货退款
  modifyOrderRepair: '/v3/h5/sg/order/modifyOrderRepair.json', // 修改退货退款申请

  // 搜索
  searchProductList: '/productdomain/api/search/products', //公域搜索列表
  guessyoulike:'/productdomain/api/cate/products', //猜你喜欢
  defaultSearch: '/search/defaultSearchList.json', // -默认词
  hotSearch: '/search/search/hotSearch.html', // -热门词
  topSearch: '/hotSearch/product/getHotSearchProductList.json', // -热搜榜
  queryMdListByLocation: '/wing/md/info/queryMdListByLocation', //搜索页-附近商家
  searchDropdown: '/search/searchdropdown.json', // 热搜list
  wdCommonSearchNew: '/search/wdCommonSearchNew.html', // 商品搜索
  getPriceByProductList: '/search/getPriceByProductList.html', // 获取价格
  wdMarketFiltrate: '/search/wdMarketFiltrate.html', // 筛选
  commonLoadItemNew: '/search/commonLoadItemNew.html', // 分类list
  getStoreInformation: '/search/searchStore.json', // 获取店铺信息
  getSelectBoxTagList: '/search/getSelectBoxTagList.json', // 搜索页活动标签
  associatedSearchStore: '/search/associated/searchStore.json', // 搜索推荐店铺
  searchCateStore: '/productdomain/api/cate/searchCateStore', // 品类对应地区最优门店
  searchHistoryRecord: {
    url: '/api/cpc/history/v1/record',
    method: 'POST',
    baseURL: config.PDHOST,
    headers: {
      'Content-Type': 'application/json'
    }
  }, // 新增搜索历史
  searchHistory: {
    url: '/api/cpc/history/v1/recent',
    method: 'POST',
    baseURL: config.PDHOST,
    headers: {
      'Content-Type': 'application/json'
    }
  }, 
  deleteItemSearchHistory: {
    url: '/api/cpc/history/v1/delete',
    method: 'POST',
    baseURL: config.PDHOST,
    headers: {
      'Content-Type': 'application/json'
    }
  },
  // 搜索历史
  clearSearchHistory: {
    url:'/api/cpc/history/v1/clear',
    method: 'POST',
    baseURL: config.PDHOST,
    headers: {
      'Content-Type': 'application/json'
    }
  }, // 清除搜索历史

  // 收货地址
  getMemberAddress: '/wing/member/address/getList', // 收货地址
  insertAddress: '/wing/member/address/add', // 新增收货地址
  getAddress: '/wing/member/address/getOne', // 获取收货地址详情
  updateAddress: '/wing/member/address/update', // 修改收货地址
  deleteAddress: '/wing/member/address/delete', // 删除收货地址
  deleteBatchAddress: '/wing/member/address/batchDel', // 批量删除收货地址
  labels: '/v3/h5/order/labels.json', // 获取标签
  updateLabel: '/wing/member/address/updateLabel', // 新增/修改地址标签
  addressParse: '/v3/h5/order/addressesParse.json', // 地址识别

  // 祥玲新增的俩接口，见文档：https://ihaier.feishu.cn/wiki/wikcnJQzyRWxBwXgghfZ0BzLdGb
  // 新增地址后切换订单收货地址
  changeAddressAfterInsert: {
    url: '/v3/h5/orderAddress/changeAddressAfterInsert.json',
  },
  // 删除地址后重置订单收货地址
  resetAddressAfterDelete: {
    url: '/v3/h5/orderAddress/resetAddressAfterDelete.json',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },

  // 分类
  // getNavigations: '/sg/cms/navigation/getNavigations.json', // 分类
  // 结算页
  getOrderCreateUrl: '/orderth/api/order/page/v/normal/getOrderCreateUrl.json', // 生成下单链接或redKey
  createOrderNew: '/v3/h5/order/createOrder.json', // 进入结算页接口 (创建订单)
  getPriceChange: '/v3/h5/order/getPriceChange.json', // 校验价格是否变动 (提交订单之前调用)
  createOrder: '/mstore/smartstore/order/createOrder.json', // 结算页主接口
  getPageInfo: '/v3/h5/order/getPageInfo.json', // 获取订单结算信息
  changeAddress: '/v3/h5/order/changeAddress.json', // 修改结算页收货地址
  updatePay: '/v3/h5/order/updatePay.json', // 切换支付方式
  getProductServices: '/v3/h5/order/getProductServices.json', // 查询网单商品的服务项
  saveProductService: '/v3/h5/order/saveProductService.json', //
  asynSubmitOrder: '/v3/h5/order/asynSubmitOrder.json', // 提交订单
  spiteBatchOrderPayInfo: '/sh/order/spite/v/spiteBatchOrderPayInfo.json', // 分阶段付款提交订单
  setchoiceDeliverType: '/v3/h5/order/choiceDeliverType.json', //交付方式
  // sendSmsCaptch: '/mstore/smartstore/order/sendSmsCaptch.json', // 发送短信验证码接口
  sendSmsCaptch: '/mstore/smartstore/order/sendSmsCaptchForUser.json', // 发送短信验证码接口，不传手机号，直接取用户绑定手机号
  handleUnoccupiedStock: '/v3/h5/order/handleUnoccupiedStock.json', //暂不占库
  sendSmsCapthNew: '/mstore/smartstore/order/sendSmsForBindUser',
  getShopList: '/v3/h5/sg/shop/getShopList.json', // 结算页获取配送门店
  getCanChoiceMdList: '/v3/h5/order/getCanChoiceMdList.json', //获取可选择门店
  changeShop: '/v3/h5/order/changeShop.json', // 结算页更改配送门店
  saveOfflineAmount: '/v3/h5/order/saveOfflineAmount.json', //保存线下录入金额
  saveOrderInsourcing: '/v3/h5/order/saveOrderInsourcing.json', // 结算页内购保存信息
  checkBalanceEnough: '/v3/h5/order/checkBalanceEnough.json', //内购订单维护金额
  choiceDeliveryPayBalance: '/v3/h5/order/choiceDeliveryPayBalance.json', //货到付尾款
  getCommunityInfo: '/v3/h5/order/extra/getCommunityInfo.json', //获取小区信息
  saveVillageInfo: '/v3/h5/order/extra/saveVillageInfo.json', //保存小区信息
  chooseActiveLabel: '/v3/h5/order/extra/chooseActiveLabel.json', //极速达选中、取消接口
  getOrderPayDetailByOrderSn: '/sh/orderpay/v/getOrderPayDetailByOrderSn.json', //分阶段详情
  updateProductInfo: '/v3/h5/order/updateProductInfo.json', //用户修改网单商品数量
  queryDetailAndCheckUseByTikTokCode: '/v3/h5/order/queryDetailAndCheckUseByTikTokCode.json', //查询抖音券信息并验证是否可用
  getCanOrNotUseCouponList: '/coupon/v1/tiktok/getCanOrNotUseCouponList', //查询可用或不可用抖音券列表
  choiceTikTokCoupon: '/v3/h5/order/choiceTikTokCoupon.json', //选择抖音券
  changeTikTokCoupon:'/v3/h5/order/changeTikTokCoupon.json', //修改抖音券

  sendMobileSms: '/v3/platform/web/member/sendMobileSms.json', //发送手机短信
  createBusinessOpportunity: '/wing/crm/createBusinessOpportunity', //创建商机单

  wxpay: '/mstore/pay/wxpay.json', // 微信支付
  getStoreCoupon: '/v3/h5/order/getCanUseStoreCouponFromMarketingPlatformV2.json', // 获取店铺优惠券
  getOrderCoupon: '/v3/h5/order/getCanUseOrderCouponFromMarketingPlatform.json', // 获取平台优惠券
  getOrderCouponGift: '/v3/h5/order/getCanUseGiftCouponFromMarketingPlatform.json', // 获取礼品券
  choiceStoreCoupon: '/v3/h5/order/choiceStoreCouponFromMarketingPlatform.json', // 选择店铺优惠券
  choiceOrderCoupon: '/v3/h5/order/choiceOrderCouponFromMarketingPlatform.json', // 选择平台优惠券
  choiceOrderCouponGift: '/v3/h5/order/choiceGiftCoupon.json', // 选择礼品券
  toInvoice: '/v3/h5/order/toInvoice.json', // 获取结算页带过来的发票信息
  toInvoiceNew: '/sh/invoice/v/getMemberInvoice.json', // 获取结算页带过来的发票信息
  getInvoiceTypeList: '/sh/invoice/v/getInvoiceTypeList.json', // 获取发票类型

  findInvoice: '/v3/h5/invoice/findInvoice.json', // 用户已申请发票后，查询网单关联的发票列表
  cancelApplyInvoice: '/v3/h5/invoice/cancelApplyInvoice.json', // 撤销开票申请
  memberInvoice: '/v3/h5/invoice/memberInvoice.json', // 获取增票信息列表
  createInvoice: '/v3/h5/invoice/insertInvoice.json', // 新建增票信息
  updateInvoice: '/v3/h5/invoice/updateInvoice.json', // 更新增票信息
  deleteBatchInvoice: '/v3/h5/invoice/batchDeleteInvoice.json', // 批量删除增票信息
  getInvoiceInfoById: '/v3/h5/invoice/invoice.json', // 根据id获取增票信息
  defaultInvoiceAddress: '/v3/h5/invoice/defaultInvoiceAddress.json', // 获取默认增票信息收货地址
  getApplyInvoicePage: '/v3/h5/invoice/getApplyInvoicePage.json', // 下单时获取发票信息
  getReplaceApply: '/v3/h5/replaceInvoice/replaceApply.json', // 修改发票申请时获取发票信息
  cancelInvoice: '/v3/h5/order/cancelInvoice.json', // 下单时取消选择发票
  getInvoiceInfo: '/v3/h5/invoice/getInvoiceInfo.json', // 订单详情多网单发票服务获取发票信息
  submitInvoice: '/v3/h5/invoice/submitInvoice.json', // 网单开票提交订单信息
  replaceSubmit: '/v3/h5/replaceInvoice/replaceSubmit.json', // 修改发票申请
  submitMemInvoice: '/v3/h5/order/submitMemInvoice.json', // 提交发票信息
  getInvoiceApplyHistoryList: '/v3/h5/invoice/getInvoiceApplyHistoryList.json', // 获取发票申请历史
  postApply: '/v3/h5/postInvoice/postApply.json', // 获取 发票邮寄页所需信息
  postSubmit: '/v3/h5/postInvoice/postSubmit.json', // 提交 发票邮寄信息
  orderPayCheckNew: '/v3/h5/sg/orderPayCheckNew.html', // 支付前校验库存
  orderActiveLabelCheck: '/sh/order/activeLabel/v/activeLabelCheck.json', // 支付前极速达时间校验
  GET_VALIDATE_ADDRESS: '/v3/h5/order/validateAddress.json', // 下单地址校验
  getCanChoiceDate: '/v3/h5/order/getCanChoiceDate.json', // 结算页获取配送时间
  getCanChoiceDateNew: '/v3/h5/order/ops/getCanChoiceDate.json', // 新获取配送时间
  choiceDate: '/v3/h5/order/choiceDate.json', // 结算页选择配送时间
  choiceDateNew: '/v3/h5/order/ops/choiceDate.json', // 新结算页选择配送时间
  GET_TAIL_COUPONS: '/v3/h5/tail/getCanUseTailCouponsFromMarketingPlatform.json', // 定金+尾款优惠券
  CHOICE_TAIL_COUPENS: '/v3/h5/tail/choiceTailCouponsFromMarketingPlatform.json', // 尾款选择优惠券
  choiceTailCoupons: '/sh/ordertail/create/v/choiceTailCoupons.json', // 尾款结算选择券
  useCouponOnTailOrder: '/sh/order/coupon/v/useCouponOnTailOrder.json',// 尾款结算选择券new
  submitTailOrder: '/sh/ordertail/create/v/submitTailOrder.json', //新尾款提交结算
  getBenefitInfo: '/v3/h5/order/benefit/getBenefitInfo.json', //新获取虚拟资产信息
  useBenefit: '/v3/h5/order/benefit/useBenefit.json', //新 使用或取消虚拟资产
  submitOrderStagePay: '/v3/h5/order/submitOrderPay.json', //分阶段信息

  getYlhDist: '/v3/h5/order/getYlhDist.json', // 带单人
  changeYlhDist: '/v3/h5/order/changeYlhDist.json', // 修改带单人
  
  SUBMIT_TAIL_INVOICE: '/v3/h5/tail/submitMemInvoice.json', // 选择尾款发票
  CHOOSE_TAIL_ADDRESS: '/sh/ordertail/create/v/changeAddress.json', // 修改尾款地址
  GET_TAIL_ORDER: '/v3/h5/tail/getTailOrder.json', // 获取尾款结算页信息
  getTailOrderInfo: '/sh/ordertail/create/v/checkOrderTail.json', // 获取尾款结算页信息new
  SUBMIT_TAIL_ORDER: '/v3/h5/tail/submitTailOrder.json', // 提交尾款订单
  selectOrderRights: '/v3/h5/order/setOrderRights.json', // 选择和取消权益
  checkOrderRight: '/v3/h5/order/checkOrderRight.json', // 获取订单的权益

  getReservationTimeQuantum: '/sh/order/create/v/getReservationTimeQuantum.json', // 【预约服务时间】获取预约时间段
  dealReservationTime: '/v3/h5/order/dealReservationTime.json', // 【预约服务时间】提交预约服务时间

  realNameAuthByMemberId: '/v3/kjt/bank/realNameAuthByMemberId.json', //用户实名认证
  // 购物车
  cartCList:'/api/cpc/selfCart/v1/list', // 公域购物车
  cartCRemove:'/api/cpc/selfCart/v1/remove', // 公域购物车删除单个商品
  cartCRemoveBatch: '/api/cpc/selfCart/v1/batch/remove', // 公域购物车批量删除商品
  cartCAddBatch: '/api/cpc/selfCart/v1/batch/add', // 公域购物车批量添加商品
  cartCAdd: '/api/cpc/selfCart/v1/add', // 公域购物车添加单个商品
  cartCUpdateNum: '/api/cpc/selfCart/v1/update', // 公域购物车修改商品数量
  cartList: '/v3/h5/cart/list.html', // 购物车列表
  goodsNumber: '/v3/h5/cart/update.json', // 购物车修改数量
  deleteCart: '/v3/h5/cart/delect.json', // 删除购物车商品
  // cartNumber: '/v3/h5/cart/getCartNum.json', // 获取购物车数量
  getCartNumber: '/v3/h5/scart/num.json', // 获取购物车数量
  GET_CART_COMM: '/mstore/smartstore/cart/getCartComm.json', // 购物车返佣
  getCartList: '/v3/h5/scart/list.json', // 获取购物车详情
  addCart: '/v3/h5/scart/add.json', // 单个加入购物车
  updateCart: '/v3/h5/scart/update.json', // 单个更新购物车数量
  addBatch: '/v3/h5/scart/addBatch.json', // 批量加入购物车
  deleteBatch: '/v3/h5/scart/delete.json', // 批量删除购物车

  smartListDetail: '/sg/cms/activity/activityDetail.json', //限时折扣、低价爆款、满减折扣专区二级页面
  discountActivityDetail: '/sg/cms/activity/discountActivityDetail.json', //凑单折扣详情
  searchCateHotSell: '/productdomain/api/cate/searchCateHotSell', //搜索近期热买
  hotSellingList: '/productdomain/api/cate/hotSellingList', //热门榜单

  // 万人团
  myMoreGroupList: '/wechat/groupbuy/moregroup/myMoreGroup.json', // 我的拼单列表
  tuanIndex: '/wechat/groupbuy/moregroup/index.json', // 万人团首页
  tuanActivityShow: '/wechat/groupbuy/moregroup/activityShow.json', // 中间广告页接口
  // 拼团详情
  initGroup: '/wechat/groupbuy/moregroup/initGroup.json', // 团长发起团接口
  groupDetail: '/wechat/groupbuy/home/groupDetail.json', // 拼团详情
  tuanGetDetailCode: '/wechat/groupbuy/product/getDetailCode.json', // 获取二维码 (活动类型0:普通团1:超级团，2：首页列表)
  share: '/wechat/groupbuy/home/share.json', // 分享接口
  deleteMyMoreGroup: '/wechat/groupbuy/moregroup/deleteMyMoreGroup.json', // 我的订单删除
  tuanDetail: '/wechat/groupbuy/home/detail.json', // tuan detail
  tuanCheckStock: '/wechat/groupbuy/product/checkStock.json', // tuan 校验库存
  tuanGetAttribute: '/wechat/groupbuy/product/getAttribute.json', // tuan 商品(O2O)属性接口
  tuanCreateOrder: '/wechat/groupbuy/order/pageInfo.json', // 结算页接口--万人团
  tuanSubmitOrder: '/wechat/groupbuy/moregroup/submitOrderM.json', // 订单提交接口-- 万人团
  tuanChangeAddress: '/wechat/groupbuy/order/changeAddress.json', // 切换收获地址结算页接口--万人团
  isGroupDetail: '/wechat/groupbuy/moregroup/isMoreGroupDetail.json', // 是否为万人团商品详情
  // moreGroupDetail: '/wechat/groupbuy/home/detail.json', // 万人团商品详情
  // groupCheckStock: '/wechat/groupbuy/product/checkStock.json', // 万人团库存校验
  groupOrderList: '/wechat/groupbuy/moregroup/groupOrderList.json', // 团单关联的订单列表接口
  groupUserList: '/wechat/groupbuy/member/groupUserList.json', // 参团列表
  tuanCancelOrder: '/wechat/groupbuy/order/cancelOrder.json', // 拼团取消订单

  // 券券裂变
  getMyHelpCoupon: '/activity/couponshelp/getMyHelpCoupon.html', // 我的助力界面
  getCouponActivitys: '/activity/couponshelp/getCouponActivitys.html', // 正在进行的活动列表
  startCouponActivity: '/activity/couponshelp/startCouponActivity.html', // 发起活动
  helpCouponInfo: '/activity/couponshelp/helpCouponInfo.html', // 助力页面接口
  helpCoupon: '/activity/couponshelp/helpCoupon.html', // 助力接口
  helpedSuccessList: '/activity/couponshelp/getHelpedSuccessList.html', // 被助力成功名单

  // 优惠券转赠
  myCouponShare: '/activity/coupons/myCouponShare.html', // 分享记录
  couponShare: '/activity/coupons/couponShare.html', // 分享优惠券
  // couponForTransferNew: '/activity/coupons/receiveShareCoupon.html', // 领取优惠券
  // getMyCouponList: '/v3/h5/sg/coupon/getCouponsByMemberId.html', // 我的优惠券
  transfer: '/coupon/v1/display/transferCoupon.json', // 转赠优惠卷

  // 用户协议签订
  checkMemberContract: '/v3/mstore/sg/checkMemberContract.json', // 判断是否签订
  signContract: '/v3/mstore/sg/signContract.json', // 签订协议

  // 智家免登录相关接口
  GET_AUTHORIZE: '/sg/h5/web/auth/mobile/customer/authorize.json',
  GET_OUTOKENLOGIN: '/v3/platform/login/outTokenLogin.json',
  GET_MEMBER: '/v3/platform/web/member/getMember.json',
  GET_USERINFO: '/v3/mstore/sg/member/userinfo.json', // 根据accessToken获取用户信息
  GET_HAS_STORE: '/v3/platform/web/app/is_has_store',
  GET_IDENTIFY_INFO: '/wing/member/identify/detail',
  GET_USER_ROLE: '/wing/member/multiIdentity/detail',

  // 游客登录token
  GET_TOURISTS_TOKEN: '/v3/platform/web/token/getToken.json',

  // 根据memberId查询进入店铺日志
  queryByMemberId: '/v3/mstore/sg/enterStore/queryByMemberId.json',

  // 收银台
  TO_ORDER_SUBMIT_SUCCESS: '/v3/kjt/paycenter/getPaymethodsList.json', // 提交订单成功获取支付相关接口
  TO_ORDER_SUBMIT_SUCCESS_NEW: '/v3/kjt/paycenter/paymethods/getPaymethodsList.json', // 提交订单成功获取支付相关接口 新接口
  TO_ORDER_PAY_NEW: '/checkout/paymentMethod.json', // 间连方式确认支付接口
  // ORDER_200PAYCHECK: '/v3/h5/sg/orderPayCheckNew.html', // 提交支付方式接口
  GET_PAYLIST_METHODS: '/paycenter/listPayMethods', // 新支付方式列表
  HB_PAY_H5: '/v2/h5/pay/Hb/alipay/new_request.html', // 花呗分期接口
  ALIPAY_H5: '/v2/h5/pay/alipay/request.html', // 支付宝pay
  WX_PAY_H5: '/v3/h5/weChat/toWeChatPay.html', // 微信支付普通H5
  WX_PAY_APP: '/v3/h5/pay/wxpay.html', // 微信获取app支付参数 直连接口
  WX_PAY_INWXH5: '/v3/h5/weChat/pay.html', // 微信内 h5获取支付参数
  WHITE_SHOWS_QUERY_STATUS: '/v3/kjt/queryIousStatus.json', // 白条状态查询
  CCB_FENQI_W: '/paycenter/pay/request.html', // 调取 建行信用卡支付 接口
  EASYCONNECT_INIT: '/v3/kjt/sg/withdrawSetting.html', // 获取绑定信息
  UNINO_PAY: '/paycenter/pay/acp/h5/payRequest.html', // 银联 云闪付
  UNINO_PAY_CALLBACK: '/v3/h5/pay/callback.html', // 银联 云闪付成功回调
  WX_CODE_TO_PAYINFO: '/v3/platform/web/other/login/getWxAccessToken', // 微信通过code获取支付相关参数
  GetExtendInfoByOrderId: '/checkout/thirdpay/getExtendInfoByOrderId.json', // 获取订单来源
  GET_CHANGE_WEBVIEW_DATA: '/activity/couponNew/testCache.json', // 针对ios支付切容器获取数据接口
  ORDER_ERROR_LOG: '/checkout/echoPaymentLog.json',
  GET_GHDICCPAY_RESULT: '/checkout/queryZtPayDetail.json', // 工行数字货币支付结果查询
  getSimpleOrderInfo: '/checkout/orderInfo/getSimpleOrderInfo', // 扫码支付查询支付信息
  getCashPayQRcode: '/v3/kjt/pos/getPosQrCode.json', // 现金刷卡支付二维码接口
  checkStockBeforePayNli: '/v3/h5/sg/checkStockBeforePayNli.json', // 扫码支付校验库存
  getOrderEffect: '/sh/order/v/getOrderEffect.json', //支付结果页获取活动权益
  querySceneScheme: '/sh/order/v/querySceneScheme.json', //场景请设计查询
  signpay:'/paycenter/signPay/pay', //签约支付
  signPayList: '/paycenter/signPay/syn/page',
  unsignPay: '/paycenter/signPay/syn/unsign',

  // 银行卡免密支付
  BANKCARD_FREE_LIST: '/paycenter/nocardpay/getSignInfo', // 查询签约卡
  DO_FREE_BANKCORD: '/paycenter/nocardpay/signAgreement', // 签约
  CANCEL_FREE_BANKCORD: '/paycenter/nocardpay/updateAgreement', // 解约
  GET_FREE_CODE_MSG: '/paycenter/nocardpay/sendSMS', // 短信验证码
  SET_FREE_BANK_DEFAULT: '/paycenter/nocardpay/setDefaultCard', // 设置默认银行卡
  GET_BANKCARD_TYPE: '/v3/kjt/bank/getBankInfo.json', // 获取银行卡类型
  READ_IMG_CARD_INFO: '/paycenter/ocr/getOrcData', // orc识别卡号

  // 钻石
  GET_DIAMOND_DETAIL: '/v3/h5/sg/getBenefitDetails.json', // 我的钻石明细
  GET_DIAMOND_TOTAL: '/v3/h5/sg/getBenefitMember.json', // 我的钻石钱包等数量
  GET_DIAMOND_LINK: '/sg/cms/mine/diamond.json ', // 获取我的钻石使用连接

  // 智家商城白条
  GET_PROCOTOL_BAITIAO: '/v3/mstore/sg/richText.json', // 白条协议
  WHITE_SHOWS_APPLY: '/v3/kjt/applyForOpen.json', // 白条申请开通
  WHITE_SHOWS_PAY: '/v3/kjt/payApply.json', // 白条支付
  WHITE_COST: '/v3/kjt/iouc/cost.json', // 白条分期策略
  WHITE_ACCESS_TOKEN: '/v3/platform/web/member/queryAccessToken.json', // 查询刷新accessToken
  // 银行卡相关
  HAS_BANK_LIST_NEW: '/v3/kjt/bank/getBankCardList.json',
  GET_BANK_CARD_INFO: '/v3/kjt/bank/toBindCard.html', // 获取绑卡信息
  TRUE_REALNAMEAUTH: '/v3/kjt/bank/isRealNameAuth.json', // 实名认证
  GET_BANKNAME: '/v3/kjt/bank/getBankName.json', // 自动识别银行卡名
  POST_CHECKS: '/v3/kjt/bank/bindCardCheck.json', // 校验信息
  GET_CODEID: '/v3/kjt/bank/captcha.json', // 发送验证码
  POST_BANKDATA: '/v3/kjt/bank/bindCard.json', // 绑定银行卡
  KJTBIND_INIT: '/v3/kjt/sg/kjtAccountBind.html', // 绑定快捷通
  GET_BANK_DETAIL_INFO: '/v3/kjt/bank/getCardDetail.json', // 获取绑卡详细信息
  BANK_DELETE_CAPTCHA: '/v3/kjt/bank/getCaptchaForUnbind.json',
  BANK_DELETE_NEW: '/v3/kjt/bank/unBindCard.json',
  ALIPAY_GET_VERTIFIYID: '/paycenter/alipay/preCertVerify', // 支付宝获取vertifyId
  ALIPAY_TO_BIND: '/paycenter/alipay/bind', // 绑定支付宝
  ALIPAY_TO_UNBIND: '/paycenter/alipay/unbind', // 解绑支付宝
  ALIPAY_CREDITPAY_BY: '/paycenter/alipay/listAntCreditPayByAmount', // 花呗分期信息

  // 微信二次分享接口
  WXJsSdkConfig: '/v3/platform/web/wechat/jssdkconfig.json',

  // 快捷通相关
  POST_USERDATAANDID: '/v3/kjt/bank/realNameAuth.json', // 去实名认证
  CHANGE_AUTHENTICATION_CHECK: '/v3/kjt/bank/realAuthChangeVerif.json', // 变更实名认证校验
  CHANGE_AUTHENTICATION: '/v3/kjt/bank/realAuthChange.json', // 变更实名认证

  // 手机充值
  GET_PHONE_LOCATION: '/lifepayment/getPhoneLocation', // 获取手机号所在地区
  RECHARGE_PHONE_SAVEORDER: '/lifepayment/saveLifeOrder', // 创建手机充值订单
  PHONE_RECHARGE_ALIPAY: '/lifepayment/pay/alipay/payRequest', // 手机充值支付宝
  PHONE_RECHARGE_WEIXIN: '/lifepayment/wxpay/mpay', // 手机充值微信
  RECHARGE_PAY_LIST: '/lifepayment/getLifeOrderList', // 手机充值记录
  DELETE_RECHARGE: '/lifepayment//deleteLifeOrder', // 取消充值
  GET_RECHARGE_DETAIL: '/lifepayment/getLifeOrderDetail', // 获取充值详情

  getWithdrawalAccountInfo: '/paycenter/pay/store/getWithdrawalAccountInfo', // 提现账户信息

  // 店铺
  manage: '/v3/mstore/sg/manage.json', // 店铺信息
  collectStore: '/v3/mstore/sg/collectStore.json', // 关注店铺
  enterprise: '/v3/mstore/sg/enterprise.json', // 获取商家营业执照
  checkIdentity: '/v3/mstore/sg/checkIdentity.json',
  queryIsUse: '/wing/md/info/entranceStoreMdList', // 查询店铺是否失效
  getShopGameList: '/api-gw/shpm/game/getGameList', //店铺活动大转盘列表

  /** 千店千面店铺接口 */
  storeSign: '/v3/mstore/decorate/getStoreSign.json', // 店招模块
  storeSignPreview: '/merchant/api/v1/decoration/home/header/storeSign', // 店招部分预览接口
  storeSelectedModel: '/v3/mstore/decorate/getStoreModel.json', // 首页精选模块内容
  recommendProducts: '/v3/mstore/decorate/getProByModelId.json', // 推荐商品列表
  storeVRPreviewList: '/v3/mstore/decorate/getImgByModelId.json', // VR 预览列表 storeModelId=117
  preSaleList: '/sg/cms/productactivity/list.json', // 预售专区--定金尾款
  sceneTabList: '/sg/cms/home/uhome/getTabsAndSpace.json', // 场景页筛选条件
  sceneListNew: '/item/v1/sence/store/list', // 场景列表（新）
  sceneList: '/sg/cms/home/uhome/getProgramPlan.json', // 场景列表
  validateTabOrSpace: '/sg/cms/home/validateTabOrSpace.json', // 场景类别校验
  subStoreList: '/v3/mstore/decorate/getMallMdStoreList.json', // 分店地址列表
  subStoreListNew: '/v3/mstore/decorate/getShopNearbyList.json', // 分店地址列表
  getCustomPage: '/v3/mstore/decorate/getPageModel.json', // 自定义页面数据
  shopPreview: '/merchant/api/v1/decoration/home/model/indexPreview.json', // 店铺首页预览功能
  customPagePreview: '/merchant/api/v1/decoration/home/model/customPreview.json', // 自定义页面预览功能
  storeNewProducts: '/v3/mstore/decorate/getStoreNewProducts.json', // 店铺上新接口
  platformPreSale: '/sg/cms/reserve/preSale.json', // 品类、品旗、001获取平台预售活动
  checkPrivateDomain: '/v3/mstore/sg/privateDomain/query.json', // 查询当前位置是否为店铺私域范围
  storeFindGoods: '/sg/cms/flashSales/third/goodProduct.json', // 店铺级限时抢购接口
  notifyWashingMachine: '/item/collection/notifyWashingMachine.json', // 商品收藏，通知洗衣机产业
  marketingActivityInfo: '/sg/cms/activity/marketingActivityInfo.json', // 店铺首页-获取活动信息
  homeDecorationActivityInfo: '/sg/cms/activity/homeDecoration/activityInfo.json', // 店铺首页-活动-家装预约活动详情
  receiveMemberRight: '/marketing/activity/v1/receiveMemberRight', // 店铺首页-活动-家装预约活动详情-无意向金提交表单

  // 附近的店
  nearbyList: '/sg/cms/home/nearby/list.json',
  getHotProducts: '/sg/cms/home/getHotProducts.json', // 附近的店详情 热销商品
  nearbyDetail: '/sg/cms/home/nearbyDetail.json', //  ?nearbyId=257&latitude=36.12784&longitude=120.41803

  // 店铺套购
  getShopSuitList: '/v3/mstore/sg/queryStoreProductPackageList.html', // 店铺套购列表
  getShopSuitImage: '/v3/mstore/sg/queryStorePackage.html', // 店铺套购列表页广告图查询
  getShopSuitDetail: '/v3/mstore/pkg/getPackageDetail.json', // 店铺套购详情

  // 精选套购/场景推荐
  recommendSuitOrScene: '/item/sgProduct/productPackage/recommend.json', // 搜索精选套购/场景推荐

  // 公共图片上传
  upLoadCommentPicture: '/v3/h5/sg/comment/upLoadCommentPicture.json', // 单个图片上传
  uploadPictureBybase64: '/v3/h5/sg/upload/uploadPictures.json', // 图片base64 批量上传

  everyDayList: '/sg/cms/revision/topSallingPage.json', // 今日榜单的商品列表
  everyDayListTop: '/sg/cms/revision/getCategory.json', // 今日榜單的商品列表的頭部list

  // 限时抢购
  flashSaleTitle: '/sg/cms/flashSales/third/dockersale.json',
  flashSaleShop: '/sg/cms/flashSales/third/dockersaleForStore.json',
  flashRemind: '/sg/cms/flashSales/third/remind.json', // 平台发现好货-消息提醒


  // 获取解决方案(类似全屋用水解决方案)
  solutionDetails: '/sg/cms/home/programs/details.json',
  // 解决方案预约
  makeAppointment: '/sg/cms/home/design.json',
  // 解决方案清单的商品列表
  sequenceProducts: '/sg/cms/home/programs/details/v2/sequenceProducts.json',
  // 解决方案列表
  listByProductId: '/sg/cms/home/programs/listByProductId.json',
  // 智能场景背景图片
  scenesImage: '/sg/cms/home/smart/scenes.json',
  // 智能场景定位信息
  scenesCoordinate: '/sg/cms/home/smart/space.json',
  // 智能场景图商品信息
  // scenesProducts: '/sg/cms/home/programs/details/v2/sequenceProducts.json',
  // 场景优惠券列表
  scenesCoupons: '/sg/cms/home/programs/loadPlanCouponsById.json',

  // 检查尾款支付
  checkTailOrder: '/v3/h5/tail/checkTailOrder.json',
  // 新尾款结算页
  createNewTailOrder: '/sh/ordertail/create/v/createTailOrder.json',
  // 获取尾款结算
  getTailPageInfo: '/sh/ordertail/create/v/getTailPageInfo.json',
  // 获取尾款可用券列表
  getTailCanUseCoupons: '/sh/ordertail/create/v/getCanUseCoupons.json',
  queryUsefulCouponsOnTailOrder: '/sh/order/coupon/v/queryUsefulCouponsOnTailOrder.json',
  // 获取可用虚拟资产列表
  getCanUseBenifit: '/sh/ordertail/create/v/getCanUseBenifit.json',
  getUnSupportProductsForOrderNew: '/sh/ordertail/create/v/getUnSupportProductsForOrder.json',
  initMemberInvoice: '/sh/invoice/v/initMemberInvoice.json', // 初始化发票信息
  getInvoiceTitleList: '/invoiceweb/invoiceTitle/getInvoiceTitleList', // 获取发票抬头列表
  saveInvoiceTitle: '/invoiceweb/invoiceTitle/saveInvoiceTitle', // 保存发票抬头

  getTradeInCate: '/productdomain/api/cate/getTradeInCate', //以旧换新活动类目
  getCouponsAndProuducts: '/coupon/v1/package/g2b/getCouponsAndProducts', // 获取活动信息 (包含认证信息)
  getPurchasePriceList: '/coupon/v1/package/g2b/getPurchasePriceList',
  g2bReceiveCoupon: '/coupon/v1/package/g2b/receiveCoupon', // 领券
  enterpriseSendEmail: '/wing/internalPurchaseEnterprise/sendEmail',
  enterpriseAuthEmail: '/wing/internalPurchaseEnterprise/authEmail',
  userAgreementRecord: '/wing/member/userAgreement/record', // 记录协议

  // 生成尾款单结算页
  createTailOrder: '/v3/h5/tail/createTailOrder.json',
  // 店铺详情客服
  getBusinessCustomer: '/v3/h5/sg/getBusinessCustomer.json',
  // 根据店铺id 获取优惠券列表
  // getCouponsList: '/activity/coupons/getCouponsList.json',
  // getCouponsList: '/coupon/v1/carry/getCouponCenter.json',

  // 优惠券（新） 商品可用优惠券（单品页）
  getProductAvailableCoupons: '/coupon/v1/carry/getProductCoupons.json',
  // 礼品券 商品可用礼品券（单品页）
  getGiftCouponListByStoreAndSku: '/coupon/v1/gift/getGiftCouponListByStoreAndSku.json',
  // 权益
  querySingleOnlineRightsList: '/v3/h5/order/querySingleOnlineRightsList.json',
  // 订单可用优惠券（结算页）
  getOrderAvailableCoupons: '/activity/couponNew/getOrderAvailableCoupons.json',
  // 自动领券演策优惠券是否符合策略
  checkCouponReceiveStrategyFirst: '/coupon/v1/carry/checkCouponReceiveStrategyFirst.json',
  // 获取推荐商品列表
  loadFindProductIds: '/search/loadFindProductIds.html',

  getAccountRealNameRemind: '/paycenter/account/isRealNameRemind.json',

  // 新版登录接口
  accountLogin: '/v3/platform/login/accountLogin.json', // 账号密码登录
  mobileLogin: '/v3/platform/login/mobileLogin.json', // 手机号登录
  getSmsCode: '/v3/platform/sms/getSmsCode.json', // 获取短信验证码
  getUcImg: '/v3/platform/captcha/getUcImg.json', // 获取用户中心图片验证码

  // 注册短信验证码
  register: '/v3/platform/web/member/captchaForSmsLogin.json',
  login: '/v3/platform/web/member/smsLogin.json',

  getImg: 'platform/web/member/register/getImgCaptcha.html',
  registerAccount: '/v3/platform/web/member/smsRegister.json',
  // 密码登录
  password: '/v3/platform/web/member/captchaLogin.json',
  // 重置密码验证码
  retrieve: '/v3/platform/web/member/captchaForFindPaw.json',
  // 提交新密码
  changePassword: '/v3/platform/web/member/resetPassword.json',

  // 修改密码
  UPDATE_PASSWORD: '/v3/platform/web/member/updatePassword.json',
  // 忘记密码
  // findPassword: '/v3/platform/web/member/resetPassword.json',

  CHECK_RESETSMS: '/v3/platform/web/member/checkResetSms.json', // 验证短信验证码
  SUBMIT_RESET: '/v3/platform/web/member/submitReset.json', // 提交修改密码


  msSceneSearchList: '/productdomain/api/msScene/searchList', // 场景搜索
  msSceneDetail: '/productdomain/api/msScene/detail', //场景详情
  msSceneSearchBySku: '/productdomain/api/msScene/searchBySku', //场景按品搜素

  // 手机号不可用之账号申诉
  createUserGrievanceRecord: '/v3/platform/web/member/createUserGrievanceRecord.json', // 创建用户申诉记录
  reviewUserGrievance: '/v3/platform/web/member/reviewUserGrievance.json', // 审核用户申诉

  // 退出
  logout: '/v3/platform/web/member/logoutNew.json',
  // 消息中心
  messageCenter: '/v3/mstore/sg/getSgMessageList.json',
  // 消息中心已读状态
  readNews: '/v3/mstore/sg/readSgMessage.json',
  // 消息中心详情页
  messageCenterDetail: '/v3/mstore/sg/getSgMessageDetail.json',
  // 【移动工作台】消息模板
  getSendMessage: '/msmsg/message/getSendMessage',
  getMessageDetails: '/msmsg/message/getMessageDetails',
  deleteBatchMessage: '/msmsg/message/deleteBatchMessageDetails',
  batchMessageDetails: '/msmsg/message/batchMessageDetails',
  deleteMessage: '/msmsg/message/deleteMessageDetails',
  getMessageContent: '/msmsg/message/getMessageContent',
  read: '/msmsg/message/readMessage',
  // 单品详情页分享信息接口
  productQrCode: '/item/material/productQrCode',
  // 获取店铺的直播信息
  getLiveByStoreId: '/v3/mstore/sg/getLiveByStoreId.json',
  // 用户中心免登重定向
  getUcLoginUrl: '/v3/platform/web/getUcLoginUrl.json',
  // ssoauth AB站授权登录
  checkssoauth: '/v3/platform/web/codeLogin.json',
  // 集团卡券-适用商品列表 根据券id和定位获取授权店铺
  getCouponUseStore: '/activity/couponNew/getCouponUseStore',
  // 集团卡券-适用商品列表 根据券id和店铺id获取适用商品
  getCouponUseProducts: '/activity/couponNew/getCouponUseProducts',
  // 检查是否可以预约
  checkMemberReserve: '/sg/cms/reserve/checkMemberReserve.json',
  // 预约抢购发送短信
  sendSmsCaptcha: '/sg/cms/reserve/sendSmsCaptcha.json',
  // 确认预约
  reserve: '/sg/cms/reserve/reserve.json',
  // 我的预约
  myReserve: '/sg/cms/reserve/myReserve.json',

  GET_RESETSMS: '/v3/platform/web/member/getResetSms.json', // 找回密码发送短信验证码

  getGlobalTipsMsg: '/wing/menuConfiguration/queryByChildMenuId', //获取全局菜单功能信息

  // 复购分佣
  recordShareRelation: '/v3/mstore/sg/recordShareRelation.json',

  // ---- 智选场馆页 ----
  provinceInfo: '/sg/cms/chara/charaPage.json', // 省场馆
  productList: '/search/charaLoadItem.json', // 获取商品列表
  cateList: '/sg/cms/navigation/getNavigations.json', // 获取分类列表
  // asyncPrice: '/search/getPriceByProductList.html',
  // 海尔智选首页基本元素接口
  getSpecialGoodsMain: '/sg/cms/chara/charaIndex.json',
  // 海尔智选首页活动接口
  getActiveInfo: '/sg/cms/flashSales/third/charasale.json',
  // 底部分类
  getGoodsType: '/sg/cms/chara/charaCate.json',
  // 海尔智选底部分类商品列表接口
  getISGoodsTypeInfo: '/sg/cms/chara/charaProducts.json',
  getCharaRegions: '/sg/cms/chara/charaRegions.json',
  // 解除绑定
  KUNBIND_INIT: '/v3/kjt/sg/kjtAccountUnbind.html', // 解除绑定
  // 邀请合伙人
  openShareLink: '/v3/mstore/sg/partner/openShareLink.json', // 打开链接
  acceptInvite: '/v3/mstore/sg/partner/acceptInvite.json', // 确认邀请
  // 添加合伙人
  openInvitation: '/v3/mstore/sg/partner/openInvitation.json', // 打开链接
  acceptInvitationAdd: '/v3/mstore/sg/partner/acceptInvitationAdd.json', // 确认邀请
  // 店铺合伙人管理
  createPlatformShareLink: '/v3/mstore/sg/platformPartner/createShareLink.json', // 获取邀请平台合伙人的分享链接
  checkPartner: '/v3/mstore/sg/partner/checkPartner.json', // 校验添加合伙人权限
  getPartnerLabel: '/v3/mstore/sg/partner/getPartnerLabel.json', // 查询合伙人标签列表
  getAddPartner: '/v3/mstore/sg/partner/getAddPartner.json', // 根据手机号查询待添加的合伙人信息
  getMdByStoreId: '/v3/mstore/sg/partner/getMdByStoreId.json', // 查询合伙人门店列表新接口
  // 获取邀请平台合伙人的分享链接
  // 获取数据看板默认视图
  getStoreMenuDefaultView: '/v3/mstore/data/getStoreMenuDefaultView.json',
  // 获取数据看板的树形菜单
  tableauMenu: '/v3/mstore/data/getStoreMenuTree.json',
  // 获取数据看板店铺Tableau票证
  getStoreTableauTicket: '/v3/mstore/data/getStoreTableauTicket.json',
  // 放弃权益
  giveupRights: '/v3/h5/order/resetOrderRights.json',
  // 获取订单详情的权益列表
  getRightList: '/v3/h5/order/getDetailOrderRights.json',

  // 问答区域数据
  getMaxAnswerProductQuestion: '/v3/mstore/sg/qa/getMaxAnswerProductQuestion.json', // 详情页问答区域
  releaseProductQuestion: '/v3/mstore/sg/qa/releaseProductQuestion.json', // 我要提问
  getPageProductQuestion: '/v3/mstore/sg/qa/getPageProductQuestion.json', // 问答列表
  getPageProductAnswer: '/v3/mstore/sg/qa/getPageProductAnswer.json', // 问题详情
  getPageProductComment: '/v3/mstore/sg/qa/getPageProductComment.json', // 评论回复详情
  addProductAnswerComment: '/v3/mstore/sg/qa/addProductAnswerComment.json', // 评价回复提交
  releaseProductAnswer: '/v3/mstore/sg/qa/releaseProductAnswer.json', // 我来回答
  getPageMemberAnswer: '/v3/mstore/sg/qa/getPageMemberAnswer.json', // 我的回答
  getpurchasedGoods: '/v3/mstore/sg/qa/purchasedGoods.json', // 是否购买过商品
  addProductAnswerLikes: '/v3/mstore/sg/qa/addProductAnswerLikes.json', // 回答点赞
  getPageMemberQuestion: '/v3/mstore/sg/qa/getPageMemberQuestion.json', // 我的提问
  deleteProductQuestion: '/v3/mstore/sg/qa/deleteProductQuestion.json', // 删除问题
  deleteProductAnswer: '/v3/mstore/sg/qa/deleteProductAnswer.json', // 删除回答
  getMyQuestionAnswer: '/v3/mstore/sg/qa/getMyQuestionAnswer.json', // 获取我的问答提问数量
  getProductQuestionInfo: '/v3/mstore/sg/qa/getProductQuestionInfo.json', // 根据sku查商品信息

  getSYNfootprint: '/item/v1/browserhistory/push/syn', // 三翼鸟足迹

  // 高德地图
  getLbsGeo: '/amap/v3/geocode/geo',
  getLbsRegeo: '/amap/v3/geocode/regeo',
  // 用户收货地址管理
  // 根据街道国标码获取商城地址库省市区街道
  getPcr: '/v3/h5/order/getPcr.json',
  pcrByCode: '/mstore/region/pcrByCode.json', // 根据街道国标码获取商城地址库省市区街道和经纬度

  // 火热直播间查询接口
  getSkuLives: '/sg/cms/live/getSkuLive.json', // 查询产品直播间
  getProgramLives: '/sg/cms/live/getProgramLive.json', // 查询场景直播间

  // 店铺关系
  vipStore: '/sg/cms/home/getMemberStores.json',
  vipStoreNew: '/v3/mstore/decorate/getMallNearMdStore.json',

  // 浏览活动计时通知
  getNotifyTaskFinished: '/sg/cms/task/notifyTaskFinished.json',
  // 会员中心计时浏览任务
  vipCenterTaskFinished: '/sg/cms/task/vipCenterTaskFinished.json',

  // ---------大转盘----------
  getGameInfo: '/v4/mstore/sg/game/oldGameApp.json', // 获取游戏信息
  gameLuckDraw: '/v4/mstore/sg/game/luckDraw.json', // 抽奖
  gameAddAddress: '/v4/mstore/sg/game/addAddress.json', // 受奖地址
  gameWinnerList: '/v4/mstore/sg/game/winnerList.json', // 中奖名单
  gameHelp: '/v4/mstore/sg/game/help/powerShare.json', // 助力接口
  gameHelpInfo: '/v4/mstore/sg/game/help/helpRecord.json', // 助力页面初始化接口
  queryWinnerPrize: '/v4/mstore/sg/game/queryWinnerPrize.json', // 获取中奖记录
  queryWinnerAddress: '/v4/mstore/sg/game/queryWinnerAddress.json', // 获取中奖地址
  saveWinnerAddress: '/v4/mstore/sg/game/saveWinnerAddress.json', // 保存中降地址
  deleteWinnerAddress: '/v4/mstore/sg/game/deleteWinnerAddress.json', // 删除中降地址
  // 店铺大转盘
  getPicById: '/v4/mstore/sg/game/getPicById.json', // 获取背景图
  getPrizeDetail: '/v4/mstore/sg/game/getPrizeInfoByWinningId', // 查询中奖详情
  // ---------老虎机---------
  getActivityInfo: '/v4/mstore/sg/drawActivity/getActivityInfo.json', // 获取游戏信息
  draw: '/v4/mstore/sg/drawActivity/draw.json', // 抽奖
  getWinnerList: '/v4/mstore/sg/drawActivity/getWinnerList.json', // 中奖名单
  getMyPrize: '/v4/mstore/sg/drawActivity/getMyPrize.json', // 获取中奖记录
  getActivityListByOrder: '/v4/mstore/sg/drawActivity/getActivityListByOrder.json', // 根据订单编码获取[支付后]类型活动列表信息
  savePrizeAddress: '/v4/mstore/sg/drawActivity/saveWinnerAddress', // H5独立活动（无限制类活动）设置奖品地址
  checkNeedOrderSn: '/v4/mstore/sg/drawActivity/checkNeedOrderSn', // 检测是否需要录入订单号

  getGuessYouLikeIt: '/search/searchLoad.json', // 猜你喜欢
  getGuessYouLikeNew: '/api-gw/shpmResource/goods/recommend/query',
  walletInfo: '/v3/mstore/sg/wallet.json', // 钱包详情
  ecardList: '/v3/mstore/sg/ecard/getECardFlowDetailList.json', // E卡明细
  getStoreFlashSale: '/sg/cms/flashSales/third/storeFlashSale.json', // 店铺限时抢购、
  getStoreFlashSaleNew: '/sg/cms/flashSales/third/storeFlashSaleMd.json', // 店铺限时抢购、(新)

  useBenefitNew: '/v3/h5/order/benefit/useBenefitNew.json', // 使用虚拟资产

  // 智家E卡
  getSmartEcardUsableList: '/sh/order/smartecard/v/getSmartEcardUsableList.json', // 查询 E卡数量
  getSmartEcardUnusableList: '/sh/order/smartecard/v/getSmartEcardUnusableList.json', // 查询不可用E卡数量
  getSmartEcardDetails: '/sh/order/smartecard/v/getSmartEcardByPwd.json', // 查询绑卡信息
  getBindEcard: '/sh/order/smartecard/v/bindSmartEcard.json', // 绑定礼品卡
  getSmartEcardExpenditureDetails: '/sh/order/smartecard/v/getSmartEcardExpenditureDetails.json', // 查询卡消费记录
  getSmartEcardListForOrder: '/v3/h5/order/smartecard/getSmartEcardListForOrder.json', // 结算页查询可用卡 不可用卡数量
  // getSmartEcardListForTailOrder: '/v3/h5/order/smartecard/getSmartEcardListForTailOrder.json', // 尾款结算页查询可用卡 不可用卡数量
  getSmartEcardListForTailOrder: '/sh/ordertail/create/v/getSmartEcardListForOrder.json', // 尾款结算页查询可用卡 不可用卡数量
  getProductListBySmartEcard: '/search/product/getProductListBySmartEcard.json', // 获取e卡适用商品列表
  getUnSupportProductsForOrder: '/v3/h5/order/smartecard/getUnSupportProductsForOrder.json', // 不可用卡不适用商品
  getUnSupportProductsForTailOrder: '/v3/h5/order/smartecard/getUnSupportProductsForTailOrder.json', // 尾款结算页不可用卡不适用商品
  // getUnSupportProductsForTailOrder: '/sh/ordertail/create/v/getUnSupportProductsForOrder.json', // 尾款结算页不可用卡不适用商品
  checkDeliveryPayBalanceRate: '/v3/h5/order/checkDeliveryPayBalanceRate.json',

  getOrdersPaymentInfo: '/sh/order/v/getOrdersPaymentInfo.json',

  // 首页热销榜
  getSaleByCate: '/sg/cms/navigation/getSale.json', // 根据品类id获取销量
  getHotSaleProducts: '/hotSearch/product/getHotSaleProductList.json', // 根据品类获取热销榜商品

  GET_HELP: '/v3/mstore/sg/shunguangHelp.html', // 帮助列表
  GET_HELP_RICHTEXT: '/v3/mstore/sg/richText2.json', // 获取帮助 详细富文本
  GET_JOBS: '/v3/mstore/sg/occupationList.json', // 获取邀请人 职业列表

  // 平台合伙人
  submitApplyInfo: '/v3/mstore/sg/platformPartner/submitApplyInfo.json', // 提交申请接口
  applyResult: '/v3/mstore/sg/platformPartner/applyResult.json', // 申请结果查询接口
  queryApplyInfo: '/v3/mstore/sg/platformPartner/queryApplyInfo.json', // 申请信息查询接口
  userIdentify: '/v3/mstore/sg/platformPartner/userIdentify.json', // 身份查询
  getPartnerRank: '/v3/mstore/sg/partnerReward/top50.json', // 获取空调合伙人排行榜列表
  bindWxAccount: '/mstore/smartstore/wechat/bindThirdAccount.json', // 绑定当前账号到unionId
  getWechatUserInfo: '/v3/platform/web/other/login/getUserInfo', // 根据code获取用户信息
  queryMemberInfo: '/v3/mstore/sg/platformStandard/queryMemberInfo.json', // 第三方合伙人标准入驻查询用户信息
  submitStandardMemberInfo: '/v3/mstore/sg/platformStandard/submitStandardMemberInfo.json', // 提交第三方合伙人信息
  queryEmployeeInfo: '/v3/mstore/sg/platformStandard/queryEmployeeInfo.json', // ihaier环境内根据员工号获取员工详情
  platformPartnerOpenShareLink: '/v3/mstore/sg/platformPartner/openShareLink.json', // 打开邀请链接(就是记录日志)
  pushCardActivityAssist: '/v3/mstore/sg/platformPartner/pushCardActivityAssist.json', // 调用智家第三方接口

  queryCommissionRankConfig: '/paycenter/commission/queryCommissionRankConfig', // 佣金榜单规则查询接口
  queryCommissionRank: '/paycenter/commission/queryCommissionRank', // 佣金榜单查询接口
  // 平台合伙人 => 三翼鸟合伙人（原智家掌柜）B端，平台合伙人管理
  // 平台合伙人接口（平台合伙人有 普通合伙人、员工平台合伙人、服务兵平台合伙人、设计师平台合伙人、兑呗直销员平台合伙人）。
  // 查看当前合伙人的基本信息
  getPartnerDetail: '/v3/mstore/sg/platformPartnerManage/getPartnerDetail.json',
  // 查看当前合伙人下面的合伙人
  getPlatformPartnerInfo: '/v3/mstore/sg/platformPartnerManage/getPlatformPartnerInfo.json', // 分页查询我的合伙人列表/合伙人搜索
  getPartnerGradeRules: '/v3/mstore/sg/platformPartnerManage/getPartnerGradeRules.json', // 平台合伙人规则查询
  getPartnerContributionData: '/v3/mstore/sg/platformPartnerManage/getPartnerContributionData.json', // 获取合伙人详情页面的贡献数据
  getPartnerDataAnalysis: '/v3/mstore/sg/platformPartnerManage/getPartnerDataAnalysis.json', // 平台合伙人总数据分析
  getPartnerFavoriteProducts: '/v3/mstore/sg/platformPartnerManage/getPartnerFavoriteProducts.json', // 喜欢浏览的商品
  getActionAnalysis: '/v3/mstore/sg/mechanism/getActionAnalysis.json', // 微店活跃度分析
  getSaleNumByCate: '/v3/mstore/sg/mechanism/getSaleNumByCate.json', // 微店主销售商品分析

  getSpmCommission: '/v3/h5/order/commission/getSpmCommission.json',

  getProductTradeInInfo: '/item/ofn/getProductTradeInInfo.json', //以旧换新v2

  // 店铺合伙人
  getStorePartnerInfo: '/v3/mstore/sg/storePartnerManage/getStorePartnerInfo.json', // 分页查询店铺合伙人列表
  getStorePartnerDataAnalysis: '/v3/mstore/sg/storePartnerManage/getStorePartnerDataAnalysis.json', // 店铺合伙人数据分析
  checkMemberStatus: '/v3/mstore/sg/partner/checkMemberStatus.json', // 校验账号状态
  updateShowMobile: '/v3/mstore/sg/storePartnerManage/updateShowMobile.json', // 设置商家、顾问、合伙人是否能联系我
  updateStoreSpecialName: '/v3/mstore/sg/partner/updateStoreSpecialName.json', // 设置备注
  getPartnerInfoDetail: '/v3/mstore/sg/partner/getPartnerDetail.json', // 合伙人详情
  submitPartnerUnbind: '/v3/mstore/partner/unbundling/submitPartnerUnbind.json', // 提交店铺合伙人解绑申请
  getStorePartnerUnbindSpeed: '/v3/mstore/partner/unbundling/getStorePartnerUnbindSpeed.json', // 查询店铺合伙人解绑进度
  appealPartnerUnbind: '/v3/mstore/partner/unbundling/appealPartnerUnbind.json', // 执行申诉合伙人解绑申请
  createShareLink: '/v3/mstore/sg/partner/createShareLink.json', // 生成邀请链接

  // 合伙人解绑
  getUnbundlingList: '/v3/mstore/partner/unbundling/getUnbundlingList.json', // 解绑列表
  getUnbundlingDetail: '/v3/mstore/partner/unbundling/getUnbundlingDetail.json', // 解绑详情
  relieveBindingReview: '/v3/mstore/partner/unbundling/relieveBindingReview.json', // 解绑审核

  // 合伙人管理
  getPartnersList: '/v3/mstore/sg/partner/getPartners.json', // 查询合伙人列表
  getTotalCount: '/v3/mstore/sg/partner/getTotalCount.json', // 查询统计信息
  updateGroup: '/v3/mstore/sg/partner/updateGroup.json', // 更新星级
  updateBelongStore: '/v3/mstore/sg/partner/updateBelongStore.json', // 更新合伙人所属门店
  updateIsConsultant: '/v3/mstore/sg/partner/updateIsConsultant.json', // 更新合伙人顾问身份
  getTaPartners: '/v3/mstore/sg/partner/getTaPartners.json', // 查询TA的合伙人
  deletePartner: '/v3/mstore/sg/partner/deletePartner.json', // 删除合伙人
  setCancelStorePartnerLabel: '/v3/mstore/sg/partner/setCancelLabel.json', // 设置、取消合伙人标签
  checkAuthority: '/v3/mstore/sg/partner/checkAuthority.json', // 校验合伙人门店邀请权限

  // 合伙人--天网佣金设置
  getProductionCommissionList: '/v3/mstore/sg/commission/list.json', // 获取商品佣金列表
  getProductionCommissionDetail: '/v3/mstore/sg/commission/detail.json', // 获取商品佣金详情
  savaCommissionRate: '/v3/mstore/sg/commission/save.json', // 保存佣金设置

  // 我的营收
  checkBindByType: '/v3/kjt/bank/checkBindByType.json', // 1.1.1 校验快捷通或者银行卡是否绑定
  getComminsionSumInfo: '/paycenter/billcenter/getComminsionSumInfo.json', // 1.1.2 佣金、货款汇总信息接口
  getGoodsAmountList: '/paycenter/billcenter/getGoodsAmountList.json', // 1.1.3 货款账单列表接口
  getServiceCharge: '/paycenter/threeWindBird/billcenter/getServiceAmount.json', // 营收模块 服务费信息

  // 外部订单评价
  getEvaluateDetail: '/v3/h5/order/comment/getCommentDetailInfo.json', // 获取评价详情
  getTagList: '/v3/h5/order/comment/genCommentImpression.json', // 获取评价标签
  saveEvaluate: '/v3/h5/order/comment/saveCommentInfo.json', // 保存评价
  saveEvaluateAppend: '/v3/h5/order/comment/saveExperienceInfo.json', // 保存追评
  // 店铺拼团
  getMainGroupBooking: '/sg/cms/groupbuy/getGroupBuyIndexProductsList.json', // 首页拼团专区列表
  getShopGroupList: '/sg/cms/groupbuy/groupBuyStoreList.json', // 店铺拼团列表
  getGoodsGroupList: '/activity/groupBuy/getActorList.json', // 单品页拼团待成团列表
  getGroupBuyByOrderSn: '/v3/h5/groupbuy/queryGroupBuyInfo.json', // 根据订单号查询拼团信息
  isGroupBuyOrder: '/v3/h5/groupbuy/getGroupBuyOrderSn.json', // 通过orderId查询拼团订单的orderSn

  // 智家魔方
  findTopCategory: '/sg/cms/magicCube/findTopCategory.json', // 智家魔方模块tab栏
  findMagicCube: '/sg/cms/magicCube/findMagicCube.json', // 魔方信息接口
  getNewProductList: '/newProduct/product/newProductList.json',

  // 礼品卡提取
  uploadFileIn: '/paycenter/giftCard/pickUp/uploadFileIn.json', // 上传图片
  checkEvent: '/paycenter/giftCard/pickUp/checkEvent.json', // 校验活动是否存在,是否在有效期
  submitInfo: '/paycenter/giftCard/pickUp/submitInfo.json', // 礼品卡提取提交信息
  checkCode: '/paycenter/giftCard/pickUp/checkCode.json', // 校验提货码是否有效

  // 频道页接口
  detailInfo: '/sg/cms/homepage/detailInfo.json', // 校验提货码是否有效
  getCommonProductList: '/search/product/getCommonProductList.json', // 通用商品查询列表
  getSceneDetailUrl: '/item/v1/sence/getSceneDetailUrl', // 通用商品查询列表
  // 积分相关
  findIntegral: '/sg/cms/integral/findIntegral.json', // 查询可用总积分
  findIntegralDetail: '/sg/cms/integral/findIntegralDetail.json', // 查询积分明细
  findDueSoonIntegral: '/sg/cms/integral/findDueSoonIntegral.json', // 查询即将过期的积分

  // 导购客服
  activateGuide: '/im/guide/activateGuide', // 激活/更改 导购资料
  updateDeviceToCloud: '/v3/platform/web/app/saveDeviceToken.json', // 上报设备id和系统
  getUserSig: '/im/user/usersig', // 获取IM的usersig
  getConversationList: '/im/user/chatSessionList', // 获取会话列表
  userSessionMaterial: '/im/user/userSessionMaterial', // 批量获取用户基础信息
  getMessageList: '/im/msg/history', // 获取历史消息
  autoProductMsg: '/im/msg/autoProductMsg', // 自动发送商品消息
  getGuideProfile: '/im/guide/cardPage', // 导购详细资料
  getGoodsGuide: '/im/guide/queryTopThree', // 单品页获取导购列表
  getStoreGuide: '/im/guide/allData', // 店铺获取导购列表

  uploadImg: '/im/upload/img', // 上传图片返回缩略图
  getGuideByMdCode: '/im/guide/strategy', // 单品页点击客服按钮 查找导购客服
  // 新移动工作台
  validAccount: '/v3/h5/order/validAccount.json', // 校验用户【商城订单校验】
  searchSceneList: '/item/v1/sence/searchWorkBenchScene', // 搜索页查询场景列表
  searchMdStores: '/wing/md/info/searchMdStores', // 搜索页查询门店
  searchAllWords: '/productdomain/api/search/word', // 新搜索页查询关键词
  storeSearchWord: '/productdomain/api/storeSearch/word', // 店铺搜索默认词
  cancelAllSelectedCoupons: '/v3/h5/order/cancelAllSelectedCoupons.json', // 结算页取消所有优惠券的选择
  cancelAllSelectedCards: '/v3/h5/order/card/cancelAllSelectedCards.json', // 结算页取消所有卡的选择
  // 余额相关接口
  accountInfo: '/paycenter/pay/store/accountInfo.json', // 获取账户信息
  queryShopUnfreezeConfig: '/paycenter/cbs/queryShopUnfreezeConfig', // 商家预计货款文案天数获取 : 预计货款？点击弹窗提示语根据此接口动态展示文案
  queryEnsureBillList: '/paycenter/pay/store/queryEnsureBillList.json', // 冻结金额：跳转冻结金额账单 获取查询数据
  recharge: '/paycenter/pay/store/recharge.json', // 充值
  getBalance: '/paycenter/pay/store/getBalance.json', // 转巨商汇货款： 获取账户信息
  getAliBalance: '/paycenter/pay/store/getAliBalance.json', // 支付宝余额查询
  getRegionCenter: '/paycenter/getRegionCenter', // 转巨商汇货款：*根据88码查询小微信息接口
  getPayToList: '/paycenter/getPayToList', // 转巨商汇货款：*根据88码和销售组织编码查询付款公司编码（付款方名称选择列表）*
  balanceTransfer: '/paycenter/pay/store/transfer.json', // 转巨商汇货款：确认转账
  getBalanceMarketingInfo: '/api-gw/shpmResource/ad/v1/rotation', // 转巨商汇货款底部营销位
  bindBankInfo: '/paycenter/pay/store/bindBankInfo.json', // 查询绑定银行卡
  queryBillList: '/paycenter/pay/store/queryBillList.json', // 账单：获取账单信息
  balanceWithdraw: '/paycenter/pay/store/withdraw.json', // 余额--提现
  queryBillInfo: '/paycenter/pay/store/queryBillInfo.json', // 余额--账单详情
  queryRecharge: '/paycenter/pay/store/queryRecharge.json', // 充值主动查询接口
  ensureBillInfo: '/paycenter/pay/store/ensureBillInfo.json', // 保证金账单详情
  getAccountFund: '/paycenter/pay/store/getAccountFund', // 查询账户金额
  listEnterpriseFlow: '/paycenter/pay/store/listEnterpriseFlow', // 余额流水账单
  unFreezeStatus: '/paycenter/pay/store/unfreezeStatus.json', // 商家是否解冻
  // 网商贷款
  loadAmount: '/paycenter/webBusiness/loadAmount.json', // 信用融资额度查询
  loadApply: '/paycenter/webBusiness/loadApply.json', // 信用融资申请
  loadHistory: '/paycenter/webBusiness/loadHistory.json', // 信用融资纪录查询
  loadRepayRecord: '/paycenter/webBusiness/loadRepayRecord.json', // 还款记录查询
  queryRegisterInfo: '/paycenter/vanChain/queryRegisterInfo', // 获取万链金融
  getWlLoadingUrl: '/paycenter/vanChain/getLoadingUrl', // 获取万链金融跳转url

  // 我的礼品卡
  exchangeDetail: '/activity/giftcard/exchangeDetail.json', // 礼品卡兑换钻石明细接口
  exchange: '/activity/giftcard/exchange.json', // 礼品卡兑换钻石接口
  giftInfo: '/activity/giftcard/giftInfo.json', // 礼品卡信息查询
  giftCardTip: '/paycenter/pay/giftcard/desc.json', // 绑卡须知
  // 手机号邮箱管理
  sendMobileCaptcha: '/v3/platform/web/member/sendMobileCaptcha', // 发送短信验证码API
  untieMobileNextStep: '/v3/platform/web/member/untieMobileNextStep', // 解绑手机号下一步API
  sendEmailCaptcha: '/v3/platform/web/member/sendEmailCaptcha', // 获取邮箱验证码API
  untieMobileSubmit: '/v3/platform/web/member/untieMobileSubmit', // 解绑手机号已绑邮箱提交API
  unEmailVerifyEmailSubmit: '/v3/platform/web/member/unEmailVerifyEmailSubmit', // 解绑手机号未绑邮箱提交API
  unEmailVerifyMobileSubmit: '/v3/platform/web/member/unEmailVerifyMobileSubmit', // 解绑手机号未绑邮箱验证手机提交API
  bindMobileNew: '/v3/platform/web/member/bindMobileNew.json', // 绑定手机号
  captchaMobile: '/v3/platform/web/member/captchaMobile.json', // 绑定手机号获取短信验证码
  needImgCaptchaForSms: '/v3/platform/web/member/needImgCaptchaForSms.json', // 绑定手机号校验是否需要图形验证码

  getOffSms: '/v3/platform/web/member/getOffSms.json', //获取手机号验证码
  getCheckSms: '/v3/platform/web/member/getCheckSms.json', // 认证手机号验证码

  checkMobile: '/v3/platform/web/member/checkMobile.json', // 更换手机号校验原手机号
  phoneAuth: '/v3/kjt/bank/phoneAuth.json', // 校验新手机号
  // changeMobile: '/v3/platform/web/member/changeMobile.json', // 更换手机号申请

  // 注销
  checkWriteOffNew: '/v3/platform/web/member/checkSingleWriteOff.json', // 注销前校验 https://stp.haier.net/project/539/interface/api/185230
  submitWriteOffNew: '/v3/platform/web/member/submitSingleWriteOff.json', // 执行注销  https://stp.haier.net/project/539/interface/api/185227
  checkCancelSmsCode: '/v3/platform/sms/checkCancelSmsCode.json', // 注销前校验短信验证码
  
  // 待确认合伙人
  getPartnerRelationList: 'v3/mstore/sg/partner/getPartnerRelationList.json', // 待确认查询
  invitePartnerURL: 'v3/mstore/sg/partner/invitePartnerURL.json', // 添加合伙人 URL
  invitePartnerSMS: 'v3/mstore/sg/partner/invitePartnerSMS.json', // 添加合伙人发短信
  checkUserNameExist: '/v3/platform/web/member/checkUserNameExist', // 校验新手机号（新）
  // changeMobile: '/v3/platform/web/member/changeMobile.json', // 更换手机号申请
  changeMobile: '/v3/platform/web/member/changeMobileNotIdentity.json', // 更换手机号申请

  guideLevel: '/im/guide/guideLevel', // 导购等级页面
  getMemberPerformance: '/sh/order/performance/v/getMemberPerformance.json', // 获得导购业绩
  getGuideOrderDetail: '/sh/order/v/getOrderDetail.json', // 获取导购订单详情
  getShoppingGuideConfig: '/activity/exclusiveprice/getShoppingGuideConfig.json', // 查询门店导购最低限价
  setUpWechatNumber: '/v3/mstore/sg/setUpWechatNumber.json', // 设置导购微信号
  // 商家中心-售后
  // TODO : 替换为移动工作台专用接口
  getOrderRepairsList: '/sh/order/merchant/v/orderrepair/getOrderRepairsList.json', // 逆向单列表
  getOrderRepairsDetail: '/sh/order/merchant/v/orderrepair/getOrderRepairsDetail.json', // 逆向单详情
  getOrderRepairsDetailNew: '/sh/order/merchant/v/orderrepair/getAllRefundDetail.json', // 逆向单详情new
  checkOrderReparis: '/sh/order/merchant/v/orderrepair/checkOrderRepairs.json', // 退货/款审核逆向
  checkOrderSnByBusinessNo: '/sh/order/v/checkOrderSnByBusinessNo.json', // 提交逆向单审核校验：该网单的商机号是否存在

  // 商家中心-订单
  orderMain: '/sh/order/merchant/v/orderproductmanager/mainInfo', // 门店列表
  getOrderProductsList: '/sh/order/merchant/v/orderproductmanager/getOrderProductsList.json', // 网单列表
  getOrderProductsDetail: '/sh/order/merchant/v/orderproductmanager/getOrderProductsDetail.json', // 网单详情
  getAllRefundDetail: '/sh/order/v/getAllRefundDetail.json', // 网单详情new
  changeBusinessNotes: '/sh/order/merchant/v/orderproductmanager/changeBusinessNotes.json', // 修改备注
  // 商家中心-工单
  workList: '/sh/order/merchant/v/workOrder/pageList.json', // 工单列表
  workDetail: '/sh/order/merchant/v/workOrder/detail.json', // 工单详情
  updateMiddleResult: '/sh/order/merchant/v/workOrder/updateMiddleResult.json', // 工单处理

  // 商家中心-商品
  getGoodMinList: '/productdomain/api/mobile/products/count',
  postGoodslist: '/productdomain/api/mobile/products/list',
  getProductBrand: '/productdomain/api/mobile/products/getProductBrand',
  getProductCate: '/productdomain/api/mobile/products/getProductCate',
  getCommissionRegion: '/productdomain/api/mobile/products/getCommissionRegion',
  getFreightTemplates: '/productdomain/api/mobile/products/getFreightTemplates',
  getFreightProductRelation: '/productdomain/api/mobile/products/getFreightProductRelation',
  changeOnSale: '/productdomain/api/mobile/products/changeOnSale',
  postGoodsUpdate: '/productdomain/api/mobile/products/update',
  getChildGoods: '/productdomain/api/products/skku/getChildGoods',
  updateSkkuProduct: '/productdomain/api/products/skku/updateSkkuProduct',
  getProductPriceList: '/productdomain/api/products/getProductPriceList',
  updatePmPrice: '/productdomain/api/storeProduct/price/updatePmPrice',
  getPricerich: 'v3/mstore/sg/richText2.json',

  //商家中心-发票
  getOverdueCount: '/sh/order/merchant/v/invoice/getOverdueCount.json',
  getInvoiceListToB: '/sh/order/merchant/v/invoice/pageList.json',
  getInvoiceDetailToB: '/sh/order/merchant/v/invoice/detail.json',
  getExpressListToB: '/sh/express/v/getExpressList.json',
  postInvoiceRed: '/sh/order/merchant/v/invoice/cancel.json',
  inputInvoiceToB: '/sh/order/merchant/v/invoice/inputInvoice.json',
  recognizeInvoiceToB: '/sh/order/merchant/v/invoice/recognize.json',

  // 店铺资料
  getWingInfo: '/wing/store/info', // 查询店铺信息
  getWingList: '/wing/md/info/list', // 查询门店列表信息
  getWingMdType: '/wing/md/info/mdType', // 查询门店分类信息
  getWingDetail: '/wing/md/info/detail', // 查询门店详细信息
  postWingUpdateContact: '/wing/store/info/updateContact', // 修改店铺联系人
  hotSocialStore:'/wing/store/info/hotSocialStore',//搜索页面 热门生态店铺

  // 商机二维码需求
  getDemandDict: '/item/changeImage/getDemandDict.json', // 获取需求类型（装修场景字典信息）
  updateStoreImage: '/item/changeImage/updateStoreImage.json', // 更换背景图
  getStoreImage: '/item/changeImage/getStoreImage.json', // 查询背景图
  sendAddNInfo: '/item/changeImage/sendAddNInfo.json', // 商机二维码报名接口


  // 三翼鸟工作台获取平台户路径
  applyCreate: '/paycenter/zt/applyCreate.json',
  // 查询店铺主是否为企业
  storeEconKind: '/wing/store/info/storeEconKind',

  // 换新卡
  getMyCardCount: '/coupon/v1/card/display/getMyCardCount.json', // 我的换新卡数量
  getMyCards: '/coupon/v1/card/display/getMyCards.json', // 我的优惠券
  queryPriceByCardSkus: '/coupon/v1/card/display/queryPriceByCardSkus.json', // 批量获取卡商品最该可优惠金额
  getProductsByCardId: '/coupon/v1/card/display/queryCardProducts.json', // 换新卡适用商品列表
  getCanUseRechargeCardList: '/v3/h5/order/card/getCanUseRechargeCardList.json', // 结算页获取换新卡
  receiveCards: '/v3/h5/order/card/receiveCards.json', // 自动领卡
  receiveCoupons: '/v3/h5/order/card/receiveCoupons.json', // 自动领券
  confirmSelectedCard: '/v3/h5/order/card/confirmSelectedCard.json', // 确认选择换新卡
  // 佣金货款账单详情
  getGoodsAmountDetail: '/paycenter/billcenter/getGoodsAmountDetail.json',
  // 网单 详情新接口
  // 智家宝
  wdRevenue: '/v3/mstore/sg/local/wdRevenue.json', // 智家宝 查询
  getKjtAccount: '/v3/kjt/getKjtAccount.json', // 获取快捷通token和金额
  myRevenueList: '/v3/mstore/sg/local/myRevenueList.json', // 智家宝收益 - 账单列表查询
  sgbBillList: '/paycenter/SgbBill/page.json', // 智家宝账单
  drawCash: '/v3/kjt/drawCash.json', // 智家宝提现
  genToken: '/v3/kjt/genToken.html', // 直接宝登录成功后回调接口
  // 待缴费查询接口
  debtQuery: '/paycenter/pay/store/debtQuery.json',
  // 查询商家挂帐明细
  debtDetailQuery: '/paycenter/pay/store/debtDetailQuery.json',

  // 门店购物清单需求相关
  getAttrAndSkku: '/item/sgProduct/purchase/getAttrAndSkku.json',
  getMdShoppingList: '/api/cpc/cart/inventory/agg/v1/list',
  getMdShoppingCart: '/api/cpc/cart/inventory/v1/list',
  bactchDeleteGoods: '/api/cpc/cart/inventory/v1/batch/remove',
  getScenePrice: '/api/cpc/cart/inventory/v1/batch/getPrice',
  updateShoppingCart: '/api/cpc/cart/inventory/v1/update',
  batchUpdateShoppingCart: '/api/cpc/cart/inventory/v1/batch/update',
  getFkUrl: '/api/cpc/cart/inventory/v1/fkurl',
  changePrice: '/api/cpc/cart/inventory/v1/batch/changePrice',
  canChangePrice: '/activity/exclusiveprice/shoppingListCanChangePrice.json',
  // 确认订单的发票信息是否完整
  verifyInvoice: '/v3/h5/invoice/verifyInvoice.json',

  // 店铺雷达一期
  getUserList: '/msdata/user/getUserList', // 用户列表
  getRealTimeActivity: '/msdata/user/getRealTimeActivity', // 实时数据
  getDetailedActivity: '/msdata/user/getDetailedActivity', // 用户明细
  sendTemplateMsg: '/im/msg/sendTemplateMsg', // 发送模板消息
  getTemplateList: '/msdata/messageTemplate/getTemplateList', // 获取模板列表
  setUpTemplate: '/msdata/messageTemplate/setUpTemplate', // 设置消息模板
  getMdStoreInfoList: '/msdata/messageTemplate/getMdStoreInfoList', // 门店
  getCustomizePageList: '/msdata/messageTemplate/getCustomizePageList', // 专题页
  getZnxTemplate: '/msdata/synMsgTemplate/getList', // 获取站内信渠道模版
  setZnxTemplate: '/msdata/synMsgTemplate/setUp', // 设置站内信渠道模版
  sendZnxMsg: '/im/msg/synMsgPush', // 发送站内信消息

  // 店铺会员
  getVipInfo: '/wing/shopMember/query', // 查询会员卡片信息
  joinVip: '/wing/shopMember/save', // 加入店铺会员
  logOutVip: '/wing/shopMember/cancel', // 注销店铺会员
  getTaskList: '/marketing/v1/getTaskList', // C端-获取门店任务列表
  shareFissionTask: '/marketing/v1/shareFissionTask', // C端-任务分享/再次分享
  receivePrize: '/marketing/v1/receivePrize', // C端-领取/核销奖励
  getFissionTaskPrize: '/marketing/v1/getFissionTaskPrize', // C端-获取实物奖励信息
  getMemberBenefit: '/marketing/v1/getMemberBenefit.json', // C端 入会获取会员权益
  getAllBenefitAndPrize: '/marketing/v1/getAllBenefitAndPrize.json', // C端 入会获取会员权益
  queryShopVipStatus: '/wing/shopMember/manage', // 查询店铺会员开启状态
  // 员工内购
  getStaffCategoryByCurrentTime: '/sg/cms/homepage/getStaffCategoryByCurrentTime.json', // 内购品类区
  getInsourcingProductList: '/productdomain/api/productList/getInsourcingProductList', // 内购商品列表
  getInternalCartList: '/api/cpc/cart/emp/pur/v1/list', // 内购购物车列表
  getIntervalCartNum: '/api/cpc/cart/emp/pur/v1/num', // 员工内购-获取购物车数量
  internalCartRemoveMult: '/api/cpc/cart/emp/pur/v1/batch/remove', // 员工内购-批量移除购物车中商品接口
  internalCartUpdate: '/api/cpc/cart/emp/pur/v1/update', // 员工内购-更新购物车商品信息接口
  internalCartAdd: '/api/cpc/cart/emp/pur/v1/add', // 员工内购-添加商品到购物车接口
  interCartMultiAdd: '/api/cpc/cart/emp/pur/v1/batch/add', // 员工内购-批量添加商品到购物车接口
  internalCartRemove: '/api/cpc/cart/emp/pur/v1/remove', // 员工内购-移除购物车中商品接口
  checkInternalAccountStatus: '/wing/internalPurchase/checkAccountStatus', // 根据手机号员工号校验接口
  recordAccountBind: '/wing/internalPurchase/recordAccountBind', // 绑定账号接口
  recordSignNotice: '/wing/internalPurchase/recordSignNotice', // 记录签订协议接口
  recordVisitLog: '/wing/internalPurchase/recordVisitLog', // 记录访问日志接口
  // getInternalRichText: '/v3/mstore/sg/richText2.json', // 获取内购富文本
  internalDynamic: '/item/sgProduct/product/insouring/dynamic.json', // 内购商品动态接口
  getCateByTag: '/productdomain/api/cate/getCateByTag', // 内购分类页查询分类

  getInsourcingInfo: '/sh/order/insourcing/v/getInsourcingInfo.json', // 获取内购额度
  rulePage: '/wing/internalPurchase/rulePage', // 获取内购规则
  querySignedContent: '/wing/internalPurchase/querySignedContent', // 获取内购规则详情
  userInfo: '/wing/internalPurchase/userInfo', // 获取个人信息
  findElementInfo: '/sg/cms/homepage/findElementInfo.json', // 首页各区域展示配置

  // 交互师
  checkActivated: '/im/interaction/checkActivated',
  activateIM: '/im/interaction/activateIM', // 交互师激活聊天
  getDesignerInfo: '/im/interaction/getDesignerInfo', // 获取激活聊天页 的信息
  getRecordZftStatus: '/paycenter/merchantAccount/getRecordZftStatus.json', // 查询建档和支付宝状态
  getOrderProductNoticeList: '/v3/h5/order/notice/getOrderProductNoticeList.json', // 获取网单公告列表

  // 二维码中台化
  getQrExtraParam: '/api/basedict/mcode/getExtra', // 通过流水号获取二维码必要参数接口
  genreateQr: '/api/basedict/mcode/generate', // 生成二维码
  checkCRMUserValid: '/wing/crm/checkUserValid', // 校验是否为CRM用户，20230905 后废弃，因为这个接口的上游改变了策略，添加个参数 mdcode
  checkCrmSubUserEnable: '/wing/crm/checkCrmSubUserEnable', // 校验是否为CRM用户
  getMyQrcodeList: '/api/basedict/matrixCode/page', // 我的二维码列表
  createMatrixCode: '/api/basedict/matrixCode/bind', // 创建我的二维码
  getFullAddress: '/v3/h5/residential/getFullAddress.json', // 查询平台到小区地址
  // 场景套购
  queryStoreFtState: '/item/scene/ft/state', // 查询门店套购状态
  queryStoreFTCategory: '/item/scene/ft/classify', // 查询门店套购分类
  queryStoreFTList: '/item/scene/ft/md/list', // 查询门店套购列表
  getFtDetail: '/item/scene/ft/detail', // 查询门店套购详情
  getFtCate: '/productdomain/api/ft/cates', // 查看套购商品列表
  getSelectDetailData: '/api/cpc/cart/inventory/ft/v1/list', // 获取套购 购物清单
  addSceneCart: '/api/cpc/cart/inventory/v1/add', // 商品加入购物清单接口
  batchAddSceneCart: '/api/cpc/cart/inventory/v1/batch/add', // 批量加入购物清单接口

  handleOrderPlace: '/v3/h5/order/handleOrderPlace.json', //下单类型
  // 代客下单
  agentPayInit: '/sh/order/private/v/userSmfx.json', // 代客下单支付-用户扫码或点击分享链接
  agentPayGuide: '/sh/order/private/v/getOrderOfCustomerList.json', // 代客下单支付-导购端——查询订单详情
  agentPayUser: '/sh/order/private/v/getOrderOfCustomerDetailList.json', // 代客下单支付-用户端——查询订单详情
  agentPayMobile: '/sh/order/v/updateOrderMember.json', // 代客下单支付-手机号校验接口
  agentPayQrcode: '/mstore/order/getOrderQRCode.json', // 代客下单支付-订单二维码
  changeOrder: '/v3/h5/order/changeOrder.json', // 更换下单方式
  addCustomerAddress: '/v3/h5/order/addCustomerAddress.json', // 检验并保存订单收货地址
  getCustomerAddress: '/v3/h5/order/getCustomerAddress.json', // 查询顾客收货地址
  getGrantCouponUser: '/v3/h5/order/getGrantCouponUser.json', //获取顾客授权结果
  updateStock: '/v3/h5/order/updateStock.json', // 更新库存类型
  querySharerDisplayInfo: '/wing/member/order/querySharerDisplayInfo', // 查询分享人姓名
  updateGrantCoupon: '/v3/h5/order/updateGrantCoupon.json', // 更新优惠券授权
  getCustomerInfo: {
    url: '/sh/order/v/getCustomerInfo.json',
    method: 'POST',
    baseURL: config.OHOST,
    headers: {
      'Content-Type': 'application/json'
    }
  }, // 获取客户信息
  insertCustomerAddress: {
    url: '/v3/h5/order/insertCustomerAddress.json',
    method: 'POST'
  }, // 代客下单新建收货地址——地址保存

  // 快下单
  getStoreProduct: '/productdomain/api/quotation/queryProductList',
  queryMyQuotation: '/productdomain/api/quotation/queryMyQuotation', //查看自己报价单列表
  queryByRtSeq: '/productdomain/api/quotation/queryByRtSeq', //根据流水号获取报价单
  queryProductListBySku: '/productdomain/api/quotation/queryProductListBySku', //指定sku查查询
  quickOrderSave: '/productdomain/api/quotation/save', // 保存报价单
  quickOrderUpdateProduct: '/productdomain/api/quotation/updateProduct', //修改报价单
  quotationClear:'/productdomain/api/quotation/clear', //清空报价单
  quotationShare:'/productdomain/api/quotation/share', //分享保存方案
  qutoationSaveFromSeq: '/productdomain/api/lk/saveFromSeq', //保存方案
  qutoationModifyQuotation: '/productdomain/api/quotation/modifyQuotation', //修改分享出去的报价单
  qutoationTransformSeq: '/productdomain/api/quotation/transformSeq', //报价单去结算
  qutoatiionQueryBySeq :'/productdomain/api/quotation/queryBySeq', //获取已生效报价单
  qutoationEnable: '/productdomain/api/quotation/enable', //报价单生效接口
  qutoationPreOrderFromLk: '/productdomain/api/quotation/preOrderFromLk', //我的方案列表去结算
  qutoationSaveFromLK: '/productdomain/api/quotation/saveFromLk', //我的方案列表创建报价单
  qutoationSaveFromPaySeq: '/productdomain/api/lk/saveFromPaySeq', //支付结果页保存法案
  qutoationAnalysisRtSeq: '/productdomain/api/quotation/analysisRtSeq', //根据实时流水号计算利润中心
  qutoationShareFromLk: '/productdomain/api/quotation/shareFromLk', //我的方案分享
  qutoationProfitcenter: '/productdomain/api/lk/mdlk/profitcenter', //获取门店方案利润中心聚合信息
  qutoationMdList: '/productdomain/api/lk/mdlk/list', //获取门店轻设计场景列表
  qutoationDefaultName: '/productdomain/api/quotation/defaultName', // 获取方案默认名称
  qutoationBlackButtons: '/productdomain/api/buttonStrategy/getBlackedButton', //快下单按钮控制逻辑
  qutoationCouponDetail: '/price/v1/product/discount/detail', //报价单优惠明细

  // plus会员
  entrance: '/domaindiscount/product/plus/entrance', // plus会员开通入口
  getPlusOrderProductList: '/v3/h5/order/plusVIP/getPlusOrderProductList.json', // 获取PLUS会员卡片列表
  getPlusVipGiftsInfo: '/sh/order/plus/vip/v/getPlusVipGiftsInfo', // 获取PLUS会员的礼品卡信息
  getPlusVipRightsInfo: '/sh/order/plus/vip/v/getPlusVipRightsInfo', // 获取PLUS会员的权益数据
  selectPlusGift: '/v3/h5/order/plusVIP/selectPlusGift.json', // 选择plus会员开卡礼品
  cancelOpenPlusAccount: '/v3/h5/order/plusVIP/cancelOpenPlusAccount.json', // 取消开通plus
  submitPlusOrder:'/v3/h5/order/plusVIP/submitPlusOrder.json', // 用户开通plus会员，提交订单

  // 发票超时赔付
  invoiceApply: '/v3/h5/invoiceCompensation/apply.json', // 用户申请发票超时赔付
  invoiceApplySubmit: '/v3/h5/invoiceCompensation/submit.json', // 提交赔付申请信息
  invoiceApplyDetail: '/v3/h5/invoiceCompensation/getDetailNew.json', // 查看发票超时赔付详情
  invoiceCompensationcancel: '/invoiceweb/invoiceCompensation/cancel', //申请撤销

  // 意向派单
  getOrderCounts: '/msdata/order/getCounts', // 列表数量
  getOrderMdInfo: '/wing/md/info/queryMdInfoByIdentity', // 门店筛选
  getUnassignedList: '/msdata/order/getUnassignedList', // 未分派列表
  getOrderList: '/msdata/order/getList', // 跟进中/完成/关闭列表
  getInvaildList: '/msdata/orderInvalid/getList', // 失效列表
  getEmployeeList: '/msdata/order/getEmployeeList', // 员工列表
  intendedAddComment: '/msdata/orderComment/addComment', // 添加备注
  assignOrders: '/msdata/orderState/assignOrders', // 分派
  updateMemberId: '/msdata/order/updateMemberId', // 更新memberId
  toBusiness: '/msdata/orderState/toBusiness', // 转商机
  confirmOrder: '/msdata/orderState/confirmOrder', // 转订单
  closeOrder: '/msdata/orderState/close', // 关闭工单
  updateOrderAddress: '/msdata/order/updateAddress', // 更新工单地址信息

  sendVerifyCode: '/item/booking/verifyCode', // 非标品的预约 发送验证码
  nonStandard: '/item/booking/nonStandard', // 生态非标品一键预约

  //海尔卡券
  getShopAllCoupon: '/coupon/dataBoard/getShopAllCoupon.json', // 获取指定商家下所有优惠券的统计信息
  findShopCoupon: '/coupon/dataBoard/findShopCoupon.json', //分页获取指定商家可参与活动的用券信息
  getShopActivityCoupon: '/coupon/dataBoard/getShopActivityCoupon.json', //获取指定商家指定活动下指定券的用券信息

  // 凑单折扣
  getDiscountProductList: '/item/collect/discount/getDiscountProductList.json', // 获取凑单折扣商品下的其他商品
  getProductCollectDiscount: '/item/collect/discount/getProductCollectDiscount.json', // 查询凑单折扣商品价格

  // 权益相关
  queryOrderRight: '/sh/order/right/v/queryOrderRight.json', // 查询权益
  getOrderRightCache: '/sh/order/right/v/getOrderRightCache.json', // 权益查询历史
  delOrderRightCache: '/sh/order/right/v/delOrderRightCache.json',// 删除 权益查询历史

  getBlOrders: '/v3/h5/order/getBlOrders.json', // 获取补录订单信息
  blCheckOrderRight: '/v3/h5/order/blCheckOrderRight.json', // 获取补录权益列表
  blSetOrderRight: '/v3/h5/order/blSetOrderRight.json', // set补录权益
  blSubmitOrderRight: '/v3/h5/order/blSubmitOrderRight.json', // 权益补录提交
  dealOrderRightLog: '/sh/order/right/v/dealOrderRightLog.json', // 点击权益限制按钮 记录日志接口
  // 商品权益批量查询接口
  queryOrderRightSingleExistList : '/sh/order/right/v/queryOrderRightSingleExistList.json',

  getRightsAndCoupon: '/productdomain/api/productList/getRightsAndCoupon', //权益及优惠券信息查询

  // 商城订单迭代列表
  getOrderProductIteration: '/sh/freeorder/v/getOrderProductIteration.json',
  // 单条查询商场订单审核数据
  queryFreeOrderAuditInfo: '/sh/freeorder/v/queryFreeOrderAuditInfo.json',
  // 批量查询商场订单审核数据
  queryFreeOrderAuditList: '/sh/freeorder/v/queryFreeOrderAuditList.json',
  // 商场订单提交审核
  doAudit: '/sh/freeorder/v/doAudit.json',

  // 统仓配送/安装改约相关
  queryReserveInfo: '/v3/h5/order/reserve/queryReserveInfo.json', // 查询网单预约信息
  queryReserveTimeRange: '/v3/h5/order/reserve/queryReserveTimeRange.json', // 查询预约时间的可选范围
  submitReserveTime: '/v3/h5/order/reserve/submitReserveTime.json', // 提交预约时间
  queryReservationInfo: '/sh/order/reserve/v/queryReservationInfo.json', // 查询网单上的预约信息
  submitReservationInfo: '/sh/order/reserve/v/submitReservationInfo.json', // 提交网单上的预约信息
  queryReserveTimeRange2: '/sh/order/reserve/v/queryReserveTimeRange', // 查询预约时间范围

  fileToUrl: '/wing/file/upload', // 本地图片返url
  saveLogoUrl: '/wing/store/info/saveLogoUrl', // 保存店铺logo图片

  userDisplayInfo:'/wing/member/info/display',//私域、移动工作台展示信息查询

  // 发现好货-好物推荐（店铺活动）
  getFlashStoreActivity:'/marketing/activity/v1/flashSales/storeActivity',

  getSecurityData: '/paycenter/init/securityData', //获取当前可用密钥

  // 协议变更提醒
  changeRemindRecord: '/wing/richText/changeRemindRecord', // 变更提醒查询
  signAgreement: '/wing/richText/signAgreement', // 记录协议签订
  // 洗衣机扫码领券
  receiveWashCoupons : '/coupon/v1/wash/receiveCoupons',
  getWashActivityInfo : '/coupon/v1/wash/getActivityInfo',

  // CSI满意度接口
  judgeIsDisturbed: '/api/basedict/csi/judgeIsDisturbed', // 是否触发免打扰
  judgeRegisterAndLoginTime: '/api/basedict/csi/judgeRegisterAndLoginTime', // 校验注册时间、登陆时间是否够七天
  getLabelList: '/api/basedict/csi/valuate/getLabelList', // 获取评价标签列表
  csiSave: '/api/basedict/csi/valuate/save', // 提交评价接口

  // 中室协接口
  getCidaUrl: { url: '/wing/cida/memberAuth' },//获取跳转链接

  // 查询逆向是否已申请
  checkOrderRepair:'/v3/h5/sg/order/checkOrderRepair.json',

  // 场景快下单
  getProfitcenterInfo: '/productdomain/api/lk/md/profitcenter', // 获取用户方案利润中心聚合信息
  getSceneList: '/productdomain/api/lk/list', // 获取轻设计场景列表
  deleteScenes: '/productdomain/api/lk/delete', // 删除轻设计场景
  updateScenes: '/productdomain/api/lk/update', // 更新轻设计场景内容
  pluPrice: '/price/v1/product/templete', // 更新轻设计场景内容
  sceneRelation: '/productdomain/api/fk/component/relation', // 获取单品Top5场景
  marking: '/productdomain/api/fk/component/relation/marking', // 商品是否存在关联场景
  sceneMainComponents: '/productdomain/api/fk/component/appliance/search', // 分页获取场景详情sku
  sceneAlternateComponents: '/productdomain/api/fk/component/extend/appliance/search', // 分页获场景详情备选sku
  sceneChangePirce: '/activity/price/change/generate.json', // 购物清单改价
  sceneSaveCase: '/productdomain/api/lk/save', // 快下单保存方案
  analysis: '/productdomain/api/lk/profitcenter/analysis', // 计算利润中心归属

  //三翼鸟平台--平台合伙人管理 【2023.11月份董晓玲新增需求，用于合伙人大赛】
  getSYNPartnerList: '/wing/pfPartner/queryPfPartnerInPermission', //合伙人列表、搜索结果 接口
  getSYNPartnerDetail: '/wing/pfPartner/queryOtherPfPartnerInfo', //三翼鸟平台合伙人详情,
  deleteSYNPartner: '/wing/pfPartner/opCooperateMd', //更新合伙人门店接口，此处用于删除平台合伙人
  addPartnerRemark: '/wing/pfPartner/addPfPartnerRemark', // 合伙人详情，添加备注
  
  // 新单品页 
  productDetailInfo: '/item/api/productDetail/v1/info', // 静态接口
  productDetailDynamic: '/item/api/productDetail/v1/dynamic', // 动态接口
  productDetailExternal: '/item/api/productDetail/v1/external', // 商品补充接口
  productDetailUserInfo: '/item/api/productDetail/userInfo', // 用户商品接口
  productDetailSkkuInfo: '/item/api/productDetail/skkuInfo', // sku商品接口
  getFreight: '/item/purchase/getFreight.json', // 获取运费
  
  // 备件
  uplusScan: '/api-gw/wisdomdevice/device/instructions/v1/scan',
  getPlatProductList: '/productdomain/api/productList/getPlatProductList', // 获取平台商品列表
  accessoryProducts: '/productdomain/api/accessory/products',
  
  // 合同管理
  queryByBizCodeOrContactNo: '/wing/contact/queryByBizCodeOrContactNo', // 根据商机ID合同号查询合同信息
  contractAdd: '/wing/contact/add', // 新增合同接口
  contractUpdate: '/wing/contact/update', // 更新合同接口
  queryContactInPermission: '/wing/contact/queryContactInPermission', //工作台查询合同列表接口
  
  // 家居局改焕新
  homeGetContract: '/sh/order/home/v/getContract', // [cbs]查询网单的合同
  homeBindContract: '/sh/order/home/v/bindContract', // [cbs]网单绑定合同
  homeProductList: '/sh/order/home/v/homeProductList.json', // [cbs]查看物流跳转家居列表详情
  homeProductTrack: '/sh/order/home/v/homeProductTrack.json', // [cbs]家居订单物流详情

  // 采购专区
  // 采购专区-购物车
  // [yapi](https://stp.haier.net/project/638/interface/api/cat_47352)
  procurementCartBatchRemove: '/api/cpc/procurementCart/v1/batch/remove', // [采销购物车]批量移除购物车商品
  procurementCartUpdate: '/api/cpc/procurementCart/v1/update', // [采销购物车]更新商品数量
  procurementCartAdd: '/api/cpc/procurementCart/v1/add', // [采销购物车]添加商品到购物车
  procurementCartRemove: '/api/cpc/procurementCart/v1/remove', // [采销购物车]移除购物车商品
  procurementCartNum: '/api/cpc/procurementCart/v1/num', // [采销购物车]获取购物车中商品数量
  procurementCartList: '/api/cpc/procurementCart/v1/list', // [采销购物车]获取购物车列表
  procurementGetSeries: '/productdomain/api/procurement/getSeries',//【采购专区】获取系列列表
  procurementQueryFilterData: '/productdomain/api/procurement/queryFilterData', // 获取筛选条件

  // 采购专区-协议相关
  // https://stp.haier.net/project/791/interface/api/cat_47392
  procurementPolicyIsSigned: '/marketing/policy/isSigned', //用户是否已签订进行中的政策协议
  procurementPolicyList: '/marketing/policy/list', // 查询政策协议是否签订
  procurementPolicySign: '/marketing/policy/sign', // 签订政策协议
  procurementPolicyDetail: '/marketing/policy/detail', // 政策详情
  procurementAgreementDetail: '/marketing/policy/agreementDetail', // 政策协议详情
  getInvoiceSynSummary: '/invoiceweb/syn/summary/getInvoiceSynSummary', // 开票明细
  procurementPackageList: '/productdomain/api/procurement/package/list', // 套购专区
  validateOrder: '/v3/h5/order/validateOrder.json', // 验证订单
}
export default URL
