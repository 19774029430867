import http from '../http'
import URL from '../url'

// [采销购物车]批量移除购物车商品
export const procurementCartBatchRemove = (data) => {
  return http.request({
    url: URL.procurementCartBatchRemove,
    method: 'post',
    data,
  })
}

// [采销购物车]更新商品数量
export const procurementCartUpdate = (data) => {
  return http.request({
    url: URL.procurementCartUpdate,
    method: 'post',
    data,
  })
}

// [采销购物车]添加商品到购物车
export const procurementCartAdd = (data) => {
  return http.request({
    url: URL.procurementCartAdd,
    method: 'post',
    data,
  })
}

// [采销购物车]移除购物车商品
export const procurementCartRemove = (data) => {
  return http.request({
    url: URL.procurementCartRemove,
    method: 'post',
    data,
  })
}

// [采销购物车]获取购物车中商品数量
export const procurementCartNum = (params) => {
  params._t = Date.now();
  return http.request({
    url: URL.procurementCartNum,
    method: 'get',
    params,
  })
}

// [采销购物车]获取购物车列表
export const procurementCartList = (params) => {
  params._t = Date.now();
  return http.request({
    url: URL.procurementCartList,
    method: 'get',
    params,
  })
}

// 【采购专区】查询系列定义接口
// https://stp.haier.net/project/638/interface/api/231823
export const getSeriesDefine = (params) => {
  params._t = Date.now();
  return http.request({
    url: URL.procurementGetSeries,
    method: 'get',
    params,
  })
}

// 根据采购场景码scene（SYNCG）查询汇聚类目接口
export const getStoreProductFirstCate = (params) => {
  params._t = Date.now();
  return http.request({
    url: URL.getStoreProductFirstCate,
    method: 'get',
    params,
  })
}

//【采购专区】获取搜索筛选项
export const procurementQueryFilterData = (params) => {
  params._t = Date.now();
  return http.request({
    url: URL.procurementQueryFilterData,
    method: 'get',
    params,
  })
}

// 用户是否已签订进行中的政策协议
export const procurementPolicyIsSigned = (params) => {
  params._t = Date.now();
  return http.request({
    url: URL.procurementPolicyIsSigned,
    method: 'get',
    params,
  })
}

// 采购专区-协议相关
// https://stp.haier.net/project/791/interface/api/cat_47392
export const procurementPolicyList = (params) => {
  params._t = Date.now();
  return http.request({
    url: URL.procurementPolicyList,
    method: 'get',
    params,
  })
}

// 签订政策协议
export const procurementPolicySign = (data) => {
  return http.request({
    url: URL.procurementPolicySign,
    method: 'post',
    data,
  })
}

// 政策详情
export const procurementPolicyDetail = (params) => {
  params._t = Date.now();
  return http.request({
    url: URL.procurementPolicyDetail,
    method: 'get',
    params,
  })
}


// 政策协议详情
export const procurementAgreementDetail = (params) => {
  params._t = Date.now();
  return http.request({
    url: URL.procurementAgreementDetail,
    method: 'get',
    params,
  })
}

// 获取门店商品 与快下单共用同一接口
export const getStoreProduct = (data) => {
  return http.request({
    url: URL.getStoreProduct,
    method: 'post',
    data,
  })
}

// 开票明细
export const getInvoiceSynSummary = (data) => {
  return http.request({
    url: URL.getInvoiceSynSummary,
    method: 'post',
    data,
  })
}

// 采购专区-套餐列表
export const procurementPackageList = (data) => {
  return http.request({
    url: URL.procurementPackageList,
    method: 'post',
    data,
  })
}

// 验证订单
export const validateOrder = (data) => {
  return http.request({
    url: URL.validateOrder,
    method: 'post',
    data,
  })
}
export default {
  procurementCartBatchRemove,
  procurementCartUpdate,
  procurementCartAdd,
  procurementCartRemove,
  procurementCartNum,
  procurementCartList,
  getSeriesDefine,
  getStoreProductFirstCate,
  procurementQueryFilterData,
  procurementPolicyList,
  procurementPolicySign,
  procurementPolicyDetail,
  procurementAgreementDetail,
  getStoreProduct,
  getInvoiceSynSummary,
  procurementPackageList,
  validateOrder,
};