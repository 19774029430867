import utils from '@/utils/utils'

export default {
  namespaced: true,
  state: {
    tempInvoice: null,
    supportedInvoiceTypes: utils.strJSONParse(sessionStorage.getItem('orderInvoiceSupportedTypes'), []), // 支持的发票类型
    pageInfo: utils.strJSONParse(sessionStorage.getItem('orderInvoicePageInfo')),
    invoiceTypes: {
      1: '增值税专用发票',
      2: '增值税电子普通发票',
      3: '增值税纸质普通发票',
      4: '增值税电子专用发票',
      5: '增值税数电普通发票',
      6: '增值税数电专用发票',
    },
    curSelectedInvoiceType: '',
    isChangeInvoiceTitle: false,
    editInvoiceTitle: utils.strJSONParse(sessionStorage.getItem('editInvoiceTitleInfo')), // 编辑发票抬头
    selectedInvoiceTitle: utils.strJSONParse(sessionStorage.getItem('selectedInvoiceTitleInfo')), // 选择的编辑发票抬头
  },
  mutations: {
    setSupportedInvoiceTypes(state, types) {
      state.supportedInvoiceTypes = types
      sessionStorage.setItem('orderInvoiceSupportedTypes', JSON.stringify(types))
    },
    setTempInvoice(state, info) {
      state.tempInvoice = info
    },
    setCurSelectedInvoiceType(state, type) {
      state.curSelectedInvoiceType = type
    },
    setPageInfo(state, info) {
      state.pageInfo = info
      sessionStorage.setItem('orderInvoicePageInfo', JSON.stringify(info))
    },
    setIsChangeInvoiceTitle(state, value) {
      state.isChangeInvoiceTitle = value
    },
    setSelectedInvoiceTitle(state, info) {
      state.selectedInvoiceTitle = info
      sessionStorage.setItem('selectedInvoiceTitleInfo', JSON.stringify(info))
    },
    setEditInvoiceTitle(state, info) {
      state.editInvoiceTitle = info
      sessionStorage.setItem('editInvoiceTitleInfo', JSON.stringify(info))
    },
    resetInvoiceData(state) {
      state.selectedInvoiceTitle = null
      // state.supportedInvoiceTypes = []
      state.pageInfo = null
      // state.curSelectedInvoiceType = ''
      // sessionStorage.setItem('orderInvoiceSupportedTypes', '')
      sessionStorage.setItem('selectedInvoiceTitleInfo', '')
      sessionStorage.setItem('orderInvoicePageInfo', '')
    }
  },
}
