import ua from '@/utils/ua'
import env from '@/utils/env.js'

export const LBS_KEY = '7c964766c309377bc93c3d88470cd995';
export const ERROR_TOAST = '网络请求失败，请稍后重试';
export const DEFAULT_LOGO = 'https://cdn09.ehaier.com/shunguang/H5/www/img/sgmobile/uplus_logo.png';
export const DEFAULT_TITLE = '海尔智家';
export const DEFAULT_DESC = '海尔智家，海尔家电正品保障';
export const DEFAULT_LOGO_SYBIRD = 'https://cdn09.ehaier.com/shunguang/H5/www/img/sgmobile/new_sybird_logo.png';
export const DEFAULT_TITLE_SYBIRD = '海尔智家';
export const DEFAULT_DESC_SYBIRD = '定制智慧家 就找三翼鸟';
export const DEFAULT_TITLE_PARTNER = '三翼鸟－定制智慧家';
export const DEFAULT_TITLE_CONSULTANT = '我是三翼鸟合伙人，邀您一起加入'
export const DEFAULT_DESC_PARTNER = '海尔官方平台，海量正品，来三翼鸟一起瓜分亿元佣金吧！';
export const DEFAULT_LOGO_SHARE = ua.isSyBirdOrShopKeeper() ? DEFAULT_LOGO_SYBIRD : DEFAULT_LOGO;
export const DEFAULT_TITLE_SHARE = ua.isSyBirdOrShopKeeper() ? DEFAULT_TITLE_SYBIRD : DEFAULT_TITLE
export const DEFAULT_DESC_SHARE = ua.isSyBirdOrShopKeeper() ? DEFAULT_DESC_SYBIRD : DEFAULT_DESC
// export const INTERNAL_AUTHENTICATION_SERVICE_TEST = 'http://10.131.144.90:13025/ihaier-runtime-auth-server'
// export const INTERNAL_AUTHENTICATION_SERVICE_PROD = 'http://10.131.144.90:13025/ihaier-runtime-auth-server'
export const INTERNAL_AUTHENTICATION_SERVICE_TEST = 'https://m-test.ehaier.com/v3/platform/ihaier/checkUrl.json' // 内购鉴权服务 测试
export const INTERNAL_AUTHENTICATION_SERVICE_PROD = 'https://m.ehaier.com/v3/platform/ihaier/checkUrl.json' // 内购鉴权服务 生产
export const INTERNAL_AUTHENTICATION_APPID_PROD = 'cli_a22c491fa779100b' // 内购应用 appid 生产
export const INTERNAL_AUTHENTICATION_APPID_TEST = 'cli_a205abbc9afa100d' // 内购应用 appid 测试
export const INTERNAL_AUTHENTICATION_CLIENTID_TEST = '8de33acdf9b5ef3abf8e06881067250b' // 内购应用 clientId 测试
export const INTERNAL_AUTHENTICATION_CLIENTID_PROD = '1347adc45cb50879c7301873f7c5b881' // 内购应用 clientId 测试

export const TIM_SDK_APPID = 1400582587
export const TIM_SDK_APPID_TEST = 1400595597

export const RSA_PRIVATE_KEY =  env.isProdOrPreEnv() 
  ? 'MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCV/hdMl82Ure3nS3XJwRaTx3nIMUiK3zcpQBpW7oTTwyVEafU/O9t6/KiavvROmRlWs3zmT1lVbnSg3uSDT/ubX91KPlcQjRfUvua2/rZqmeKZX4hmVpLQgKIiaPPVoYSEMK1ZkVZIHD12gBjEZfn+07YjhnqLB+9S7esQ0u6qS2nuLOYEK3ZojomngMJvNcSdKCMYcagp2+KoTz3jIC9U0yhINcDwps5vcSLR5GFXTCiK7Z/RwlZfDB+aptS6d5bZ58lFnzK4yeg29G8P+L+6SAx1oqapt62G9Pf3KlZA0odJJoX18dCgE7GK7bRXOGJuy5FskMs6lSg0nQu2LuH7AgMBAAECggEACLaE5fcfgxaDbl8szXaA+D6rLVzniOGkk6ncndYnMyHv5UR+cZjpjABnTu3uxS4Rkb7Fb5+GYfcToVCxV4tq40CzWx+58QUxMDOStWigchrUVfJ9uuwmhSQjxvnvyCYky4UEXOKbb37sdxjD14zQ8+Oaff/O2IqydU/KyuRl5tB55MimfVengLAalmi5r+yI82CAyyurdDWbHXX1st68uX/b1aNs0TwV3PH9muf/pqzwvuCOFdYF8yQJ/yIgHpv35tCgu2E+5bGo07AQGjVwErY4R1FrhcSm29Q5Ph5ITIHcTo52en3WUmv7Wx2bN3TDJeg90//gooAH9fn9hPSLoQKBgQDKRJwD8ieJAAMvmna9hUgGNKwKVf4vzE0+2kZngBANTNX6iECIleyNrk7p8JHS/u/fx5b7q8XjgKW9/z4y9fv9sHTUNx9OLjztcKO4TGXe4Gxkd09xSuIiO6Unj51p6/SeFPQvZoK2/MOgNj/4Gez1ECyhu3lpuuJxBr1Np65fcQKBgQC91nLfpmoVD0UhQxUs4XDhC4Wzgt+xIMObU9icxROFvSx/FRJw3NowAitDYJNo2DhHswuIFMOkNxijKK5qLJJK52EZxYpudr5NU99ajayuaFRcX9mS5Rwb1m9qCQldYjzfbOY3BpHyA3O45cuAlt2ku+4Z1n/E3GIxXK3ytnl6KwKBgGLyhU/42hZPBz/2VDgKR12hzrePsbU4QGGK7f4by7515AeXN9qZXQtCRiBzKyMIy9exD882DKxqdqonfwn3A7rq43wdKxdXzz5oZ+70kLEjIGNVamuFsnIEs5pSyzLR1uyuW3YEs9VIeXqaj1iTdjO8IdlMmOx8G+Im5dxjNw5BAoGBAJ6jTE+VE2UR46UOF+3vXpLRPCNspF2ykYOFp6gqEExcb4irJUPnavVfeqPNWqHsves8tdA7+dmKqS3/WvEi2gV6IWyV5T62QAUCp81WQw1j5ctj1b+pcbnZkjU74/WDmHbk2+TzupZRSC0k6tBEQmfdG/W/h/oMaInzdvEyh4UNAoGBAJzHUmPo0yj4MS4vwaY362VSm95hsfRlq/69E23oC3knhCmOTOEnIbN7EIcf5tmh8DQKWyaUUgoh9KoB6erLdYC2H5+kXVrXq5dG9cW1PPaJtAEiAfpuzRkGQbMKZnjTWEtRQaHTyoCD9k5ukkmSMgBXHIuYkZLOJIdTjf2WK/ys' 
  : 'MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCn3O7Ztk15CGnzYP/Y25oZJ2W0z+dk9hxydTVILx0bcDFV+lexoiIyWL6ajUV3BDXSqXjwkPo5TqgIItp3UxhClRwOfCIudcBM7bCEAeS2EnBX8cvxMH5gzmA697Xt8K2dQyxafPmrlIeax9N6Q77yjV/oirfrvzTlhzxM8YoLvVyrtDIoV1vGoJX1M6fDOOJNtF0SskGxX2UcPrTfXsDLdkv1vlxHNHuH3ea89WEXVtaSdoE2hnzvesquUcLW/12RPfjA5F9i/+Y/tBcLlyUdAu0/sOzPzwHGbSf/f/O5f2KT+29GmfkDpp9R7Q12tsAC/6yGh7E62JnXmYk7YnAVAgMBAAECggEBAIUIqFbsjtukGmvi7QENkUPsIEO7HTS7g/IGB/MsO5I6SS9NluBVkWp7QJu16F6ZfJp26z0keNqgBrVkAeXdefWSvMiSAnj1ozCUk3cbFQpi9JwOKkX827uW81boaEupmb5vtSAFuc0MiqfVRDm6pQMPnfnqrjZss9xQYC7OokwIP2TLYD69w+ekGtqNkQ5UZBJ170q4+fSf4Xh/PYt3NBuVuOUJa9KgzP4hPbdJSEcqRQu+WzVMsAx3vSLEHTTglJpolu+OUjwDeQYhkJCqFgH2gpadOmy6juPWm+dL1KaVpPjwpOElFV1Nvm6i4pY6uTPb4uc3QA7cLEb32rNTUaUCgYEA0gQkPaN6ZnPxwdsVQ0CuxobvWeT/c9Cywq9CqKfD0mYH4m5e4DttfVE9SunzzwsKCV1ekeQYXylW8Q/05hfl49yA+14+4mLmDAOcyMuwUhr8qMJ5NlN/4xgE33ABIzD8qCzmD1xFrd5LmVvz/D8V6jIIsV2DudKZTlAB9zi1l8MCgYEAzJ4EKaRDy7YoOA0VsfKemciQS2heydkJEeAOHJ3xwBDkcPUw+0EyuZbXrLZ83jiquZIdJlq51i55pMxoK1KIV8GOqCUaew/LwSidPTHTmS/t6Am0rxFfZFL4OiOfqSeQQAu5W9NPyn2uIAmc5D2rZUhZ5QTvfFVD28WiykH4s0cCgYB/r17tTsgrJBD3IKDzy463SvCYwf6Bxn/4l5+6dI09DSV8CEiMofT2fP773fOKjJakjOzjXD9pE/Q1CdOGVT9C4xxUN7+Z2jEtWsDm39gqgxfb7qjNW8TkKAVGZ415fN4PzYRuEFaY2A80hVB4q05CnfrlAwsEXkMw9rnLOeJGCwKBgF9EPunoagxBnk4igXRdYsx03gHN8TwHSbrB17ApNXJYKxQQhKKmw2g3O+lU+aQ5O/u0dM/zs8xtzQMWjHWHMqWxH6tXwxGC1Dn1n+fq9/q9nwXG5xIPg5thKeNfzKlkGzxGeSGwq9i6VUTjVOHLeK5qVjhT6PC5fiOny9hhiNpnAoGAFRXfv+9VtAwYmuP3Zl1VKMk1PflUvtbah68Ea0Jpf7Ccilq9CTOe1Tg9Rl12J4G5T5G7YwAKu5CwRCmOQBWRC7CAR0bkh0xJscbFs7c93zGPOoyBJS2DDFhbCj44+UL4gnpqDDhjqxAKgWmFhSLwMNrsMHM2aAN3g7v4/FZ4JVk='
