export const tmpIdsMap = {
  '40379': '177',
  '40381': '207',
  '40383': '20',
  '40385': '191',
  '40387': '209',
  '40389': '187',
  '40391': '189',
  '40393': '205',
  '40395': '13',
  '40397': '211',
  '40399': '185',
  '40401': '213',
  '40403': '195',
  '40407': '217',
  '40409': '183',
}

export const customServiceSceneMap = {
  'cj090': '首页',
  'cj091': '商品详情页',
  'cj092': '我的',
  'cj093': '订单详情页',
  'cj094': '订单追踪页',
  'cj095': '退款详情页',
  'cj097': '协商历史',
  'cj099': '分类页-右上角',
  'cj100': '填写订单页-右上角',
  'cj101': '填写订单页-取消提示',
  'cj102': '收银台-右上角',
  'cj103': '收银台-取消提示',
  'cj104': '订单提交页-权益提示',
  'cj105': '三翼鸟场景详情',
  'cj106': '三翼鸟预约报名-店铺列表',
  'cj107': '三翼鸟预约报名-店铺列表-预约报名',
  'cj130': '智家掌柜-我的积分',
  'cd0256': '备件购买',
}


import utils from '@/utils/utils'
// utils.isUplus() ? : utils.isSyBird() ? : utils.isShopKeeper() ?
export const customServiceSceneMapNew = () => {
  return {
    '备件购买': {
      sceneId: 'cd0256',
      entranceId: 'jt',
      fromPageTitle: '备件购买',
    },
    '首页': {
      sceneId: 'cd0060',
      entranceId: 'jt',
      fromPageTitle: '首页',
    },
    '商品详情页': {
      sceneId: utils.isSyBird() ? 'cd0014' : utils.isShopKeeper() ? 'cd0043' : utils.isUplus() ? 'cd0074' : utils.isInternalChannel() ? 'cd0100' : 'cd0052',
      entranceId: 'jt',
      fromPageTitle: '商品详情页',
    },
    '我的': {
      sceneId: utils.isInternalChannel() ? 'cd0101' : 'cd0058',
      entranceId: 'jt',
      fromPageTitle: '我的',
    },
    '订单详情页': {
      sceneId: utils.isSyBird() ? 'cd0150' : utils.isShopKeeper() ? 'cd0048' : utils.isUplus() ? 'cd0079' : utils.isInternalChannel() ? 'cd0102' : 'cd0056',
      entranceId: 'jt',
      fromPageTitle: '订单详情页',
    },
    '退款详情页': {
      sceneId: utils.isSyBird() ? 'constmap_undefined' : utils.isShopKeeper() ? 'cd0049' : utils.isUplus() ? 'cd0080' : utils.isInternalChannel() ? 'constmap_undefined' : 'cd0057',
      entranceId: 'jt',
      fromPageTitle: '退款详情页',
    },
    '协商历史': {
      sceneId: utils.isSyBird() ? 'constmap_undefined' : utils.isShopKeeper() ? 'cd0042' : utils.isUplus() ? 'cd0073' : utils.isInternalChannel() ? 'constmap_undefined' : 'cd0051',
      entranceId: 'jt',
      fromPageTitle: '协商历史',
    },
    '分类页-右上角': {
      sceneId: utils.isInternalChannel() ? 'cd0099' : 'cd0059',
      entranceId: 'jt',
      fromPageTitle: '分类页-右上角',
    },
    '填写订单页-右上角': {
      sceneId: utils.isShopKeeper() ? 'cd0045' : utils.isUplus() ? 'cd0076' :  utils.isInternalChannel() ? 'cd0207': 'cd0053',
      entranceId: 'jt',
      fromPageTitle: '填写订单页-右上角',
    },
    '填写订单页-取消提示': {
      sceneId: 'cd0050',
      entranceId: 'jt',
      fromPageTitle: '填写订单页-取消提示',
    },
    '收银台-右上角': {
      sceneId: utils.isShopKeeper() ? 'cd0047' : utils.isUplus() ? 'cd0078' : utils.isInternalChannel() ? 'cd0208' : 'cd0055',
      entranceId: 'xf',
      fromPageTitle: '收银台-右上角',
    },
    '收银台-取消提示': {
      sceneId: utils.isSyBird() ? 'cd0015' : utils.isShopKeeper() ? 'cd0046' : utils.isUplus() ? 'cd0077' : 'cd0054',
      entranceId: 'jt',
      fromPageTitle: '收银台-取消提示',
    },
    '订单提交页-权益提示': {
      sceneId: utils.isShopKeeper() ? 'cd0044' : utils.isUplus() ? 'cd0075' : 'constmap_undefined',
      entranceId: 'jt',
      fromPageTitle: '订单提交页-权益提示',
    },
  }
}



