import utils from "@/utils/utils"
export default {
  namespaced: true,
  state: {
    orderSnFromVuex: sessionStorage.getItem('sg_payreslut_orderSn') || '',
    signPayItemInfo: utils.strJSONParse(sessionStorage.getItem('sg_signpayItem_info'), null),
  },
  getters: {
    getOrderSnFromVuex(state) {
      return state.orderSnFromVuex || ''
    },
  },
  mutations: {
    setSignPayItemInfo(state, info){
      state.signPayItemInfo = info || null
      sessionStorage.setItem('sg_signpayItem_info', JSON.stringify(info))
    },
    setOrderSnFromVuex(state, payload) {
      sessionStorage.setItem('sg_payreslut_orderSn',payload)
      state.orderSnFromVuex = payload
    }
  },
}
