<template>
  <van-nav-bar :title="title" @click-left="leftArrowClick" @click-right="rightClick" :fixed="fixed" :style="{'padding-top': `${statusBarHeight}px`, 'background': bgColor}">
    <slot name="title" slot="title"></slot>
    <slot v-if="leftArrow && !+$route.query.browerFirstIn" name="left" slot="left">
      <svg class="icon-svg icon-back" aria-hidden="true" :style="{'color': backArrowColor}">
        <use xlink:href="#icon-fanhui"></use>
      </svg>
      {{ leftText }}
    </slot>
    <slot name="right" slot="right"></slot>
  </van-nav-bar>
</template>

<script>
// import utils from '@/utils/utils'
import UplusApi from '@uplus/uplus-api'
const instance = new UplusApi()
instance.initDeviceReady()
export default {
  name: 'CustomHeader',
  props: {
    text: {
      type: String,
      default: '',
    },
    leftText: {
      type: String,
      default: '',
    },
    leftArrow: {
      type: Boolean,
      default: true,
    },
    backArrowColor:{
      type: String,
      default: '#000'
    },
    leftClick: {
      type: [Boolean, Function],
      default: false,
    },
    rightClick: {
      type: Function,
      default: () => {},
    },
    fixed: {
      type: Boolean,
      default: true
    },
    bgColor: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      title: this.text || document.title,
      leftArrowClick: null,
    }
  },
  computed: {
    statusBarHeight(){
      return this.$store.state.statusBarHeight
    }
  },
  created() {
    console.log('this.leftClick')
    console.log(this.leftClick)

    if (!this.leftClick) {
      this.leftArrowClick = () => {
        this.goBack()
      }
    } else {
      this.leftArrowClick = this.leftClick
    }
  },
  watch: {
    text: {
      handler(newVal) {
        this.title = newVal
      },
      immediate: true
    },
    $route(to) {
      let meta = to.meta
      if (meta.title) {
        this.title = meta.title
      } else if (!this.title) {
        this.title = document.title
      }

    },
  },
  mounted() {
    // const context = this;
    let meta = this.$route.meta
    if (meta.title) {
      this.title = meta.title
    }

    // 如果是收货地址部分，需要区分创建收货地址和编辑收货地址的title
    // const _href = window.location.href;
    // if (_href.indexOf('addressEdit') > 0) {
    //   context.title = _href.indexOf('addressEdit?id') > 0 ? '编辑收货地址' : '创建收货地址';
    // }
  },
  methods: {
    goBack() {
      this.$store.dispatch('doHistoryBack')
    },
  },
}
</script>

<style lang="scss" scoped>
.van-nav-bar__left {
  left: 0.24rem;
  display: flex;
  height: 100%;
  align-items: center;
}
.van-nav-bar__left, .van-nav-bar__right{
  img{
    -webkit-touch-callout: none;
    pointer-events: none;
  }
}
.van-nav-bar--fixed {
  z-index: 900;
  right: 0;
  @include max-width;
  // padding-top: calc(env(safe-area-inset-top, 0px) - 5px); /* 动态缩进，适配刘海屏 */

}

:deep(.van-nav-bar__title){
  // font-weight: 600;
  color: #111;
  font-size: 0.36rem;
}
.icon-back {
  display: inline-block;
  width: 0.48rem;
  height: 0.48rem;
}
[class*=van-hairline]::after{
  border: none;
}
</style>
