
const balance = [
  {
    path: '/balance',
    name: 'balance',
    component: () => import('@/viewsToB/balance/index.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '余额',
    }
  },
  {
    path: '/cashOut',
    name: 'cashOut',
    component: () => import('@/viewsToB/balance/cashOut/index.vue'),
    meta: {
      keepAlive: true,
      title: '提现',
      showHeader: false,
    }
  },
  {
    path: '/cash-out-success',
    name: 'cash-out-success',
    component: () => import('@/viewsToB/balance/cashOut/cash-out-success.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '提现',
    }
  },
  {
    path: '/billDetail',
    name: 'billDetail',
    component: () => import('@/viewsToB/balance/billDetail/index.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '账单详情',
    }
  },
  {
    path: '/transfer',
    name: 'transfer',
    component: () => import('@/viewsToB/balance/transfer/index.vue'),
    meta: {
      keepAlive: true,
      title: '转账',
    }
  },
  {
    path: '/transfer-success',
    name: 'transfer-success',
    component: () => import('@/viewsToB/balance/transfer/transfer-success.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '转账结果',
    }
  },
  {
    path: '/balance-pay-state',
    name: 'balance-pay-state',
    component: () => import('@/viewsToB/balance/payState/balance-pay-state.vue'),
    meta: {
      keepAlive: true,
      title: '支付状态',
      showHeader: false,
    }
  },
  {
    path: '/account-recharge',
    name: 'account-recharge',
    component: () => import('@/viewsToB/balance/recharge/index.vue'),
    meta: {
      keepAlive: true,
      title: '充值',
      showHeader: false,
    }
  },
  {
    path: '/recharge-success',
    name: 'recharge-success',
    component: () => import('@/viewsToB/balance/recharge/success.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '充值',
    }
  },
  {
    path: '/balance-bill-list',
    name: 'balance-bill-list',
    component: () => import('@/viewsToB/balance/balanceBillList/index.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '账单',
    }
  },
  {
    path: '/ensure-order-list',
    name: 'ensure-order-list',
    component: () => import('@/viewsToB/balance/ensureOrderList/index.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '账单',
    }
  },
  {
    path: '/ensureDetail',
    name: 'ensureDetail',
    component: () => import('@/viewsToB/balance/ensureOrderList/ensureDetail.vue'),
    meta: {
      keepAlive: true,
      title: '账单详情',
      showHeader: false,
    }
  },
  {
    path: '/transfer-list',
    name: 'transfer-list',
    component: () => import('@/viewsToB/balance/transfer/transfer-list.vue'),
    meta: {
      keepAlive: true,
      title: '转巨商汇货款',
      showHeader: false,
    }
  },
  {
    path: '/cash-list',
    name: 'cash-list',
    component: () => import('@/viewsToB/balance/cashOut/cash-list.vue'),
    meta: {
      keepAlive: true,
      title: '提现',
      showHeader: false,
    }
  },
  {
    path: '/recharge-list',
    name: 'recharge-list',
    component: () => import('@/viewsToB/balance/recharge/recharge-list.vue'),
    meta: {
      keepAlive: true,
      title: '充值',
      showHeader: false,
    }
  },
  {
    path: '/recharge-info',
    name: 'recharge-info',
    component: () => import('@/viewsToB/balance/recharge/recharge-info.vue'),
    meta: {
      title: '线下充值收款账号',
    }
  },
  {
    path: '/debt-list',
    name: 'debt-list',
    component: () => import('@/viewsToB/balance/debtList/debt-list.vue'),
    meta: {
      keepAlive: true,
      title: '欠费明细',
      showHeader: false,
    }
  },
  {
    path: '/balanceWorkbench',
    name: 'balanceWorkbench',
    component: () => import('@/viewsToB/balance/middle/index.vue'),
    meta: {
      keepAlive: true,
      title: '',
      showHeader: false,
    }
  },
  {
    path: '/balanceDetails',
    name: 'balanceDetails',
    component: () => import('@/viewsToB/balance/balanceDetails/index.vue'),
    meta: {
      keepAlive: true,
      title: '余额账单',
      showHeader: false,
    }
  },
]

export default balance
