import personalApi from '@/http/service/personal'
import mdApi from '@/thirdPartyLogics/index'

export default {
  namespaced: true,
  state: {
    curMdInfo: null,

    mdIndex: 0, //门店索引
    mdPage: 1, //门店分页
    mdPageSize: 10,
    mdList: [], //门店列表
    mdLoading: false,
    mdFinished: false,
  },
  mutations: {
    setCurMdInfo(state, info) {
      state.curMdInfo = info
    },
    setMdSelect(state, index){ 
      state.mdIndex = index
      state.curMdInfo = state.mdList[index]
    },
  },
  actions: {
    async getMdInfo({state, commit}){
      try {
        const res = await mdApi.getCurrentMdInfo()
        console.log('getCurrentMdInfo===>111',res)
        commit('setCurMdInfo', res)
      } catch (error) {
      }
    },
    async fetchMdData({state, commit}){
      state.mdLoading = true
      const { data } =  await personalApi.getMdIsUse()
      state.mdLoading = false
      console.log(data)
      let result = data.data
      if(result?.length < state.mdPageSize){
        state.mdFinished = true
      }
      state.mdPage += 1;
      state.mdList = state.mdList.concat(result)
      state.mdIndex = state.mdList.findIndex(item => item.mdCode === state.curMdInfo.mdCode)
    },
  }
}