import utils from '@/utils/utils'
export default {
  namespaced: true,
  state: {
    historySearchList: utils.strJSONParse(localStorage.getItem('historySearchList'), []),
    orderSearchList: utils.strJSONParse(localStorage.getItem('orderSearchList'), []),
    synPartnerSearchList: utils.strJSONParse(localStorage.getItem('synPartnerSearchList'), []), // 三翼鸟平台合伙人搜索记录
    // historySearchList: utils.strJSONParse(localStorage.getItem('storeHistorySearchList'), []), //店铺搜索记录
  },
  getters: {
    historySearchList: state => {
      let set = new Set(state.historySearchList)
      let list = Array.from(set)
      if (list.length > 10) {
        list = list.slice(0, 10)
      }
      return list
    },
    storeHistorySearchList: state => {
      let set = new Set(state.historySearchList)
      let list = Array.from(set)
      if (list.length > 10) {
        list = list.slice(0, 10)
      }
      return list
    },
    orderSearchList: state => {
      let set = new Set(state.orderSearchList)
      let list = Array.from(set)
      if (list.length > 10) {
        list = list.slice(0, 10)
      }
      return list
    },
    synPartnerSearchList: state => {
      let set = new Set(state.synPartnerSearchList)
      let list = Array.from(set)
      if (list.length > 5) {
        list = list.slice(0, 5)
      }
      return list
    },
  },
  mutations: {
    deletetHistoryItem(state, index) {
      state.historySearchList.splice(index, 1)
      // console.log(state.historySearchList)
      localStorage.setItem('historySearchList', JSON.stringify(state.historySearchList))
    },
    addHistoryItem(state, item) {
      if (item.trim() === '') {
        return
      }
      state.historySearchList.unshift(item.trim())
      let set = new Set(state.historySearchList)
      let list = Array.from(set)
      if (list.length > 10) {
        list = list.slice(0, 10)
      }
      localStorage.setItem('historySearchList', JSON.stringify(list))
    },
    deleteHistorySearchList(state) {
      state.historySearchList = [];
      localStorage.removeItem('historySearchList')
    },
    addOrderHistoryItem(state, item) {
      if (item.trim() === '') {
        return
      }
      state.orderSearchList.unshift(item.trim())
      let set = new Set(state.orderSearchList)
      let list = Array.from(set)
      if (list.length > 10) {
        list = list.slice(0, 10)
      }
      localStorage.setItem('orderSearchList', JSON.stringify(list))
    },
    deleteOrderSearchList(state) {
      state.orderSearchList = [];
      localStorage.removeItem('orderSearchList')
    },
    addSynPartnerHistoryItem(state, item) {
      if (item.trim() === '') {
        return
      }
      state.synPartnerSearchList.unshift(item.trim())
      let set = new Set(state.synPartnerSearchList)
      let list = Array.from(set)
      if (list.length > 5) {
        list = list.slice(0, 5)
      }
      localStorage.setItem('synPartnerSearchList', JSON.stringify(list))
    },
    deleteSynPartnerSearchList(state) {
      state.synPartnerSearchList = [];
      localStorage.removeItem('synPartnerSearchList')
    },
  },
  actions: {},
}
