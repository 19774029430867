const procurement = [
  {
    path: '/procurementZone',
    name: 'procurementZone',
    component: () => import('@/viewsToB/procurement/index.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '采购专区',
      needLogin: true,
    },
  },
  {
    path: '/procurementSearch',
    name: 'procurementSearch',
    component: () => import('@/viewsToB/procurement/search.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '采购专区',
      needLogin: true,
    },
  },
  {
    path: '/procurementPolicyManage',
    name: 'procurementPolicyManage',
    component: () => import('@/viewsToB/procurement/policyManage.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '政策管理',
      needLogin: true,
    },
  }, {
    path: '/procurementPolicyDetail',
    name: 'procurementPolicyDetail',
    component: () => import('@/viewsToB/procurement/policyDetail.vue'),
    meta: {
      keepAlive: true,
      showHeader: true,
      title: '政策详情',
      needLogin: true,
    },
  },
  {
    path: '/procurementAgreementDetail',
    name: 'procurementAgreementDetail',
    component: () => import('@/viewsToB/procurement/agreementDetail.vue'),
    meta: {
      keepAlive: true,
      showHeader: true,
      title: '产品协议详情',
      needLogin: true,
    },
  },
  {
    path: '/procurementInvoiceList',
    name: 'procurementInvoiceList',
    component: () => import('@/viewsToB/procurement/invoiceList.vue'),
    meta: {
      keepAlive: true,
      showHeader: true,
      title: '开票明细',
      needLogin: true,
    },
  },

]

export default procurement
