export default [
  {
    path: '/smartProListTop',
    name: 'smartProListTop',
    component: () => import('@/views/smartList/index.vue'),
    meta: {
      showHeader: false,
      statusBar: true,
      title: '智家自营榜单',
    },
  },
  {
    path: '/smartAppliances',
    name: 'smartAppliances',
    component: () => import('@/views/smartList/appliances/index.vue'),
    meta: {
      showHeader: false,
      statusBar: true,
      title: '爆款直降',
    },
  },
  {
    path: '/smartWelfare',
    name: 'smartWelfare',
    component: () => import('@/views/smartList/welfare/index.vue'),
    meta: {
      showHeader: false,
      statusBar: true,
      title: '狂抢福利',
    },
  },
  {
    path: '/smartFullDiscount',
    name: 'smartFullDiscount',
    component: () => import('@/views/smartList/fullDiscount/index.vue'),
    meta: {
      showHeader: false,
      title: '满减专区',
    },
  },
  {
    path: '/fullDiscount',
    name: 'fullDiscount',
    component: () => import('@/views/smartList/fullDiscount/fullDiscount.vue'),
    meta: {
      showHeader: false,
      title: '凑单折扣',
    },
  }
]

