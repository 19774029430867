import http from '../http'
import Url from '../url'


export const getCartCList = async (data) => {
  try {
    const result = await http.post(Url.cartCList, data)
    return result.data
  } catch (e) {
    console.log(e)
  }
}

export const addCartCItem = async (data) => {
  try {
    const result = await http.post(Url.cartCAdd, data)
    return result.data
  } catch (e) {
    console.log(e)
  }
}

export const addCartCAddBatch = async (data) => {
  try {
    const result = await http.post(Url.cartCAddBatch, data)
    return result.data
  } catch (e) {
    console.log(e)
  }
}

export const removeCartCItem = async (data) => {
  try {
    const result = await http.post(Url.cartCRemove, data)
    return result.data
  } catch (e) {
    console.log(e)
  }
}

export const removeCartBatch = async (data) => {
  try {
    const result = await http.post(Url.cartCRemoveBatch, data)
    return result.data
  } catch (e) {
    console.log(e)
  }
}

export const updateCartCItem = async (data) => {
  try {
    const result = await http.post(Url.cartCUpdateNum, data)
    return result.data
  } catch (e) {
    console.log(e)
  }
}