const activity = [
  {
    path: '/renewedArea',
    name: 'renewedArea',
    component: () => import('@/views/activity/renewedArea.vue'),
    meta: {
      showHeader: false,
      title: '换新专区'
    }
  },
  // 政企内购
  {
    path: '/enterprise-promo',
    name:'enterprisePromo',
    component: () => import('@/views/activity/enterprisePromo.vue'),
    meta: {
      showHeader: false,
      needLogin: true,
      // title: '企业员工内购会'
    },
  },
  // 配件搜索
  {
    path: '/accessory-search',
    name:'accessorySearch',
    component: () => import('@/views/accessory/accessorySearch.vue'),
    meta: {
      showHeader: true,
      needLogin: true,
      title: '选择您要更换配件的家电'
    },
  },
  // 配件列表
  {
    path: '/accessory-list',
    name:'accessoryList',
    component: () => import('@/views/accessory/accessoryList.vue'),
    meta: {
      showHeader: true,
      needLogin: true,
      title: '配件选购'
    },
  },
]

export default activity