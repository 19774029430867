import http from '../http'
import url from '../url'
import utils from '@/utils/utils'
import config from '../../config'

export const test = (data) => {
  http.interceptors.request.use(
    function(config) {
      return config
    },
    function(error) {
      return Promise.reject(error)
    }
  )
  // 设置请求结束后拦截器
  http.interceptors.response.use(response => {
    return response
  })
  return http.request({
    baseURL: 'https://unidemo.dcloud.net.cn/',
    url: 'ajax/echo/text',
    dataType: 'text',
    method: 'get',
    data,
  })
}

// 到申请退款页面
export const orderRepair = (params) => {
  return http.request({
    url: url.orderRepair,
    method: 'GET',
    params,
  })
}

// 检查发票是否可用
export const refundVerifyInvoice = (params) => {
  return http.request({
    url: url.verifyInvoiceRefund,
    method: 'GET',
    params,
  })
}

// 提交申请退款接口
export const requestOrderRepair = (params) => {
  return http.request({
    url: url.requestOrderRepair,
    method: 'POST',
    data: params,
  })
}

// 提交修改申请退款接口
export const modifyOrderRepair = (params) => {
  return http.request({
    url: url.modifyOrderRepair,
    method: 'POST',
    data: params,
  })
}

// 退款详情
export const getOrderRepairsInfo = (data) => {
  return http.request({
    url: url.getOrderRepairsInfo,
    method: 'POST',
    data,
    baseURL: config.OHOST
  })
}

// 撤销退货退款申请
export const cancelOrderRepair = (params) => {
  return http.request({
    url: url.cancelOrderRepair,
    method: 'POST',
    data: params,
  })
}

// 评价页详情
export const commentPage = (params) => {
  return http.request({
    url: url.getCommentPage,
    method: 'GET',
    params,
  })
}

// 提交评价
export const submitComment = (params) => {
  return http.request({
    url: url.submitPingJia,
    method: 'POST',
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded',
    // },
    params,
  })
}

// 评价成功页面
export const commentSuccessPage = (params) => {
  return http.request({
    url: url.getSuccessPingJia,
    method: 'GET',
    params,
  })
}

// 售后列表
export const getOrderList = (params, config) => {
  // 内购订单统一传orderType=101
  if (utils.isInternalChannel() && !params.hasOwnProperty('orderType')) {
    params.orderType = 101
  }
  return http.get(url.myOrderUrl, {
    params,
    ...config,
  })
}
export const getRepairsList = (data) => {
  return http.post(url.getRepairsList, data, {
    baseURL: config.OHOST,
  })
}
// 新的订单列表接口 移动工作台
export const getOrderListNew = (params, configs) => {
  return http.post(url.myOrderUrlNew, params, { ...configs })
}

// 申请客服介入
export const requestXiaoer = (params) => {
  return http.request({
    url: url.requestXiaoer,
    method: 'POST',
    data: params,
  })
}

// 获取快递公司列表
export const getExpressList = (params) => {
  return http.request({
    // headers: {
    //   'TokenAuthorization': 'Bearerb3cb5a6d-b1e1-46c9-8e40-3ad5fff35b62374#K6U20HLDfw4ZYj1JFVrq6dK3hF4hoAKF014ZF1lxQI6A/shBbJx4qk7KLKO9WFlw'
    // },
    url: url.getExpressList,
    method: 'GET',
    params,
  })
}

// 填写快递信息
export const requestOrderRepairExpress = (params) => {
  return http.request({
    url: url.repairExpress,
    method: 'POST',
    data: params,
  })
}

// 协商历史（查询操作历史）
export const getOrderRepairHistory = (params) => {
  return http.request({
    url: url.repairHistory,
    method: 'GET',
    params,
  })
}

// 单个图片上传
export const upLoadCommentPicture = (params) => {
  return http.request({
    url: url.upLoadCommentPicture,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'POST',
    data: params,
  })
}

// 图片base64 批量商城
export const uploadPictureBybase64 = (params) => {
  return http.post(url.uploadPictureBybase64, params, {
    timeout: 15000
  }).catch(err => {
    return Promise.reject(err)
  })
}

// 银行卡信息校验
export const bankCardValidate = (params) => {
  return http.request({
    url: url.bankCardValidate,
    method: 'POST',
    data: params,
  })
}

// 查看评价
export const getComment = (params) => {
  return http.request({
    url: url.getPingJia,
    method: 'GET',
    params,
  })
}

// 追加评价
export const submitExperience = (params) => {
  return http.request({
    url: url.appendPingJia,
    method: 'POST',
    params,
  })
}

export default {
  test,
  orderRepair,
  requestOrderRepair,
  modifyOrderRepair,
  getOrderRepairsInfo,
  commentPage,
  submitComment,
  commentSuccessPage,
  getOrderList,
  getRepairsList,
  getOrderListNew,
  cancelOrderRepair,
  requestXiaoer,
  getExpressList,
  requestOrderRepairExpress,
  getOrderRepairHistory,
  upLoadCommentPicture,
  uploadPictureBybase64,
  bankCardValidate,
  getComment,
  submitExperience,
  refundVerifyInvoice
}
