<template>
  <div id="app" class="app" :class="{'internal': isInternalChannel, 'has-tabbar': $route.meta.showTabbar || $route.meta.showInternalTabbar, 'has-header': $route.meta.showHeader, 'has-download': showDownloadApp}">
    <DownloadHeader v-if="showDownloadApp" :type="$store.state.downloadAppType" @hidden="hideDownload"/>
    <CustomHeader v-if="$route.meta.showHeader" />
    <FloatTips v-if="$store.state.globTips" :childMenuId="$store.state.globTips"/>
    <div id="app-warp" class="app-warp" :style="{'padding-top': $route.meta.statusBar ? 0 : `${statusBarHeight}px`}">
      <keep-alive v-if="locationStatus">
        <router-view/>
      </keep-alive>
      <no-location v-else></no-location>
      <van-overlay v-show="!isNetWork" z-index='999'>
        <div class="network-mask">
          <img src="https://cdn09.ehaier.com/shunguang/H5/www/img/sgmobile/personalCenter/loseconnect.png" alt="">
          <p>网络不可用，请检查网络连接状态</p>
          <a href="javascript:;" class="close-network" @click="close">关闭</a>
        </div>
      </van-overlay>
    </div>
    <div class="back-arrow" v-if="$store.state.reviewTimeInfo.backUrl && $store.state.reviewTimeInfo.leftTime && isUplus" @click="appGoBack"><img src="https://cdn09.ehaier.com/shunguang/H5/www/img/sgmobile/common/back_white.png"/>返回</div>
    <div class="clock-icon" v-if="$store.state.reviewTimeInfo.leftTime > 0 && isUplus">{{$store.state.reviewTimeInfo.leftTime}}s</div>
    <Tabbar :items="tabBars" v-if="$route.meta.showTabbar && !($route.meta.showInternalTabbar && isInternalChannel)"></Tabbar>
    <internal-tabbar :items="tabBarsInternal" v-if="$route.meta.showInternalTabbar && isInternalChannel"></internal-tabbar>

  </div>
</template>
<script>
import utils from '@/utils/utils'
import CustomHeader from './components/header'
import UplusApi from '@uplus/uplus-api';
import qs from 'qs'
import { mapGetters } from 'vuex'
import thirdPartyLogic from './thirdPartyLogics/index'
const instance = new UplusApi()
instance.initDeviceReady()
// import Tabbar from './components/tabbar/tabbar'
import FloatTips from '@/components/floatTips'
const DownloadHeader = () => import('@/components/downloadHeader')
const NoLocation = () => import('@/components/no-location')
const Tabbar = () => import('./components/tabbar/tabbar')
const InternalTabbar = () => import('./components/tabbar/internal-tabbar')

export default {
  components: {
    CustomHeader,
    FloatTips,
    DownloadHeader,
    Tabbar,
    InternalTabbar,
    NoLocation
  },
  provide () {
    return {
      reload: this.reload,
      globalProperty: () => ({
        // $TIM: this.$TIM,
        // $tim: this.$tim,
        $http: this.$http,
        $toggleSwitch: this.$toggleSwitch,
        $cdnImageUrl: this.$cdnImageUrl,
        $cdnImageUrlSuffix: this.$cdnImageUrlSuffix,
        $theme: this.$theme,
        $HOST: this.$HOST,
        $keepAliveDestroy: this.$keepAliveDestroy,
        $globalLoading: this.$globalLoading,
        $reviewTimetoast: this.$reviewTimetoast,
        $globalIcon: this.$globalIcon,
        $webpSurpport: this.$webpSurpport,
        $modalHelper: this.$modalHelper,
      })
    }
  },
  computed: {
    ...mapGetters('ihaier2Account', ['ihaier2UserInfo']),
    showDownloadApp() {
      return this.$route.meta.hasdownload && this.hasdownload && !this.isHaierApp && !this.isKTDP && +this.$store.state.downloadAppType  && !this.isInternalChannel
    },
    locationStatus() {
      return this.$store.state.locationStatus
    },
    statusBarHeight(){
      return this.$store.state.statusBarHeight
    }
  },
  data() {
    return {
      isUplus: utils.isHaierApp() || utils.isSyBirdH5(),
      isHaierApp: utils.isHaierApp(),
      isKTDP: utils.isKTDP(),
      isInternalChannel: false,
      hasdownload: true,
      isNetWork: true,
      isRouterAlive: true, // 重载页面
      tabBars: [
        {
          icon: {
            active: 'https://cdn09.ehaier.com/shunguang/H5/www/img/newvision/home.png',
            inactive: '//cdn09.ehaier.com/shunguang/H5/www/img/newvision/homes.png',
          },
          title: '首页',
          path: '/',
          name: 'home',
        },
        {
          icon: {
            active: '//cdn09.ehaier.com/shunguang/H5/www/img/newvision/guang x@2x.png',
            inactive: '//cdn09.ehaier.com/shunguang/H5/www/img/newvision/guang@2x.png',
          },
          title: '分类',
          path: '/category',
          name: 'category',
        },
        {
          icon: {
            active: '//cdn09.ehaier.com/shunguang/H5/www/img/newvision/car.png',
            inactive: '//cdn09.ehaier.com/shunguang/H5/www/img/newvision/cars.png',
          },
          title: '购物车',
          path: '/cart',
          name: 'cart',
        },
        {
          icon: {
            active: '//cdn09.ehaier.com/shunguang/H5/www/img/newvision/Mine.png',
            inactive: '//cdn09.ehaier.com/shunguang/H5/www/img/newvision/Mines.png',
          },
          title: '我的',
          path: '/mine',
          name: 'mine',
        },
      ],
      tabBarsInternal: [
        {
          icon: {
            active: 'https://cdn09.ehaier.com/shunguang/H5/www/img/sgmobile/internalChannel/internal-home-icon-active.png',
            inactive: 'https://cdn09.ehaier.com/shunguang/H5/www/img/sgmobile/internalChannel/internal-home-icon.png',
          },
          title: '首页',
          path: '/internalChannel',
        },
        {
          icon: {
            active: 'https://cdn09.ehaier.com/shunguang/H5/www/img/sgmobile/internalChannel/internal-cart-icon-active.png',
            inactive: 'https://cdn09.ehaier.com/shunguang/H5/www/img/sgmobile/internalChannel/internal-cart-icon.png',
          },
          title: '购物车',
          path: '/cart2b',
        },
        {
          icon: {
            active: 'https://cdn09.ehaier.com/shunguang/H5/www/img/sgmobile/internalChannel/internal-mine-icon-active.png',
            inactive: 'https://cdn09.ehaier.com/shunguang/H5/www/img/sgmobile/internalChannel/internal-mine-icon.png',
          },
          title: '我的',
          path: '/internalWorkbench',
        },
      ]
    }
  },
  mounted() {
    //首页坑位id 用于提交订单时转换率统计
    if(utils.isUplus() || utils.isShopKeeper()){
      let key = utils.isUplus() ? 'zhijia_app_trace_id' : 'eshop_main_deal_traceid'
      utils.getAppStorageStringValue(key).then(res=>{
        sessionStorage.setItem('sg_order_adid', res)
      })
    } 
    // 网络状态监测
    utils.netWorkStatusHandler(() => {
      this.isNetWork = true
      location.reload()
    }, () => {
      this.isNetWork = false
    })
    // 活动浏览时长
    let query = qs.parse(location.search.substring(1))
    // 产业跳转m站记录来源标识
    if (query.traceSource) {
      this.$store.commit('setTraceSource', query.traceSource)
    }
    if (query.taskCode === 'TASK1042') {
      // 会员中心 分享任务 开启
      this.$store.commit('setIsShareTask', true)
      return
    }

    if (query.reviewTime > 0) {
      let param = {
        time: query.reviewTime,
        backUrl: query.reviewbackUrl || query.backUrl,
        userId: localStorage.getItem('ucId'),
        taskCode: query.taskCode,
        taskSource: query.taskSource
      }
      this.$store.dispatch('doReviewTime', param).then(res => {
        res && this.$reviewTimetoast({ type: 1 })
      })
    }

    //公钥私域加密
    thirdPartyLogic.checkRSAKey()

  },
  methods: {
    hideDownload() {
      this.hasdownload = false
    },
    close() {
      if (utils.isHaierApp()) {
        instance.initDeviceReady().then(() => {
          instance.closeH5ContainerView();
        }).catch(e => {
          console.log(e);
        });
      }
    },
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    appGoBack() {
      let backUrl = decodeURIComponent(this.$store.state.reviewTimeInfo.backUrl)
      console.log(backUrl)
      instance.initDeviceReady().then(() => {
        instance.upVdnModule.goToPage({ url: backUrl });
      }).catch(e => { console.log(e) });
    },
  },
  created() {

    if (utils.isLarkApp()) {
      if(location.pathname === '/sgmobile/internalChannel' || location.pathname === '/internalChannel'){
        this.isInternalChannel = true
        sessionStorage.setItem('origin_source', 'internal')
        console.log('origin_source seted =================>')
        // 内购环境进入项目前清空登录信息重新获取
        this.$store.commit('ihaier2Account/setIhaier2UserInfo', null)
        console.log('setIhaier2UserInfo cleared =================>')

        utils.loadScript('https://r.haier.net/assets/prod/dts-fe/fe-stub-usercenter/3.0.58/core.js').then(() => {
          console.log('usercenter js loaded =================>')
          
          this.$store.dispatch('ihaier2Account/getIhaier2UserInfo')
        })
      } else if(sessionStorage.getItem('origin_source') === 'internal') {
        this.isInternalChannel = true
      }
    } 
    // utils.loadScript('https://r.haier.net/assets/prod/dts-fe/fe-stub-usercenter/3.0.9/core.js').then(() => {
    //   this.$store.dispatch('ihaier2Account/getIhaier2UserInfo')
    // })

    // this.$store.dispatch('chat/initListener')
  },
  beforeDestroy() {
    this.$store.dispatch('chat/stopListener')
  },
  watch: {
    '$store.getters.getLoginStatus': {
      handler(nv) {
        // if (nv) {
        //   if (utils.isShopKeeper() || utils.isUplus()) this.$store.dispatch('chat/updateDeviceToCloud')
        // } else {
        //   this.$store.dispatch('chat/timLogout')
        // }
      },
      immediate: true
    }
  }
}
</script>
<style>
html{
  height: 100%;
  width: 100%;
}
body{
  overflow: hidden;
  background: #fff;
}

.van-toast--html, .van-toast--text{
  padding: .22rem .26rem !important;
  font-size: .3rem !important;
}
</style>
<style lang="scss" scoped>
// .status-bar-area{
//   height: env(safe-area-inset-top, 20px);
//   height: constant(safe-area-inset-top, 20px);
// }
.app {
  width: 100%;
  @include max-width;
  height: 100%;
  font-family: "PingFangSC", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 0.3rem;
  box-sizing: border-box;
  /* transition: all .1s linear; */
  background: #fff;
  -webkit-user-select: none;
}
.app.internal{
  height: calc(100% - constant(safe-area-inset-top, 20px));
  height: calc(100% - env(safe-area-inset-top, 20px));
  transform: translateY(constant(safe-area-inset-top, 20px));
  transform: translateY(env(safe-area-inset-top, 20px));
  /* padding-bottom: constant(safe-area-inset-top, 20px);
  padding-bottom: env(safe-area-inset-top, 20px); */
  // position: relative;
  // &::before {
  //   content: '';
  //   height: constant(safe-area-inset-top, 20px);
  //   height: env(safe-area-inset-top, 20px);
  //   top: 0;
  //   top: 0;
  //   margin-top: calc(0px - constant(safe-area-inset-top, 20px));
  //   margin-top: calc(0px - env(safe-area-inset-top, 20px));

  //   width: 100%;
  //   // position: absolute;
  //   background-color: red;
  // }
}
.app-warp{
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: #f2f2f2;
  box-sizing: border-box;
}
.app-warp::-webkit-scrollbar{
  display: none;
  }

.has-tabbar{
  padding-bottom: .92rem;
}
.has-header{
  padding-top: .92rem;
}

.has-download{
  height: calc(100% - .8rem);
  transform: translateY(.8rem);
}

.back-arrow{
  position: fixed;
  top: 2.8rem;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .24rem;
  width: 1.28rem;
  height: .52rem;
  color: #fff;
  background: rgba(#000000, .5);
  z-index: 1000;
  border: 1px solid #fff;
  border-left: 0;
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem;
  img{
    display: inline-block;
    width: .25rem;
    height: .28rem;
  }
}

.clock-icon{
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 2.4rem;
  right: .26rem;
  width: .66rem;
  padding-top: .16rem;
  height: .82rem;
  z-index: 1000;
  background: url(https://cdn09.ehaier.com/shunguang/H5/www/img/sgmobile/home/clock.png) no-repeat;
  background-size: cover;
  font-size: .26rem;
  color: #794B00;
  box-sizing: border-box;
}

.network-mask{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 10;
  .close-network{
    margin-top: .4rem;
    display: inline-flex;
    // padding: 0 .8rem;
    height: 28px;
    width: 80px;
    justify-content: center;
    align-items: center;
    font-size: .28rem;
    color: #fff;
    text-decoration: none;
    background: $themeSynMain;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #2283E2;
    text-align: center;
    font-weight: 400;
    background: rgba(34,131,226,0.10);
    border-radius: 14px;
  }
  img{
    width: 40px;
  }
  p{
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #666666;
    letter-spacing: 0;
    line-height: 20px;
    font-weight: 400;
    margin-top: 12px;
  }
}
:deep(.van-list__finished-text) {
    font-size: 0.24rem;
    background: #f5f5f5;
    color: #b8b8b8;
    height: 0.88rem;
    line-height: 0.88rem;
}
</style>
