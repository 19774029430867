export default {
  namespaced: true,
  state: {
    params: {},
    defaultImg: '', //默认图片
    priceRange: [], //价格范围
    realPriceList: [], //实际价格列表
    attrLists: [],
    selectAttrs: [],
    selectAttrsIds: [],
    allSkuProductsLists: [],
    userSelectProdInfo: null,
  },
  mutations: {
    setParams(state, params){
      state.params = params
    },
    setDefaultInfo(state, {range, img, realPriceList}){
      state.priceRange = range
      state.defaultImg = img
      state.realPriceList = realPriceList || []
    },
    //设置初始值
    setSelectAttrInit(state, info){
      console.log('setSelectAttrInit===>',info)
      state.userSelectProdInfo = info
      state.selectAttrs = info.selectAttrs
      state.attrLists = state.attrLists.map(item => {
        item.forEach(itm => {
          if(info?.attrValueIds?.split(',').includes(String(itm?.sgAttribute?.id))){
            itm.sgAttribute.selected = true
          }            
        })
        return item
      })
    },
    // 所有商品
    setAllSkuProducts(state, lists){
      state.allSkuProductsLists = lists
    },
    //属性
    setSgAttribute(state, lists){
      lists.forEach(item => {
        item.forEach(itm => {
          itm.sgAttribute.selected = false
          itm.sgAttribute.disabled = false
        })
      });
      state.attrLists = lists
    },
    //reset
    resetSelect(state){
      state.userSelectProdInfo = null
      state.selectAttrs = []
      state.selectAttrsIds = []
      state.attrLists.forEach(item => {
        item.forEach(itm => {
          itm.sgAttribute.selected = false
        })
      })
    },
    //重置选择
    resetItemSelect(state, { index }){
      state.attrLists[index].forEach(item => {
        item.sgAttribute.selected = false
      })
    },
    //用户选择
    setUserSelect(state, {index, attrId, attrName}){
      console.log('setUserSelect==>',index, attrId, attrName)
      let tmpState = [...state.selectAttrs]
      tmpState[index] = {id: attrId, name: attrName}
      state.selectAttrs = tmpState
      state.selectAttrsIds = tmpState.map(item => item?.id)
      state.userSelectProdInfo = null
    },
    // 用户选择的商品
    setUserSelectProdInfo(state){
      let info = null
      let tmp = state.selectAttrsIds.sort((a,b)=>a-b)
      state.allSkuProductsLists.forEach(item => {
        let itemArr = item.attrValueIds.split(',').sort((a,b)=>a-b)
        if(itemArr.join(',') === tmp.join(',')){
          info = item
        }
      })
      if(info){
        state.realPriceList.forEach(item => {
          if(item.skku === info.skku){
            info.price = item.price
          }
        })
        state.userSelectProdInfo = {
          ...info,
          selectAttrs: state.selectAttrs
        }
      }
    }
  }
}