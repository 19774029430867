const smartScene = [
  {
    path: '/smartSceneList',
    name: 'smartSceneList',
    component: () => import('@/viewsToB/smartScene/list.vue'),
    meta: {
      title: '三库',
      showHeader: false,
    }
  },
  {
    path: '/smartSceneDetail',
    name: 'smartSceneDetail',
    component: () => import('@/viewsToB/smartScene/detail.vue'),
    meta: {
      title: '三库',
      showHeader: false,
    }
  },
  {
    path: '/smartSceneSearch',
    name: 'smartSceneSearch',
    component: () => import('@/viewsToB/smartScene/search.vue'),
    meta: {
      title: '三库',
      showHeader: false,
    }
  }
]

export default smartScene