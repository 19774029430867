import { sceneChangePirce, getQueryByRtSeq, quickPlaceOrderStoreProduct, queryOrderRightAndCouponList, qutoationCouponDetail } from '@/http/service/shop'
import { getActivityInfoByScene } from '@/http/service/goods'
import { getOrderPaySourceIdByEnv } from '@/views/order/cashier/common/orderSource'

export default {
  namespaced: true,
  state: {
    curIdCanChange: false, // 当前身份能否改价
    curSenceItem: {}, //当前场景选择的 列表item 信息
    curSenceProd: null, // 当前场景信息
    curPageKey: '', //当前页面pageKey 控制按钮展示用
    curMdCode: '', //当前mdCode
    curMdInfo: {},
    curSeqInfo: {}, //当前报价单所有信息
    curMyOrderSeq: '', //当前我的报价单流水号
    myList: [], //我的报价单列表

    couponDetail: null, //优惠信息
    couponSlide: false, //优惠信息展开状态
    couponLoading: false,

    skkuCallBack: null, //多规格首次加购回调

    isModify: false, //是否为修改报价单
    shareInitInfo: null, //修改报价单分享相关初始化信息

    lastSkkuInfo: null,//最近一次选中的多规格信息
    skkuNumMap: {}, //子规格数量

    isModifyNums: false, //是否为修改数量

    //门店关联的流水号
    mdMapSeq: {}, 
    // 当前利润中心编码
    curSenceProfitCenterCode: '', 

    //按钮状态
    btnStatus: {
      cartFixedBtn: true, //购物袋按钮
      changePriceBtn: true, //改价按钮
      clearBtn: true, //清空按钮
      shareBtn: true, //分享按钮
      saveBtn: true, // 保存方案按钮
      submitBtn: true, //去结算
      myListBtn: true, //我的方案按钮
      addLinkBtn: true, //选择方案加号按钮
    },
    //用户是否保存过方案存fks上报埋点用
    isSaveQuation: '',

    sharePage: '', //当前拉起分享的页面 埋点用

    //是否要修改编辑方案名
    canEditSceneName: true,
    
    //专属价方案生成的场景码，埋点用
    gioMdInfo: {
      fk2_code: '',
      sourceType: '',
    }
  },
  getters: {
    getBtnStatus(state){
      return state.btnStatus
    },
    getCurSenceProd(state){
      return state.curSenceProd
    },
    getMyListNum(state){
      let count = 0;
      state.myList.forEach(item => {
        if(item.skku){
          count += Number(item.skkuNum[item.skku])
        }else{
          count += Number(item.num)
        }
      });
      return count
    },
    getMyListPrice(state){
      let count = 0;
      state.myList.forEach(item => {
        if(item.skku){
          count += item.finalPrice * item.skkuNum[item.skku] 
        }else{
          count += item.finalPrice * item.num 
        }
      });
      return count.toFixed(2)
    }
  },  
  mutations: {
    setCurPageKey(state, pageKey){
      state.curPageKey = pageKey
    },
    setCouponDetail(state, info) {
      state.couponDetail = info
    },
    setCouponSlide(state, flag) {
      state.couponSlide = flag
    },
    setCouponLoading(state, flag){
      state.couponLoading = flag
    },
    setCurMdCode(state, mdCode){
      state.curMdCode = mdCode
    },
    setCurIdCanChange(state, flag){
      state.curIdCanChange = flag
    },
    setCanEditSceneName(state, flag){
      state.canEditSceneName = flag
    },
    setSkkuCallBack(state, fn){
      state.skkuCallBack = (item) =>{
        fn(item)
        state.skkuCallBack = null
      }
    },
    setModifyStatus(state, flag){
      state.isModify = flag
    },
    setModifyNums(state, flag){
      state.isModifyNums = flag
    },
    setCurSeqInfo(state, info){
      state.curSeqInfo = info
    },
    setLastSkkuInfo(state, {sku, info}){
      state.lastSkkuInfo = {
        ...state.lastSkkuInfo,
        [sku]: info
      }
    },
    setSkkuNumMap(state, {skku, num}){
      state.skkuNumMap = {
        ...state.skkuNumMap,
        [skku]: num
      }
    },
    setShareInitInfo(state, info){
      state.shareInitInfo = info
    },
    //设置当前场景编码
    setCurSenceProfitCenterCode(state, code){
      state.curSenceProfitCenterCode = code
    },
    //设置门店关联的流水号，切换店铺用
    setMdMapSeq(state, { mId, seq }){
      // alert(mdCode)
      state.mdMapSeq[mId] = seq
      // localStorage.setItem('quick_order_mdmapseq', JSON.stringify(state.mdMapSeq))
    },
    gioMdInfo(state, {key,code}){
      state.gioMdInfo[key] = code
    },
    setIsSaveQuation(state, flag){
      state.isSaveQuation = flag
    },
    setSharePage(state, page) {
      state.sharePage = page
    },
    setInitBtnStatus(state){
      state.btnStatus = {
        cartFixedBtn: true, //购物袋按钮
        changePriceBtn: true, //改价按钮
        clearBtn: true, //清空按钮
        shareBtn: true, //分享按钮
        saveBtn: true, // 保存方案按钮
        submitBtn: true, //去结算
        myListBtn: true, //我的方案按钮
        addLinkBtn: true, //选择方案加号按钮
      }
    },
    setBtnStatus(state, {key, status}){
      if(key instanceof Array){
        key.forEach(k => {
          state.btnStatus[k] = status
        })
      }else{
        state.btnStatus[key] = status
      }
    },
    setCurSenceItem(state, item){
      state.curSenceItem = item
      // localStorage.setItem('quick_order_curSenceItem', JSON.stringify(item))
    },
    setCurSenceProd(state, info){
      state.curSenceProd = info
      // localStorage.setItem('quick_order_curSence', JSON.stringify(info))
    },
    setCurMdInfo(state, info){
      state.curMdInfo = info
      // localStorage.setItem('quick_order_mdInfo', JSON.stringify(info))
    },
    setCurMyOrderSeq(state, seq){
      state.curMyOrderSeq = seq
      // localStorage.setItem('quick_order_seq', seq)
    },
    setMyList(state, arr){
      state.myList = arr
      // localStorage.setItem('quick_order_mylist', JSON.stringify(state.myList))
    },

    clearMyList(state){
      state.myList = []
      state.skkuNumMap = {}
    },
    updateMyList(state, {type, item}){
      let index = 0
      let indexItem = null
      if(item.skku){
        index = state.myList.findIndex(li => li.skku === item.skku)
        indexItem = state.myList.find(li => li.skku === item.skku)
      }else{
        index = state.myList.findIndex(li => li.sku === item.sku)
        indexItem = state.myList.find(li => li.sku === item.sku)
      }

      console.log('type==>',type, item, index)
      // debugger
      let newItem = {
        ...item,
        productId: item.productId,
        sku: item.sku,
        skku: item.skku,
        skkuNum: item.skkuNum,
        skkuName: item.skkuName,
        subSkuText: item.subSkuText,
        num: item.num,
        platTagIds: item.platTagIds,
        profitCenterCode: item.profitCenterCode,
        pic: item.pic,
        price: item.price,
        subSkuText: item.subSkuText,
        defaultImageUrl: item.defaultImageUrl,
        onSale: item.onSale,
        settled: item.settled,
        hasStock: item.hasStock,
        productFullName: item.productFullName,
        finalPrice: indexItem?.finalPrice || item.finalPrice,
        changePrice: undefined, //改价
        sellPoints: item.sellPoints,
        marketingTagList: item.marketingTagList,
        optinalRightsFlag: item.optinalRightsFlag, //前端添加
        orderRightType: item.orderRightType,
        coupon: item.coupon,
        giftCoupon: item.giftCoupon,
        isBakBtn: item.isBakBtn,
        subInfo: item.subInfo,
        qzbp: item.qzbp, //前置
        fwfeibiao: item.fwfeibiao,
        inputCount: item.inputCount,
        freeInterest: item.freeInterest,
        installmentType: item.installmentType,
      }
      switch (type) {
        case 'add':
          if(index !== -1){
            state.myList.splice(index, 1, newItem)
          }else{
            state.myList.push(newItem)
          }
          break;
      
        case 'del':
          if(index !== -1){
            // debugger
            if(state.myList[index].skkuNum[item.skku]<=0){
              state.myList.splice(index, 1)
            }else if(!item.skku && state.myList[index].num <=0){
              state.myList.splice(index, 1)
            }else{
              state.myList.splice(index, 1, newItem)
            }
          }
          break;
      }
      //同步每一个状态
      if(newItem.skku){
        state.skkuNumMap = {
          ...state.skkuNumMap,
          [newItem.skku]: newItem.skkuNum[newItem.skku]
        }
      }
      state.myList.forEach(itm => {
        itm.skkuNum = state.skkuNumMap
      })
      
      
      if(state.lastSkkuInfo && item.skku === state.lastSkkuInfo[item.sku]?.skku){
        state.lastSkkuInfo = {
          ...state.lastSkkuInfo,
          [item.sku]: {
            skku: item.skku,
            pic: item.pic,
            finalPrice: item.finalPrice,
            subSkuText: item.subSkuText,
            subInfo: item.subInfo,
          }
        }
      }
      
      // localStorage.setItem('quick_order_mylist', JSON.stringify(state.myList))
    },
    // addMyList(state, item){
    //   let index = state.myList.findIndex(li => li.sku === item.sku)
    //   if(index !== -1){
    //     state.myList.splice(index, 1, item)
    //   }else{
    //     state.myList.push(item)
    //   }
    //   localStorage.setItem('quick_order_mylist', JSON.stringify(state.myList))
    // },
    // delMyList(state, item){
    //   let index = state.myList.findIndex(li => li.sku === item.sku)
    //   if(index !== -1){
    //     if(!state.myList[index].num){
    //       state.myList.splice(index, 1)
    //     }
    //     state.myList.splice(index, 1, item)
    //   }
    //   localStorage.setItem('quick_order_mylist', JSON.stringify(state.myList))
    // }
  },
  actions: {
    //优惠信息
    async fetchCouponDetail({state, commit}, curMyOrderSeq){
      //fetch
      if(curMyOrderSeq || state.curMyOrderSeq){
        commit('setCouponLoading', true)
        let { result, success, message } = await qutoationCouponDetail({
          quotationSeq: curMyOrderSeq || state.curMyOrderSeq,
          sourceType: sessionStorage.getItem('sc_source_type') || null,
          scene: 'CJQSJ'
        })
        console.log('fetchCouponDetail==>', success, result)
        if(result && result.discountDetailList){
          result.discountDetailList.forEach(item =>{
            item.subTotalDiscountAmount = 0
            item.isSlide = false
            item.detailList.forEach(subItem =>{
              item.subTotalDiscountAmount += subItem.discountAmount
            })
            item.subTotalDiscountAmount = item.subTotalDiscountAmount.toFixed(2)
          })
        }
        commit('setCouponDetail', result)
        commit('setCouponLoading', false)
        return {status: success, message}
      }
    },

    // 获取用户已添加购物清单列表
    async getUserMyList({commit, state, dispatch}, {seq}){
      // if(seq){ 
      //   commit('setMdMapSeq', { mId, seq }) 
      // }
      // let curMdSeq = state.mdMapSeq[mId]
      commit('setCurMyOrderSeq', seq || '')
      if(state.curMyOrderSeq){
        commit('setMyList',[])
        let myListRes = await await getQueryByRtSeq({
          quotationSeq: state.curMyOrderSeq,
          // excludeMarketing: true
        })
        let { success, data, errMessage } = myListRes
        if(success){
          if(!data){ //报价单过期，清空
            commit('setCurMdCode', data?.mdCode)
            commit('setCurMyOrderSeq','')
            commit('setMyList',[])
            commit('setCurSeqInfo', {})
            return {status: false, message: errMessage}
          }
          let { products } = data
          //初始化 skkuNumMap
          let skkuNumMap = {}
          products.forEach(item => {
            item.qzbp = item?.platTagIds?.some(i => i == 7) //前置
            item.fwfeibiao = item?.platTagIds?.some(i => i == 8) //服务非标
            item.inputCount = !item?.platTagIds?.some(i => i == 10) //输入数量
            if(item.skku){
              skkuNumMap[item.skku] = item.num
            }
          })
          state.skkuNumMap = {
            ...state.skkuNumMap,
            ...skkuNumMap,
          }
          products.forEach(item => {
            item.pic = item.defaultImageUrl
            item.skkuNum = skkuNumMap
            item.subSkuText = item.skkuName || '',
            item.subInfo = null
          })
          let lists = products.map(item => ({
            ...item,
            optinalRightsFlag: false,
            orderRightType: '',
            coupon: '',
            giftCoupon: '',
            freeInterest: '',
            installmentType: '',
          }))
          console.log('lists===>',lists)
          commit('setCurMdCode', data?.mdCode)
          commit('setMyList',lists)
          commit('setCurSeqInfo', data)
          dispatch('fetchRightsTag')
          dispatch('fetchStageScene')
          if(state.isModify){
            commit('setShareInitInfo', {
              fname: data?.quotationName || '',
              sails: data?.saler || '',
              mainImg: data?.quotationMasterImg || 'https://cdn092.ehaier.com/shunguang/H5/www/img/sgmobile/scene/scence_pic.jpeg',
              startTime: data?.quotationStartDate || '',
              endTime: data?.quotationEndDate || ''
            })
          }

          return {status: true, message: ''}
        }
      }else{
        commit('setMyList',[])
        return {status: true, message: ''}
      }
    },


    async changePrice({commit, state, dispatch}){
      let arr = [], numFlag = false
      state.myList.forEach(item => {
        if(item.changePrice){
          if(isNaN(Number(item.changePrice))){
            numFlag = true
          }else{
            arr.push({
              storeId: state.curMdInfo.storeId,
              mdCode: state.curMdInfo.mdCode,
              productId: item.productId,
              sku: item.sku,
              skku: item.skku || null,
              price: item.changePrice,
              productFullName: item.productFullName,
              quotationSeq: state.curMyOrderSeq,
            })
          }
        }
      })
      if(numFlag) return { success: false, message: '价格设置不正确'}
      if(!arr.length) return {success: false, message: '您未改价'}
      let result = await sceneChangePirce({
        productList: arr
      })
      if(result.success && result.data){
        //获取报价单列表
        let myListRes =  await getQueryByRtSeq({
          quotationSeq: state.curMyOrderSeq
        })
        let { products } = myListRes.data
        if(products && products.length){
          //初始化 skkuNumMap
          let skkuNumMap = {}
          products.forEach(item => {
            item.qzbp = item?.platTagIds?.some(i => i == 7) //前置
            item.fwfeibiao = item?.platTagIds?.some(i => i == 8) //服务非标
            item.inputCount = !item?.platTagIds?.some(i => i == 10) //输入数量
            if(item.skku){
              skkuNumMap[item.skku] = item.num
            }
          })
          state.skkuNumMap = {
            ...state.skkuNumMap,
            ...skkuNumMap,
          }
          products.forEach(item => {
            item.pic = item.defaultImageUrl
            item.price = item.finalPrice
            item.skkuNum = skkuNumMap
            item.subSkuText = item.skkuName || '',
            item.subInfo = {}
          })
          console.log('products==>',products)
          let lists = products.map(item => ({
            ...item,
            optinalRightsFlag: false,
            orderRightType: '',
            coupon: '',
            giftCoupon: '',
            freeInterest: '',
            installmentType: '',
          }))
          commit('setMyList', lists)
          dispatch('fetchRightsTag')
          dispatch('fetchStageScene')
          dispatch('fetchCouponDetail')
        }
        return {
          success: true
        }
      }else{
        return {
          success: false,
          message: result.message
        }
      }
    },

    //获取分期信息
    async fetchStageScene({state}, lists){
      if(!lists){
        lists = state.myList
      }
      return new Promise(async (reslove,reject)=>{
        if(!lists.length) return reslove(lists)
        let param = {
          paySourceId: getOrderPaySourceIdByEnv(),
          sourceType: sessionStorage.getItem('sc_source_type') || 'CJQSJ',
          installmentType: 'ali_huabei',
          productList: [],
        }
        lists.forEach(i=>{
          param.productList.push({
            sku: i.sku,
            mdCode: i.mdCode,
            isScene: true, //!!i.relation,
          })
        })
        console.log('res=======sresdas.',param)
        try {
          const res = await getActivityInfoByScene(param)
          console.log('res=======sresdas.',res)
          if(res.success) {
            lists.forEach(i=>{
              res.data?.forEach(r=>{
                if(i.sku === r.sku){
                  i.freeInterest = r.freeInterest.length ? r.freeInterest.join('/') : ''
                  i.installmentType = r.installmentType || ''
                }
              })
            })
          }
          return reslove(lists)
        }catch (error) {
          console.log('error1=>',error)
          reject()
        }
      })
    },
    //权益及优惠券等相关信息
    async fetchRightsTag({commit, state, rootGetters}, lists){
      if(!lists){
        lists = state.myList
      }
      return new Promise(async (reslove,reject)=>{
        if(!lists.length) return reslove(lists)
        let param = {
          productParamList:[],
          accessToken: rootGetters.mId || '',
          // sourceType: sessionStorage.getItem('sc_source_type') || null
        }
        lists.forEach(i=>{
          param.productParamList.push({
            storeCode: i.storeCode || null,
            mdCode: i.mdCode || null,
            sku: i.sku,
            price: i.finalPrice
          })
        })
        try {
          const res = await queryOrderRightAndCouponList(param)
          if(res.success) {
            lists.forEach(i=>{
              res.data?.forEach(r=>{
                if(i.sku === r.sku){
                  i.optinalRightsFlag = r.optinalRightsFlag
                  i.orderRightType = r.orderRightType
                  i.coupon = r.coupon
                  i.giftCoupon = r.giftCoupon
                }
              })
            })
          }
          return reslove(lists)
        }catch (error) {
          console.log('error1=>',error)
          reject()
        }
      })
    },


    // 获取列表数据
    async fetchListData({state, dispatch}, payload){
      const { data = [] } = await quickPlaceOrderStoreProduct(payload)
      if(!data.length) return data
      data.forEach(item=>{
        item.qzbp = item.platTagIds.some(i => i == 7) //前置
        item.fwfeibiao = item.platTagIds.some(i => i == 8) //服务非标
        item.inputCount = !item.platTagIds.some(i => i == 10) //输入数量
        item.pic = '' //子商品sku
        item.skku = '' //子商品sku
        item.subSkuText = '' //选择的子规格
        item.skkuNum = {} //不同子规格数量
        item.subInfo = null //子规格所有信息
        item.num = 0
        item.optinalRightsFlag = false
        item.orderRightType = ''
        item.coupon = ''
        item.giftCoupon = ''
        item.freeInterest = ''
        item.installmentType = ''
        state.myList.forEach(it => {
          if(it.sku === item.sku && it.skku){
            console.log('fetchListData aaaa==>',it.price)
            item.skku = it.skku
            item.price = item.skkuPriceVos.find(i => i.skku === it.skku).price // it.price
            item.finalPrice = item.price
            item.pic = it.defaultImageUrl
            item.subSkuText = it.subSkuText
            item.subInfo = null
          }
        })
      })
      console.log('fetchListData aaaaaaaaaaaaaaaa==>',data)
      dispatch('fetchRightsTag', data)
      dispatch('fetchStageScene', data)
      return data
    }
  }
}