// 组件传参共享
export default {
  namespaced: true,
  state: {
    settingDefaultAddressId: '', // 新建收货地址时，设置的全局默认地址信息
    settingAddressListNewAddBg: '', // 收货地址列表默认选中背景地址
    shareStoreInfo: {}, //分享时店铺信息
    shareWxTMImgBase64: '', //分享时微信小程序码base64
  },
  mutations: {
    setSettingDefaultAddress(state, id){
      state.settingDefaultAddressId = id
    },
    setAddressListNewAddBg(state, id){
      state.settingAddressListNewAddBg = id
    },
    setShareStoreInfo(state, info) {
      state.shareStoreInfo = info
    },
    setShareWxTMImgBase64(state, base64){
      state.shareWxTMImgBase64 = base64
    }
  },
}
