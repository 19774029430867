// 区分各种用户代理和从哪种来源打开
const ua = navigator.userAgent

// 自动判断环境方案文档地址：https://ihaier.feishu.cn/docx/L3GNd23xpoR5Y1xzVA7cXoYwnbh
const WXID = {
  scMpPeWechat:'wx1d6feb8b7a528465', // 私域小程序
  synMpWechat:'wx7699f2c712c4723c', // 三翼鸟小程序
  zjMpWechat: 'wxe24b2f1f4e378891', // 智家小程序
}

export default {
  runEnv() {
    return ua;
  },
  getWechatMPId() {
    const ua = this.runEnv()
    const reg = new RegExp(/miniProgram\/(wx[a-z0-9]{16})/);
    const wxid = ua.match(reg) ? ua.match(reg)[1] : false;
    return wxid
  },
  isIos() {
    return ua.indexOf('iPhone') > -1
  },
  isAndroid() {
    return ua.indexOf('Android') > -1
  },
  isMobile() {
    const ua = this.runEnv()
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua)
  },
  /** 是否公域 */
  isPublicClient() {
    return !this.isShopKeeper() && !this.isInternalChannel() && sessionStorage.getItem('open_source') !== 'pc-store-center'
  },
  /**
   * 智家、三翼鸟、新移动工作台
   */
  isHaierApp() {
    return this.isShopKeeper() || this.isUplus() || this.isSyBird()
  },
  /**
   * 爱创客app
   */
  isIMaker() {
    return ua.indexOf('iMaker') > -1
  },
  /**
   * 智家app
   */
  isUplus() {
    return ua.indexOf('App/Uplus') > -1
  },
  /**
   * 鸿蒙
   */
  isHarmony(){
    return ua.indexOf('Harmony') > -1
  },
  /**
   * 三翼鸟app
   */
  isSyBird() {
    return ua.indexOf('App/SyBird') > -1
  },
  /**
   * @returns 是否为新移动工作台
   */
  isShopKeeper() {
    return ua.indexOf('App/Shopkeeper') > -1
  },
  isSyBirdH5() {
    return (sessionStorage.getItem('open_source') === 'haier-sybird-h5' || sessionStorage.getItem('open_source') === 'haier-app-sybird-shared-h5')
  },
  isSyBirdSharedH5() {
    return sessionStorage.getItem('open_source') === 'haier-app-sybird-shared-h5'
  },
  isWeixin() {
    return /MicroMessenger/i.test(ua.toLowerCase())
  },
  isAlipay() {
    return /AlipayClient/i.test(ua.toLowerCase())
  },
  /**
   * iHaier 1.0
   */
  isIhaierApp() {
    return /Qing\/.*;(iOS|iPhone|Android).*/.test(ua)
  },
  /**
   * @description 是否内购频道 ：ihaier2.0且通过内购首页进入
  */
  isInternalChannel() {
    // return true
    return sessionStorage.getItem('origin_source') === 'internal' && this.isLarkApp()
  },
  /**
   * @description 是否ihaier2.0
  */
  isLarkApp() {
    // return true
    return ua.indexOf('Lark') > -1
  },
  /**
   * 智家掌柜app
   */
  isSgRn() {
    return ua.indexOf('ShunGuangRN') > -1
  },
  /**
   * 三翼鸟小程序, open_source 见《第三方对接文档》
   * @returns
   */
  isSyBirdMP() {
    return this.isSyBirdWeChatMP() || this.isSyBirdBaiduMP()
  },
  /**
   * 三翼鸟微信小程序, open_source 见《第三方对接文档》
   * @returns
   */
  isSyBirdWeChatMP() {
    return sessionStorage.getItem('open_source') === 'synmp' || sessionStorage.getItem('open_source') === 'haier-mp-sybird' || WXID.synMpWechat === this.getWechatMPId()
  },
  /**
   * 百度小程序 （用于区分小程序环境，不区分哪款小程序）
   * @returns
   */
  isMpBaidu() {
    return this.isSyBirdBaiduMP()
  },
  /**
   * 三翼鸟百度小程序, open_source 见《第三方对接文档》
   * @returns
   */
  isSyBirdBaiduMP() {
    return sessionStorage.getItem('open_source') === 'haier-mp-baidu-sybird'
  },
  /**
   * @returns 商城微信小程序
   */
  isScMpWechat() {
    return sessionStorage.getItem('open_source') === 'sc-mp-wx'
  },
  /**
   * @returns 商城微信私域小程序
   */
  isScMpPeWechat() {
    return sessionStorage.getItem('open_source') === 'sc-mp-wx-private' || WXID.scMpPeWechat === this.getWechatMPId()
  },
  /**
   * @returns 商城阿里小程序
   */
  isScMpAli() {
    return sessionStorage.getItem('open_source') === 'sc-mp-ali'
  },
  /**
   * @returns 商城字节小程序
   */
  isScMpByte() {
    return sessionStorage.getItem('open_source') === 'sc-mp-byte'
  },
  /**
   *
   * @returns 商城小程序 （含 微信 阿里 字节）
   */
  isScMp() {
    return sessionStorage.getItem('open_source') && sessionStorage.getItem('open_source').startsWith('sc-mp')
  },
  /**
 * @returns 智家App微信小程序
 */
  isZhiJiaMP() {
    return sessionStorage.getItem('open_source') === 'haier-mp-zhijia' || WXID.zjMpWechat === this.getWechatMPId()
  },
  /**
   *
   * @returns 三翼鸟大屏
   */
  isSYNDP() {
    return sessionStorage.getItem('open_source') && sessionStorage.getItem('open_source').toLowerCase() === 'haier-sybird-dp'
  },
  /**
   *
   * @returns 空调大屏
   */
  isKTDP() {
    return ua.indexOf('KongTiao_TFT') > -1
  },
  /**
   *
   * @returns 三翼鸟或移动工作台
   */
  isSyBirdOrShopKeeper() {
    return this.isSyBird() || this.isShopKeeper()
  },
  /**
   *
   * @returns 易理货app（安卓：jsh/android/1.0 IOS：jsh/ios/1.0）
   */
  isYLH() {
    return ua.indexOf('jsh') > -1
  },
}
