import { removeCartCItem, updateCartCItem, removeCartBatch } from '@/http/service/cartc.js'

export default {
  namespaced: true,
  state: {
    goodsList: []
  },
  mutations: {
    setGoodsList(state, payload) {
      state.goodsList = payload || []
    },
    setItemDelete(state, payload) {
      state.goodsList[payload.index].productList.splice(payload.idx, 1)
    },
    setItemCartNum(state, payload){
      state.goodsList[payload.index].productList[payload.idx].cartNum = payload.cartNum
    },

    setDeleteGoodsMuti(state, payload) {
      console.log('setDeleteGoodsMuti==>',payload)
      payload.forEach(item => {
        state.goodsList.forEach((element, index) => {
          element.productList.forEach((element2, index2) => {
            if ((element2.skku === item.skku || element2.sku === item.sku) && element2.mdCode === item.mdCode) {
              element.productList.splice(index2, 1)
            }
          })
        })
      })
      console.log('state.goodsList==>',state.goodsList)
    }
  },
  actions: {
    // 删除
    async deleteItemGoods({ commit, dispatch }, {prod, index, idx}) {
      await removeCartCItem({
        sku: prod.sku,
        skku: prod.skku,
        number: prod.cartNum,
        mdCode: prod.mdCode
      })
      commit('setItemDelete', {index, idx})
      dispatch('cart/getCartNumber', null, { root: true })
    },
    async deleteGoodsMuti({ commit, dispatch }, payload) {
      await removeCartBatch({
        vos: payload.map(item => ({
          sku: item.sku,
          skku: item.skku,
          mdCode: item.mdCode
        }))
      })
      commit('setDeleteGoodsMuti', payload)
      dispatch('cart/getCartNumber', null, { root: true })
    },
    async updateCartItemNum({ commit, dispatch }, payload){
      if(payload.cartNum > 0){
        const result = await updateCartCItem({
          number: payload.cartNum - payload.initNum,
          sku: payload.sku,
          skku: payload.skku,
          mdCode: payload.mdCode,
        })
        console.log('result==>',result)
        if(result.success){
          payload.initNum = payload.cartNum
          dispatch('cart/getCartNumber', null, { root: true })
        }
        return result
      }else{
        return null
      }
    }
  }
}