/***
 * 采购专区 store
 */
import { Toast } from 'vant';
import { ERROR_TOAST } from '@/config/constKeys'

import {
  procurementCartUpdate,
  procurementCartAdd,
  procurementCartRemove,
  procurementCartBatchRemove,
  procurementCartList,
  procurementPolicyList,
  procurementPolicyIsSigned,
} from '@/http/service/procurement.js'

export default {
  namespaced: true,
  state: {
    isSignedAgreement: false, // 是否签订过协议
    goodsList: [], // 购物车商品列表
    skkuCallBack: null, //多规格首次加购回调
    signTimes: 0,
    hasCheckedOut: false, // 是否去结算过
    isModifyNums: false, //是否为修改数量
  },
  getters: {
    totalPrice: state => {
      let _totalPrice = 0
      state.goodsList.forEach(item => {
        _totalPrice += item.price * item.cartNum
      })
      return _totalPrice
    },
    cartNums: state => {
      let _cartNums = 0
      state.goodsList.forEach(item => {
        _cartNums += item.cartNum ?? 0
      })
      return _cartNums
    },
    goodsList: state => {
      return state.goodsList
    },
  },
  mutations: {
    setIsSignedAgreement(state, isSignedAgreement) {
      state.isSignedAgreement = isSignedAgreement
    },
    setHasCheckedOut(state, hasCheckedOut) {
      state.hasCheckedOut = hasCheckedOut
    },
    setSkkuCallBack(state, fn) {
      state.skkuCallBack = (item) => {
        fn(item)
        state.skkuCallBack = null
      }
    },
    setLastSkkuInfo(state, {sku, info}){
      state.lastSkkuInfo = {
        ...state.lastSkkuInfo,
        [sku]: info
      }
    },
    setGoodsList(state, list) {
      state.goodsList = list;
    },
    removeGoodsItemIndex(state, index) {
      state.goodsList.splice(index, 1);
    },
    updateGoodsItemNumber(state, { index, number }) {
      state.goodsList = state.goodsList.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            cartNum: number
          }
        }
        return item
      })

      console.log('updateGoodsItemNumber==>', state.goodsList[index]);
    },
    signTimesChanged(state) {
      /// 1000 是个随机数，防止 signTimes 溢出
      state.signTimes = (state.signTimes + 1 ) % 1000;
    },
    setModifyNums(state, isModifyNums) {
      state.isModifyNums = isModifyNums
    }
  },
  actions: {
    /**
     * @description 加入购物车，新增的商品
     */
    async addNewGoods({ dispatch, state }, goodsItem) {
      let params = {
        sku: goodsItem.sku,
        skku: goodsItem.skku ?? '',
        mdCode: goodsItem.mdCode,
        number: 1, // 加入购物车就默认为 1
      };

      try {
        const res = await procurementCartAdd(params);
        if (res.data.success) {
          Toast('加入购物车成功');
          dispatch('getCartList')
          return res;
        } else {
          Toast(res.data.errMessage ?? ERROR_TOAST);
        }
      } catch (error) {
        console.log('addNewGoods', error);
      }
      return {};
    },

    /**
   * @description 加数量
   */
    async addGoodsCount({ dispatch, state }, goodsItem) {
      console.log('addGoodsCount', goodsItem);
      try {
        return await dispatch('updateCart', {
          ...goodsItem,
          number: goodsItem.num + 1,
        });
      } catch (error) {
        console.log(error);
      }

      return {};
    },

    /**
   * @description 减数量
   */
    async subGoodsCount({ dispatch, state }, goodsItem) {
      if (goodsItem.number > 1) {
        return await dispatch('updateCart', {
          ...goodsItem,
          number: goodsItem.num - 1,
        });
      } else {
        Toast('数量不能少于1');
      }
      return {};
    },

    /**
   * @description 单个更新购物车数量
   */
    async updateCart({ dispatch, commit, state }, goodsItem) {
      try {
        const res = await procurementCartUpdate({
          sku: goodsItem.sku,
          skku: goodsItem.skku ?? '',
          mdCode: goodsItem.mdCode,
          number: goodsItem.number,
        });
        if (res.data.success) {
          const index = state.goodsList.findIndex(item => {
            if (item.skku) {
              return item.skku === goodsItem.skku && item.sku === goodsItem.sku && item.mdCode === goodsItem.mdCode
            }
            return item.sku === goodsItem.sku && item.mdCode === goodsItem.mdCode
          })
          if (index > -1) {
            commit('updateGoodsItemNumber', { index: index, number: goodsItem.number })
          }
          return res;
        } else {
          Toast(res.data?.errMessage ?? ERROR_TOAST);
        }
      } catch (error) {
        console.error(error);
      }

      return {};
    },
    /**
   * @description 删除购物车商品
   */
    async removeGoodsItem({ dispatch, commit, state }, goodsItem) {
      try {
        const res = await procurementCartRemove({
          sku: goodsItem.sku,
          skku: goodsItem.skku ?? '',
          mdCode: goodsItem.mdCode,
        });

        if (res.data.success) {
          const index = state.goodsList.findIndex(item => {
            if (item.skku) {
              return item.skku === goodsItem.skku && item.mdCode === goodsItem.mdCode
            }
            if (item.sku) {
              return item.sku === goodsItem.sku && item.mdCode === goodsItem.mdCode
            }
          })
          if (index > -1) {
            commit('removeGoodsItemIndex', index)
          }
        }
        return res;
      } catch (error) {
        console.error(error);
      }

      return {};
    },

    /**
   * @description 获取购物车列表
   * @selectIds 初始选中的商品 array类型
   */
    async getCartList({ commit, rootState }) {
      return procurementCartList({
        // streetId: rootState.locationInfo.townshipId,
      }).then(res => {
        if (res.data?.success) {
          commit('setGoodsList', res.data?.data ?? [])
        } else {
          console.log('getCartList', res.data?.errMessage);
        }
      }).catch(e => console.log(e))
    },

    /**
     * @description 检查是否签订过协议
     */
    async checkSignedAgreement({ commit }) {
      try {
        const res = await procurementPolicyIsSigned({});
        if (res.data?.success) {
          commit('setIsSignedAgreement', res.data?.data?.signed);
        }
        return res;
      } catch (error) {
        console.error(error);
      }
      return {};
    },
    setHasCheckedOut({ commit },) {
      commit('setHasCheckedOut', true)
    },
    resetHasCheckedOut({ commit }) {
      commit('setHasCheckedOut', false)
    },

    /**
   * @description 删除购物车商品
   */
    async removeAllGoods({ dispatch, commit, state }) {
      try {
        const goodsList = state.goodsList.map(item => {
          return {
            sku: item.sku,
            skku: item.skku ?? '',
            mdCode: item.mdCode,
          }
        })

        const res = await procurementCartBatchRemove(goodsList);

        if (res.data.success) {
          commit('setGoodsList', [])
        }
        return res;
      } catch (error) {
        console.error(error);
      }

      return {};
    }

  },
}
