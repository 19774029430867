const contract = [
  {
    path: '/contractDetail',
    name: 'contractDetail',
    component: () => import('@/viewsToB/contract/contract-detail.vue'),
    meta: {
      keepAlive: true,
      showHeader: true,
      title: '合同详情',
    },
  },
  {
    path: '/contractList',
    name: 'contractList',
    component: () => import('@/viewsToB/contract/contract-list.vue'),
    meta: {
      keepAlive: true,
      showHeader: true,
      title: '合同管理',
    },
  },
  {
    path: '/couponRelateContract',
    name: 'couponRelateContract',
    component: () => import('@/viewsToB/contract/coupon-relate-contract'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '家居订单管理',
    },
  },
  {
    path: '/homeOrderLogistics',
    name: 'homeOrderLogistics',
    component: () => import('@/viewsToB/contract/home-order-logistics'),
    meta: {
      keepAlive: true,
      showHeader: true,
      title: '家居订单物流详情',
    },
  },
  {
    path: '/homeOrderLogisticsTrack',
    name: 'homeOrderLogisticsTrack',
    component: () => import('@/viewsToB/contract/home-order-logistics-track'),
    meta: {
      keepAlive: true,
      showHeader: true,
      title: '家居订单物流详情',
    },
  },
]

export default contract
