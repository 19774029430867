import util from '../utils/utils'
import semver from '@/utils/semver'
import store, { defaultLocation } from '../store'
import personalApi from '@/http/service/index'
import { getSecurityData } from '@/http/service/common'
import { judgeRegisterAndLoginTime, judgeIsDisturbed, csiSave } from '@/http/service/csi'
import UplusApi from '@uplus/uplus-api';
import { setDialogAction, setDialogTime, canShowDialog, DialogAction } from './UplusCSI.js';
import { getOrderPaySourceIdByEnv } from '@/views/order/cashier/common/orderSource'

export default {
  injectDefaultHaierAppResumeEventListener(options = { ignoreDefaultDeliveryAddress: false }) {
    const instance = new UplusApi()
    instance.initDeviceReady().then(() => {
      // 容器重新进入前台时，异步获取登录信息一次，保证登录、登出状态可以同步。但并非同步逻辑
      instance.addResumeEventListener(async () => {
        store.uplusLogininitFlag = false // 控制多次拉起登录页开关
        await this.getAppLocalData({
          identity: true,
          // address: util.isShopKeeper()
          address: false //全局定位，屏蔽该方法的定位
        })
        store.dispatch('uplusAccount/getUplusToken').then(res => {
          if (res === 400) {
            return 400
          } else {
            let sdToken = res.retData.user_center_access_token
            let oldSdToken = localStorage.getItem('accessToken')
            util.logColor('123456', 'blue', sdToken !== oldSdToken)
            // 切换账号 用新token换登录
            if (sdToken !== oldSdToken) {
              //切换账号重置定位key
              if(util.isHaierApp()){
                store.commit('setLocationInfo', store.state.locationInfo || defaultLocation)
              }
              store.dispatch('uplusAccount/getAuthorize', { sdToken, ...options }).then(result => {
                if (result === 400) {
                  return 400
                }
                if (!options.ignoreDefaultDeliveryAddress) {
                  store.dispatch('defaultDeliveryAddress') // 切换账号时设置默认收货地址为当前地址
                }
              })
            }
          }
        }).catch(e => e)
      }, 'onresume_customKey');
    }).catch(e => e)
  },
  awaitLoginIsHaierApp(isAwait, options = { ignoreDefaultDeliveryAddress: false }, needlogin) {
    if (isAwait) {
      return store.dispatch('uplusAccount/getUplusToken').then(async res => {
        if (res === 400) {
          return 400
        } else {
          let sdToken = res.retData.user_center_access_token
          let oldSdToken = localStorage.getItem('accessToken')
          util.logColor('aaaaaaaaa', 'blue', sdToken !== oldSdToken, sdToken)
          // 切换账号 用新token换登录
          if (sdToken !== oldSdToken) {
            //切换账号重置定位key
            if(util.isHaierApp()){
              store.commit('setLocationInfo', store.state.locationInfo || defaultLocation)
            }
            await store.dispatch('uplusAccount/getAuthorize', { sdToken, ...options }).then(result => {
              if (result === 400) {
                return 400
              }
              if (!options.ignoreDefaultDeliveryAddress) {
                store.dispatch('defaultDeliveryAddress') // 切换账号时设置默认收货地址为当前地址
              }
            }).catch(() => {
              return 400
            })
          }
        }
      })
    } else {
      store.dispatch('uplusAccount/getUplusToken').then(res => {
        if (res === 400) {
          if (needlogin) store.dispatch('doLogin')
          return 400
        } else {
          let sdToken = res.retData.user_center_access_token
          let oldSdToken = localStorage.getItem('accessToken')
          util.logColor('qqqqqqqqqqq', 'blue', sdToken !== oldSdToken)
          // 切换账号 用新token换登录
          if (sdToken !== oldSdToken) {
            //切换账号重置定位key
            if(util.isHaierApp()){
              store.commit('setLocationInfo', store.state.locationInfo || defaultLocation)
            }
            store.dispatch('uplusAccount/getAuthorize', { sdToken, ...options }).then(result => {
              if (result === 400) {
                return 400
              }
              if (!options.ignoreDefaultDeliveryAddress) {
                store.dispatch('defaultDeliveryAddress') // 切换账号时设置默认收货地址为当前地址
              }
            })
          }
        }
      })
    }
  },
  // 载入佣金逻辑
  async launchCommissionLogic() {
    // DSG-22686 爱创客环境中，检查路由参数，是否打开佣金展示(commissionDisplay=1 是开，0是关)；如果打开则获取身份信息保存到 store 和 sessionStorage 中
    // 佣金逻辑之前是在爱创客环境下，DSG-24911祥鹏于2021-5-21日取消了该限制
    // 在工作台环境下 从app内部获取一个开关，这个开关表示 用户是否能够看到佣金
    console.log('launchCommissionLogic====>', util.isScMpPeWechat(), sessionStorage.getItem('sg_home_open_scene'))
    if (util.isShopKeeper()) {
      let commisionDisplay
      try {
        commisionDisplay = await util.getAppStorageStringValue('eshop_main_storage_key_commission_display')
      } catch (err) { }
      console.log('commisionDisplay', commisionDisplay)
      if (commisionDisplay !== 'hide') store.commit('mymanage/setSwitch', true)
      store.dispatch('mymanage/fetchManageInfo')
    }else if(util.isScMpPeWechat() && sessionStorage.getItem('sg_home_open_scene') === 'PPHP'){ //私域小程序使用小程序控制开关
      console.log('wxMpcommissionDisplay===>',util.parseQueryValue('wxMpcommissionDisplay'))
      store.commit('mymanage/setSwitch', util.parseQueryValue('wxMpcommissionDisplay'))
      store.dispatch('mymanage/fetchManageInfo')
    }else if (util.parseQueryValue('commissionDisplay')) {
      store.commit('mymanage/setSwitch', true)
      store.dispatch('mymanage/fetchManageInfo')
    } else if (util.isIMaker() || util.isIhaierApp()) {
      store.dispatch('mymanage/fetchManageInfo')
    }
  },
  // 载入切换地理位置逻辑
  launchChangePositionLogic(obj) {
    const { addressInfo, streetCode } = obj
    if (addressInfo) {
      try {
        const rnLocation = JSON.parse(decodeURIComponent(addressInfo))
        console.log(rnLocation, 'rnLocation')
        const location = {
          region: rnLocation.region,
          provinceId: rnLocation.provinceId,
          province: rnLocation.province,
          cityId: rnLocation.cityId,
          city: rnLocation.city,
          districtId: rnLocation.areaId,
          district: rnLocation.district,
          townshipId: rnLocation.streetId,
          township: rnLocation.township,
          latitude: rnLocation.latitude,
          longitude: rnLocation.longitude,
          cityCode: rnLocation.cityCode,
          districtCode: rnLocation.districtCode,
          provinceCode: rnLocation.provinceCode,
          townshipCode: rnLocation.townshipCode,
        }
        store.dispatch('uplusLocation/doLocationInfo', location)
      } catch (error) {
        console.log(error)
      }
    } else if (streetCode) {
      // streetCode 需要先解析成商城地理信息
      console.log('bbbbbbb========bbbbbbbb')
      const datas = {
        streetCode,
      }
      return personalApi
        .pcrByCode(datas)
        .then(res => {
          if (res.data.data) {
            let allData = res.data.data
            const position = allData.location.split(',')
            let positionObj = {
              latitude: '',
              longitude: '',
            }
            if (position && Array.isArray(position) && position.length === 2) {
              positionObj = {
                longitude: position[0],
                latitude: position[1],
              }
            }
            const region = [allData.province, allData.city, allData.district, allData.township].join(' ')
            const location = {
              region: region,
              provinceId: allData.provinceId,
              province: allData.province,
              cityId: allData.cityId,
              city: allData.city,
              districtId: allData.districtId,
              district: allData.district,
              townshipId: allData.townshipId,
              township: allData.township,
              latitude: positionObj.latitude,
              longitude: positionObj.longitude,
              cityCode: allData.cityCode,
              districtCode: allData.districtCode,
              provinceCode: allData.provinceCode,
              townshipCode: allData.townshipCode,
            }
            return store.dispatch('uplusLocation/doLocationInfo', location)
          } else {
            console.log('streetCode识别失败')
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  // 处理掌柜登陆
  async sgRNLogin() {
    let sgUserInfoStr = location.search.split('sg_rn_app')[1]
    console.log(decodeURIComponent(sgUserInfoStr), 'decodeURIComponent sgUserInfoStr')
    console.log(decodeURI(sgUserInfoStr), 'decodeURI sgUserInfoStr')
    let sgUserInfo
    // 解决掌柜可能两次编码的问题
    try {
      sgUserInfo = JSON.parse(decodeURIComponent(sgUserInfoStr))
      console.log(sgUserInfo, 'sgUserInfo')
    } catch (error) {
      try {
        sgUserInfo = JSON.parse(decodeURIComponent(decodeURIComponent(sgUserInfoStr)))
      } catch (error) {
        console.log(error)
      }
    }
    if (sgUserInfo) {
      const rnLocation = sgUserInfo.addressInfo;
      const location = {
        region: rnLocation.region,
        provinceId: rnLocation.provinceId,
        province: rnLocation.province,
        cityId: rnLocation.cityId,
        city: rnLocation.city,
        districtId: rnLocation.areaId,
        district: rnLocation.district,
        townshipId: rnLocation.streetId,
        township: rnLocation.township,
        latitude: rnLocation.latitude,
        longitude: rnLocation.longitude,
        cityCode: rnLocation.cityCode,
        districtCode: rnLocation.districtCode,
        provinceCode: rnLocation.provinceCode,
        townshipCode: rnLocation.townshipCode,
      }
      try {
        await store.dispatch('sgRnAccount/doLogin', sgUserInfo.userMsg)
        await store.dispatch('sgRnAccount/doLocation', location)
      } catch (error) {
        console.log(error)
      }
    }
  },
  // 获取用户localData
  async getAppLocalData({
    identity,
    address
  }, isFirst = false) {
    try {
      if (util.isShopKeeper()) {
        if (identity) {
          const res = await Promise.all([util.getAppStorageStringValue('eshop_main_current_category'), util.getAppStorageStringValue('eshop_main_current_identity')])
          console.log('eshop_main_current_category', res[0])
          console.log('eshop_main_current_identity', res[1])
          store.commit('mymanage/setCurrentRole', {
            userCategory: res[0],
            userIdentity: res[1]
          })
        }
        if (address) {
          if (!isFirst && store.state.locationStatus) return

          let result = null
          try {
            result = await util.getAppStorageStringValue('eshop_main_location_info_key')
            result = util.strJSONParse(result, null)
          } catch (err) {
            result = null
          }

          console.log('eshop_main_location_info_key ==> ', result)
          if (result) {
            const locationInfo = util.strJSONParse(localStorage.getItem('location'), null)
            console.log('townshipId==>', locationInfo?.townshipId)
            if (locationInfo?.townshipId !== result.townshipId) {
              await this.launchChangePositionLogic({
                streetCode: result.streetCode,
              })
            }
            if (!store.state.locationStatus) {
              store.commit('setLocationStatus', true)
            }
          } else {
            store.commit('setLocationStatus', false)
          }
        }
        await this.getCurrentMdInfo()
      } else if (util.isSyBird()) {
        if (address) {
          let result = await util.getAppStorageStringValue('HOME_CURRENT_LOCATION')
          result = util.strJSONParse(result, {})
          console.log('HOME_CURRENT_LOCATION ==> ', result)
          const locationInfo = util.strJSONParse(localStorage.getItem('location'), null)
          console.log('townshipId==>', locationInfo?.townshipId)

          if (locationInfo?.townshipId !== result.townshipCode) {
            console.log('aaa====aaaa', result.townshipCode)
            await this.launchChangePositionLogic({
              streetCode: result.townshipCode,
            })
          }
        }
      }
    } catch (error) { }
  },
  // 三翼鸟工作台 获取用户当前所选门店信息
  async getCurrentMdInfo() {
    if (!util.isShopKeeper()) return null
    try {
      let storeConfig = await util.getAppStorageStringValue('eshop_main_current_store_config')
      storeConfig = util.strJSONParse(storeConfig, null)
      let mdCodeList = await util.getAppStorageStringValue('eshop_main_storage_key_mdcode_list')
      mdCodeList = util.strJSONParse(mdCodeList, [])
      const res = {
        ...storeConfig,
        mdCodeList: new Set(mdCodeList)
      }
      store.commit('mymanage/setMdInfo', res)
      return res
    } catch (err) {
      return null
    }
  },
  // 是否需要展示CSI弹窗
  async canShowCSIDialog(pageType) {
    return new Promise(async (resolve, reject) => {
      // 智家App环境过滤
      if (!util.isUplus()) {
        resolve(false)
        return
      }

      try {

        const isLogin = await store.dispatch('handleUpAuth')
        if (!isLogin) {
          resolve(false)
          return
        }
        const instance = new UplusApi()
        await instance.initDeviceReady()

        // 商城防打扰
        // 0. 满足60天内打开商城tab大于两次
        const result = await instance.upAppinfoModule.getAppInfo()
        let appVersion = result.retData.appVersion
        appVersion = appVersion.replace(/V/gi, '')
        // 仅在8.0.0版本以上的app才会有此逻辑
        if (appVersion && semver.gt(appVersion, '8.0.0')) {
          const res0 = await util.getAppStorageStringValue('eshop_tab_entrance_info')
          const res0value = JSON.parse(res0)

          if (util.isNull(res0value) || res0value.entrance_count < 2) {
            resolve(false)
            return
          }
        }


        // 1. 时间满足7天
        // 加入此逻辑为了确保一定获取到memberid
        const gut = await store.dispatch('uplusAccount/getUplusToken')
        if (gut === 400) {
          resolve(false)
          return
        }
        let sdToken = gut.retData?.user_center_access_token
        const gauth = await store.dispatch('uplusAccount/getAuthorize', { sdToken, ignoreDefaultDeliveryAddress: true })
        if (gauth === 400) {
          resolve(false)
          return
        }
        const res = await judgeRegisterAndLoginTime()
        if (!res.data.data) {
          resolve(false)
          return
        }

        // 2. 商城免打扰
        const appInfo = await instance.upAppinfoModule.getAppInfo()
        const phoneNo = appInfo.retData.clientId
        const res2 = await judgeIsDisturbed({ pageType, phoneNo })
        if (!res2.data.data) {
          resolve(false)
          return
        }

        // App防打扰
        console.log('=================================111')
        const canShow = await canShowDialog(pageType)
        console.log(canShow)
        console.log('=================================111')
        if (canShow) {
          resolve(true)
        }
        resolve(false)

      } catch (error) {
        reject(error)
      }
    })
  },
  // 关闭或提交CSI
  async closeOrSubmitCSI({ pageType, actions, data }) {
    // 智家App环境过滤
    if (!util.isUplus()) {
      return Promise.reject('非智家App环境')
    }
    try {
      // 1. 设置弹窗时间
      setDialogTime(pageType)
      // 2. 设置弹窗操作
      setDialogAction(pageType, actions)
      // 3. 提交商城CSI接口
      if (actions.includes(DialogAction.SUBMIT) || actions.includes(DialogAction.CLOSE)) {
        const instance = new UplusApi()
        await instance.initDeviceReady()
        const res = await instance.upAppinfoModule.getAppInfo()
        const appVersion = res.retData.appVersion
        const phoneNo = res.retData.clientId
        const evaluateSubmit = 1
        return csiSave({ ...data, pageType, appVersion, phoneNo, evaluateSubmit })
      }
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async checkRSAKey() {
    const result = await getSecurityData({
      paySourceId: getOrderPaySourceIdByEnv()
    })
    const { data } = result.data
    const { nonceTimestamp, encryptData } = data
    console.log('checkRSAKey====> ',nonceTimestamp, encryptData)
    store.commit('setRsaInfo', { encryptData, nonceTimestamp })
    const realPriveKeyBase64 = util.rsaDecrypt(encryptData)
    console.log('realPriveKeyBase64====> ',realPriveKeyBase64)
    // const realPrivateKey = util.base64Encode(realPriveKeyBase64)
    // console.log('realPrivateKey====> ',realPrivateKey)
    store.commit('setRsaInfo', { decryptIv: realPriveKeyBase64 })
    
  },
  
}
